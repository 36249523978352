import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import {
  ClearNotes,
  CreatePatientNote,
  DeletePatientNote,
  DeleteUserToAssignPatient,
  EditPatientNote,
  GetCoordinators,
  GetPatientById,
  GetPatientList,
  GetPatientListFilter,
  GetPatientNoteReason,
  GetPatientNoteReasonsFilters,
  GetPatientNotes,
  GetPatientPositions,
  GetPatientStatuses,
  GetUserListToAssignFilterPatient,
  GetUserListToAssignPatient,
  ResetPatientListErrors,
  SetAssignUserToPatient,
  SetNotesPage,
  SetPage,
  SetPatient,
  SetPerPage,
  SetStatusPatientHHa
} from './patients.actions';
import { PatientsService } from '../../../services/patients.service';
import { IncidentsService } from '../../../services/incidents.service';
import { StaticDataService } from '../../../../static-data.service';
import { Injectable } from '@angular/core';
import { PatientStatuses } from '../../../patients/patients.component';
import { Observable, throwError } from 'rxjs';
import { UserToAssign } from '../../../../shared/models/assigned-model';

export interface PatientDto {
  coordinator?: { id: number, name: string };
  dob: string;
  hasAdmissions: boolean;
  id: string;
  medicaidNumber: string;
  name: {
    firstName?: string;
    lastName?: string;
    middleName?: string;
    fullName?: string;
  };
  patientId: string;
}

export interface PatientFilter {
  id: string;
  fullName: string;
}

export interface PatientsStateModel {
  list: PatientDto[];
  page: number;
  perPage: number;
  order: string;
  dir: string;
  total: number;
  query: string;
  startDate: string;
  coordinatorsList: any[];
  patientStatuses: number[];
  patientStatusesList: PatientStatuses[];
  coordinators: any[];
  newPatientsErrors?: any;
  static?: any;
  reason?: Reason[];
  notes?: Note[];
  notePage: number;
  notesTotal: number;
  reasonFilter: Reason[];
  patientsFilter: PatientFilter[];
  userList: UserToAssign[];
  userListFilters: UserToAssign[];
}

export interface Reason {
  active: string;
  id: number;
  title: string;
}

export interface Note {
  author: string;
  id: string;
  reason: Reason;
  text: string;
  updatedAt: string;
  resourceId?: string;
  type?: 'incident' | 'admission' | 'patient' | 'discharge';
}

export const defaultState: PatientsStateModel = {
  list: [],
  page: 1,
  perPage: 10,
  order: 'createdAt',
  dir: 'desc',
  total: 0,
  query: '',
  startDate: '',
  coordinatorsList: [],
  patientStatusesList: [],
  patientStatuses: [],
  coordinators: [],
  newPatientsErrors: null,
  static: null,
  reason: [],
  notes: [],
  notePage: 1,
  notesTotal: 0,
  reasonFilter: [],
  patientsFilter: [],
  userList: [],
  userListFilters: []
};

@State({
  name: 'patients',
  defaults: defaultState
})

@Injectable()
export class PatientsState {

  constructor(
    private service: PatientsService,
    private incidents: IncidentsService,
    private staticService: StaticDataService
  ) {
  }

  @Selector()
  static reason(state) {
    return state.reason;
  }


  @Selector()
  static reasonFilter(state) {
    return state.reasonFilter || {};
  }


  @Selector()
  static notes(state) {
    return state.notes;
  }

  @Selector()
  static notesTotal(state) {
    return state.notesTotal;
  }

  @Selector()
  static notePage(state) {
    return state.notePage;
  }

  @Selector()
  static list(state) {
    return state.list;
  }

  @Selector()
  static patientsFilter(state): Observable<PatientFilter>[] {
    return state.patientsFilter;
  }

  @Selector()
  static usersToAssign(state): Observable<PatientFilter>[] {
    return state.userList;
  }

  @Selector()
  static usersToAssignFilter(state): Observable<PatientFilter>[] {
    return state.userListFilters;
  }

  @Selector()
  static total(state) {
    return state.total;
  }

  @Selector()
  static page(state) {
    return state.page;
  }

  @Selector()
  static perPage(state) {
    return state.perPage;
  }

  @Selector()
  static statuses(state) {
    return state.statusList;
  }

  @Selector()
  static coordinators(state) {
    return state.coordinatorsList.map(item => ({ ...item, id: +item.id }));
  }

  @Selector()
  static patientStatuses(state) {
    return state.patientStatusesList;
  }

  @Selector()
  static newPatientsErrors(state) {
    return state.newPatientsErrors || {};
  }

  getFilters(state) {
    let { coordinators } = state;
    let { patientStatuses } = state;
    let { portalPatientStatuses } = state;
    const { assigned } = state;
    const { query, page, perPage, order, dir } = state;
    if (!coordinators) {
      coordinators = [];
    }
    if (!patientStatuses) {
      patientStatuses = [];
    }
    if (!portalPatientStatuses) {
      portalPatientStatuses = [];
    }
    return {
      query, page, perPage, order, dir,
      'coordinatorIds[]': coordinators,
      'patientStatuses[]': patientStatuses,
      'portalPatientStatuses[]': portalPatientStatuses,
      'assigned[]': assigned
      // 'supervisors[]' : supervisors
    };
  }

  @Action(GetPatientList)
  getList(ctx: StateContext<PatientsStateModel>, { filterData, sortObj }: GetPatientList) {
    ctx.patchState({
      ...filterData
    });
    const state = ctx.getState();
    return this.service.getAll({
      ...this.getFilters(state),
      ...sortObj
    })
      .pipe(tap(({ data, total }: any) => {
        ctx.patchState({
          list: data.map(item => ({ ...item })),
          total: total
        });
      }));
  }
  @Action(GetPatientListFilter)
  getListFilter(ctx: StateContext<PatientsStateModel>, { filterData }: GetPatientListFilter): Observable<PatientFilter[]> {
    return this.service.getPatientsFilter({...filterData})
      .pipe(tap((value: PatientFilter[]) => {
        ctx.patchState({
        patientsFilter: value,
        });
      }));
  }


  @Action(SetPage)
  setPage(ctx: StateContext<PatientsStateModel>, { page }: SetPage) {
    ctx.patchState({
      page
    });
  }

  @Action(SetPerPage)
  setPerPage(ctx: StateContext<PatientsStateModel>, { perPage }: SetPerPage) {
    ctx.patchState({
      perPage
    });
  }

  private refreshList(ctx: StateContext<PatientsStateModel>) {
    return this.getList(ctx, <any>{ filterData: this.getFilters(ctx.getState()) })
      .pipe(tap(({ data, total }: any) => {
        ctx.patchState({
          list: data.map(item => ({ ...item })),
          total: total
        });
      }));
  }

  @Action(GetPatientById)
  getPatientById(ctx: StateContext<PatientsStateModel>, { id }: GetPatientById) {
    return this.service.getById(id);
  }

  @Action(SetPatient)
  setPatient(ctx: StateContext<PatientsStateModel>, { patient }) {
    const list = ctx.getState().list;
    list.push(patient);
    ctx.patchState({
      list
    });
    return this.service.setPatients(patient).pipe(
      tap(() => {
        list.push(patient);
        ctx.patchState({
          list
        });
      }),
      catchError(err => {
        ctx.patchState({
          newPatientsErrors: err.error.violations,
        });
        return throwError(err);
      })
      );
  }

  @Action(ResetPatientListErrors)
  ResetPatientListErrors(ctx: StateContext<any>) {
    ctx.patchState({
      newPatientsErrors: null
    });
  }

  @Action(GetCoordinators)
  getCoordinators(ctx: StateContext<PatientsStateModel>) {
    return this.incidents.getCoordinators().pipe(tap((res: any[]) => {
      ctx.patchState({
        coordinatorsList: res
      });
    }));
  }

  @Action(GetPatientStatuses)
  getPatientStatuses(ctx: StateContext<PatientsStateModel>) {
    return this.incidents.getPatientStatuses().pipe(tap((res: PatientStatuses[]) => {
      ctx.patchState({
        patientStatusesList: res
      });
    }));
  }

  @Action(GetPatientPositions)
  getPatientPositions(ctx) {
    return this.staticService.getPositions()
      .pipe(tap((data: any[]) => {
        ctx.patchState({
          static: data
        });
      }));
  }

  @Action(GetPatientNoteReason)
  getPatientNoteReason(ctx: StateContext<PatientsStateModel>, { patientId }: GetPatientNoteReason) {
    return this.staticService.getPatientNoteReason(patientId).pipe(
      tap((res: Reason[]) => {
        ctx.patchState({
          reason: res
        });
      })
    );
  }

  @Action(CreatePatientNote)
  createPatientNote(ctx: StateContext<PatientsStateModel>, { payload }: CreatePatientNote) {
    return this.staticService.createPatientNote(payload);
  }

  @Action(EditPatientNote)
  editPatientNote(ctx: StateContext<PatientsStateModel>, { payload }: EditPatientNote) {
    return this.staticService.editPatientNote(payload);
  }

  @Action(GetPatientNotes)
  getPatientNotes(ctx: StateContext<PatientsStateModel>, { patientId, filters, sort, getAllNotes }: GetPatientNotes) {
    const state = ctx.getState();
    let obj = {
      page: state.notePage,
      perPage: filters.perPage || 10,
      query: filters.query || '',
      'reasons[]': filters && filters.reasons ? filters.reasons : [],
      'authors[]': filters && filters.authors ? filters.authors.map(item => item.split('/')[0]) : [],
      notSavePerPage: filters.notSavePerPage ? 1 : 0
    };
    if (sort) {
      obj = {
        ...obj,
        ...sort
      };
    }
    return this.staticService.getPatientNotes(patientId, obj)
    .pipe(
      tap(({ data, total }) => {
        if (state.notePage > 1 && !getAllNotes) {
          ctx.patchState({
            notes: [...state.notes, ...data],
            notesTotal: total
          });
        } else {
          ctx.patchState({
            notes: data,
            notesTotal: total
          });
        }
      })
    );
  }

  @Action(DeletePatientNote)
  deletePatientNote(ctx: StateContext<PatientsStateModel>, { payload }: DeletePatientNote) {
    return this.staticService.deletePatientNote(payload);
  }

  @Action(ClearNotes)
  clearNotes(ctx: StateContext<PatientsStateModel>) {
    return ctx.patchState({
      notes: []
    });
  }

  @Action(SetNotesPage)
  setNotesPage(ctx: StateContext<PatientsStateModel>, { page }: SetNotesPage) {
    return ctx.patchState({
      notePage: page
    });
  }

  @Action(GetPatientNoteReasonsFilters)
  getPatientNoteReasonsFilter(ctx: StateContext<any>, {patientId}: any) {
    return this.staticService.getPatientNoteReasonFilter(patientId).pipe(
      tap((res: Reason[]) => {
        ctx.patchState({
          reasonFilter: res
        });
      })
    );
  }

  @Action(SetStatusPatientHHa)
  setStatusPatientHHa(ctx: StateContext<any>, {patientId, status}: any) {
    return this.staticService.setStatusPatientHHa(patientId, status);
  }


  @Action(SetAssignUserToPatient)
  setAssignUserToPatient(ctx: StateContext<any>, { uid, assignIds }: SetAssignUserToPatient) {
    return this.service.setAssignUserToPatient(uid, assignIds);
  }

  @Action(DeleteUserToAssignPatient)
  deleteAssignUserToPatient(ctx: StateContext<any>, { patientId, userId }: DeleteUserToAssignPatient) {
    return this.service.deleteAssignedUser(patientId, userId);
  }

  @Action(GetUserListToAssignPatient)
  setUserListToAssignPatient(ctx: StateContext<any>, { search }: GetUserListToAssignPatient) {
    return this.service.getUserListToAssign(search, 0).pipe(
      tap((users) => {
        ctx.patchState({
          userList: users
        });
      })
    );
  }

  @Action(GetUserListToAssignFilterPatient)
  setUserListToAssignFilterPatient(ctx: StateContext<any>, { search }: GetUserListToAssignFilterPatient) {
    return this.service.getUserListToAssign(search, 1).pipe(
      tap((users) => {
        ctx.patchState({
          userListFilters: users
        });
      })
    );
  }
}
