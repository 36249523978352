import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Event } from '../store/calendar/interfaces/event.interface';
import { Observable } from 'rxjs';
import { EventSearch } from '../store/calendar/interfaces/event-search.interface';

export interface Link {
  link: string;
}

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  private readonly baseApiUrl: string;

  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: string) {
    this.baseApiUrl = `${this.apiUrl}/calendar`;
  }

  getCalendarAuthLink(): Observable<Link> {
    return this.http.get<Link>(`${this.baseApiUrl}/auth-link`);
  }

  getToken(code: string): Observable<void> {
    return this.http.post<void>(`${this.baseApiUrl}/oauth2callback`, <string>code);
  }

  getEvents(params: EventSearch, uid: string): Observable<{ pageToken: string; items: Event[]; }> {
    return this.http.get<{ pageToken: string; items: Event[]; }>(`${this.baseApiUrl}/employee/${uid}`, {
      params: <any>params
    });
  }

  creatEvent(params: Event, uid: string): Observable<Event> {
    return this.http.post<Event>(`${this.baseApiUrl}/employee/${uid}`, params);
  }

  getEventById(id: string, uid: string): Observable<Event> {
    return this.http.get<Event>(`${this.baseApiUrl}/${'employee'}/${uid}/${id}`);
  }

  updateEvent(id: string, params: Event, uid: string): Observable<Event> {
    return this.http.put<Event>(`${this.baseApiUrl}/employee/${uid}/${id}`, {...params});
  }

  deleteEvent(id: string, uid: string) {
    return this.http.delete(`${this.baseApiUrl}/employee/${uid}/${id}`);
  }
}
