import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '../shared/shared.module';
import { AuthService } from './auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { Store } from '@ngxs/store';
import { GetPerPages, GetRules, ValidateUser } from '../store/app.actions';
import { AuthGuard } from './auth.guard';
import { PermissionGuard } from '../shared/guards/permission.guard';
import { LoginByMicrosoftRedirectUrlComponent } from './login-by-microsoft-redirect-url/login-by-microsoft-redirect-url.component';

export function initApp(store: Store) {
  return () => store.dispatch([new ValidateUser(), new GetPerPages(), new GetRules()]).toPromise();
}

@NgModule({
  declarations: [
    LoginComponent,
    LoginByMicrosoftRedirectUrlComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    LoginComponent,
    LoginByMicrosoftRedirectUrlComponent
  ],
  providers: [
    AuthService,
    AuthGuard,
    PermissionGuard,
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: APP_INITIALIZER, useFactory: initApp, deps: [Store], multi: true}
  ]
})
export class AuthModule {
}
