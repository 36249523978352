import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';

export const MULTISELECT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiselectComponent),
  multi: true,
};

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  providers: [MULTISELECT_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule]
})
export class MultiselectComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() name;
  @Input() type;
  @Input() number = 0;
  @Input() isSelectAll: boolean;
  @Input() withSearchField: boolean;
  @Input() noItemsText = 'No options available';
  @Input() bindLabel = 'name';
  @Input() bindValue = 'id';
  @Input() bindValueRadio = '';
  @Input() prefix: any;
  @Input() closeEmmiter: EventEmitter<void>;
  @Input() isSingleselect: boolean;
  @Input() disabled = false;
  @Input() fullObject = false;
  @Input() disableEmpty = false;
  @Input() saveAllData = false;
  @Input() needHaveEmptyRadioButton = false;
  @Input() selectedValue: any = null;

  @Input() set refreshData(refresh) {
    if (refresh) {
      this.allItemList = [];
      this.allItemCheckedLength = 0;
    }
  }

  @Input() set items(value: any[]) {
    this.itemList = value?.map(item => ({...item, checked: this.checkChecked(item.id)}));
    if (this.saveAllData) {
      this.itemList.forEach((item) => {
        if (!this.allItemList.find(i => item[this.bindValue] === i[this.bindValue])) {
          this.allItemList.push(item);
        }
      });
      this.itemList = this.itemList?.map((i: any) => {
        this.allItemList.forEach((allItem: any) => {
          if (allItem[this.bindValue] === i[this.bindValue]) {
            i.checked = this.checkChecked(allItem[this.bindValue]);
          }
        });
        return i;
      });
      this.allItemCheckedLength = this.value.length;
    }
    if (this.itemList?.filter(item => item.checked).length) {
      this.checkedItems$.next(this.itemList?.filter(item => item.checked));
    }
  }

  @Output() private search: EventEmitter<any> = new EventEmitter<any>();
  @Output() selected: EventEmitter<any> = new EventEmitter();
  @Output() closedDrop: EventEmitter<any> = new EventEmitter();
  itemList: any[];
  allItemList: any[] = [];
  allItemCheckedLength = 0;
  checkedItems$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  keyword$: Subject<any> = new Subject();
  openDrop = false;
  destroyed: Subject<any> = new Subject();
  isSelectedAll = false;
  onChange;
  onTouched;
  searchValue = '';
  value: any[] = [];

  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    if (!event.path.includes(this.elRef.nativeElement)) {
      this.resetSearch();
      this.openDrop = false;
    }
  }

  constructor(private elRef: ElementRef, private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.checkedItems$
      .pipe(takeUntil(this.destroyed))
      .subscribe((value: any[]) => {
        this.selected.emit(value);
      });
    if (this.closeEmmiter) {
      this.closeEmmiter
        .pipe(takeUntil(this.destroyed))
        .subscribe(() => {
          this.openDrop = false;
          this.cd.markForCheck();
        });
    }

    this.keyword$.pipe(debounceTime(1000), takeUntil(this.destroyed)).subscribe(value => this.search.next(value));
  }

  ngOnDestroy() {
    this.destroyed.next('');
    this.destroyed.complete();
  }

  writeValue(value: any[]): void {
    this.value = value;
    this.itemList = this.itemList?.map(
      item => ({...item, checked: this.checkChecked(item[this.bindValueRadio ? this.bindValueRadio : this.bindValue])}));
    this.itemList?.forEach((item) => {
      if (item.checked) {
        this.checkedItems$.next([item]);
      }
    });
    if (!this.itemList?.find((item) => item.checked)) {
      this.checkedItems$.next([]);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  toggleDrop() {
    if (this.withSearchField) {
      this.resetSearch();
    }
    this.openDrop = !this.openDrop;
  }

  selectAll() {
    this.isSelectedAll = !this.isSelectedAll;
    this.itemList = this.itemList.map(item => ({...item, checked: !this.isSelectedAll}));
    const newArray: any[] = [...this.itemList];
    newArray.forEach((item, index) => this.toggleItem(index));
  }

  toggleItem(index, isFromSelect?) {
    let checkedItems;
    this.itemList = this.itemList.map((item, indx: number) => {
      if (index === indx) {
        return ({...item, checked: !item.checked});
      } else {
        return this.isSingleselect ? ({...item, checked: false}) : item;
      }
    });

    if (this.type === 'radio') {
      if (this.bindValueRadio) {
        checkedItems = this.itemList[index][this.bindValueRadio];
      } else {
        checkedItems = this.itemList[index].value;
      }
      if (this.onChange) {
        this.onChange(checkedItems);
      }
      this.value = checkedItems;
      this.toggleDrop();
    } else {
      if (this.isSingleselect) {
        checkedItems = this.itemList[index].checked ? [this.itemList[index]] : [];
        this.openDrop = false;
        this.resetSearch();
        this.closedDrop.emit();
      } else {
        if (this.saveAllData) {
          this.itemList.forEach((i: any) => {
            const value = this.allItemList.find((allItem: any): boolean => allItem[this.bindValue] === i[this.bindValue]);
            if (value) {
              value.checked = i.checked;
            } else {
              if (!this.allItemList.find(item => i[this.bindValue] === item[this.bindValue])) {
                this.allItemList.push(i);
              }
            }
          });
          this.allItemCheckedLength = this.allItemList.filter((i: any) => i.checked)?.length;
          checkedItems = this.allItemList.filter(item => item.checked);
        } else {
          checkedItems = this.itemList.filter(item => item.checked);
        }
      }
      if (this.onChange) {
        if (this.fullObject) {
          this.onChange(checkedItems);
        } else {
          this.onChange(checkedItems.map(item => item[this.bindValue]));
        }
      }
      if (this.fullObject) {
        this.value = checkedItems;
      } else {
        this.value = checkedItems.map(item => item[this.bindValue]);
      }
      if (isFromSelect && this.isSelectAll) {
        this.isSelectedAll = this.value.length === this.itemList.length;
      }
    }
    if (this.saveAllData) {
      this.checkedItems$.next(this.allItemList.filter((item: any) => item.checked));
    } else {
      this.checkedItems$.next(checkedItems);
    }
  }

  trackByFn(index) {
    return index;
  }

  checkChecked(prop): boolean {
    if (this.fullObject && this.isSingleselect) {
      return this.value && !!this.value[0] && this.value[0].id === prop;
    }
    if (this.bindValueRadio) {
      return this.value === prop;
    }
    return this.value && !(typeof (this.value) === 'number') && this.value && this.value.indexOf(prop) > -1;
  }

  public onSearch(value: string): void {
    this.keyword$.next(value);
  }

  private resetSearch(): void {
    if (this.searchValue) {
      this.onSearch('');
      this.searchValue = '';
    }
  }
}
