import { Event } from './interfaces/event.interface';
import { EventSearch } from './interfaces/event-search.interface';

export enum TypeModalEvent {
  Create,
  Edit,
  Look,
  Close
}
export class GetAuthLink {
  static readonly type = '[Calendar] Get auth link';
  constructor() {}
}

export class GetToken {
  static readonly type = '[Calendar] Get token';
  constructor(public code: string) {}
}

export class GetTokenSuccess {
  static readonly type = '[Calendar] Get token success';
  constructor() {}
}

export class GetEvents {
  static readonly type = '[Calendar] Get events';
  constructor(public params: EventSearch, public uid: string) {}
}

export class CreateEvent {
  static readonly type = '[Calendar] Create event';
  constructor(public event: Event, public uid: string) {}
}

export class SetSelectedEvent {
  static readonly type = '[Calendar] Set selected event';
  constructor(public event: Event) {}
}

export class GetEventById {
  static readonly type = '[Calendar] Get event by id';
  constructor(public id: string, public uid: string) {}
}

export class EditEvent {
  static readonly type = '[Calendar] Edit event';
  constructor(public id: string, public event: Event, public uid: string) {}
}

export class DeleteEvent {
  static readonly type = '[Calendar] Delete event';
  constructor(public id: string, public uid: string) {}
}

export class ModalEvent {
  static readonly type = '[Calendar] Modal event';
  constructor(public stateModal: TypeModalEvent, public id: string = null) {}
}

export class SetEventSuccess {
  static readonly type = '[Calendar] Set event success';
  constructor() {}
}
