export enum ReportStatusEnum {
  New = 1,
  SentForSignatures = 2,
  Signed = 3,
  SignedFromAttachment = 4,
  SentToTheOffice = 5,
  ToBeCorrected = 6,
  SentForSignToCustomerEmployee = 7,
  SignedByCustomerPendingEmployee = 8,
  SignedByEmployeePendingCustomer = 9
}

export enum NetworkInfoEnum {
  LessThan = 1
}
