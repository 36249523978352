import { Directive, HostListener, Input, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appMaxLength]'
})
export class MaxLengthDirective {

  @Input() appMaxLength = 0;

  @HostListener('keypress', ['$event'])
  onInput(event) {
    if (event.target.value.length >= this.appMaxLength) {
      event.preventDefault();
      event.stopPropagation();
      // event.target.value = event.target.value.substr(0, this.appMaxLength);
      // if (this.ngControl.control) {
      //   this.ngControl.control.patchValue(event.target.value);
      // }
    }
  }

  constructor(@Self() private ngControl: NgControl) { }

}
