<div *ngIf="!next" class="choose-documents">
  <ng-container *ngIf="documents; else loading">
    <div class="choose-documents__header">
      <h3>Choose Documents you need</h3>
      <div class="search">
        <input [(ngModel)]="filter" type="text" placeholder="Search"/>
      </div>
      <p>Please select document:</p>
    </div>
    <ul class="choose-documents__list">
      <ng-container *ngIf="documents?.length; else noItems">
        <li *ngIf="!filter" class="choose-documents__list-item">
          <div class="form-group">
            <input (change)="selectAll()" [checked]="allSelected" type="checkbox" id="doc-select-all"/>
            <label for="doc-select-all">
              <span class="document-name">Select All</span>
            </label>
          </div>
        </li>
        <ng-container *ngFor="let doc of documents; let i = index">
          <li
            *ngIf="showDoc(doc.name)"
            [class.pointer-events-none]="doc.key === DisabledDocKey.TestFolder && !selectedDocs.length"
            class="choose-documents__list-item">
            <div class="form-group">
              <input
                (change)="toggle(i)"
                [attr.id]="'doc' + doc.id"
                [checked]="doc.checked"
                [disabled]="doc.key === DisabledDocKey.TestFolder && !selectedDocs.length || doc.isDisabled"
                type="checkbox"/>
              <label [attr.for]="'doc' + doc.id">
              <span [class.disabled]="doc.isDisabled" class="document-name">
                {{ doc.name }}
                <ng-container *ngIf="doc.key === DisabledDocKey.TestFolder">
                  (sends only with other document)
                </ng-container>
              </span>
              </label>
            </div>
          </li>
        </ng-container>
      </ng-container>
    </ul>
    <div *ngIf="documents?.length" class="next-btn-wrap">
      <button
        (click)="toggleStep()"
        [disabled]="!selectedDocs?.length"
        data-e2e="choose-documents-proceed"
        class="btn violet next-btn">
        Proceed
      </button>
    </div>
    <ng-template #noItems>
      <div class="message">No documents found</div>
    </ng-template>
  </ng-container>
</div>

<div *ngIf="next" class="choose-documents-action">
  <div class="back-btn-wrapper">
    <span (click)="toggleStep()"><i class="icon-back-arrow"></i> Back to documents</span>
  </div>
  <ul class="choose-documents-action__list">
    <li *ngFor="let doc of selectedDocs">{{ doc.name }}</li>
  </ul>
  <div class="choose-documents-action__buttons">
    <h3>Send for sign by:</h3>
    <ul class="choose-documents-action__buttons-list">
      <li>
        <button
          (click)="activePhoneField = false; chooseAction('email')"
          [disabled]="!actions?.email || isClickSend">
          <i class="icon-mail"></i> Email
        </button>
      </li>
      <li>
        <button
          (click)="activePhoneField = true; errors = []"
          [class.active]="activePhoneField"
          [disabled]="!actions?.text"
          class="text">
          <i class="icon-file-text1"></i> Text
        </button>
      </li>
      <li *ngIf="hasPermissionPrint() | async">
        <button
          (click)="activePhoneField = false; chooseAction('print')"
          [disabled]="!actions?.print || isClickSend">
          <i class="icon-print"></i> Print
        </button>
      </li>
      <li *ngIf="hasPermissionPrint() | async">
        <button
          (click)="activePhoneField = false; chooseAction('sign')"
          [disabled]="!actions?.sign || isClickSend">
          <i class="icon-signature"></i> Sign
        </button>
      </li>
    </ul>
  </div>
  <div *ngIf="errors.length" class="error-list">
    <div *ngFor="let err of errors" class="error">{{ err }}</div>
  </div>
  <div *ngIf="activePhoneField" class="phone-form">
    <div *ngIf="!phoneMeta.success" class="phone-form__wrapper">
      <label class="input-wrapper">
        <input [(ngModel)]="phone" [mask]="phoneMask" type="tel" appMask/>
        <span *ngIf="phoneMeta.error" class="err-msg">{{ phoneMeta.error }}</span>
      </label>
      <div class="button-wrapper">
        <button (click)="chooseAction('text')" [disabled]="isClickSend" class="btn violet send-text">
          Send
        </button>
      </div>
    </div>
    <div *ngIf="phoneMeta.success" class="phone-form__success"><span class="icon"></span> Link successfully sent</div>
  </div>
</div>

<div class="success" style="display: none">
  <div class="success__wrapper">
    <div class="success__header">
      <span class="icon"></span>
    </div>
    <div class="success__body">
      <span>Link successfully sent</span>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="overlay">
    <div class="icon">
      <img src="/assets/icons/upload.svg" alt=""/>
    </div>
    <div class="text">Loading</div>
  </div>
</ng-template>
