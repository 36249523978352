import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(value: string): string {
    if (value.length === 10) {
      return value.replace(/(\d{3})(\d{3})(\d{4})/, '+1 ($1) $2 - $3');
    } else {
      return value.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3 - $4');
    }
  }
}
