import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { catchError } from 'rxjs/operators';
import { OpenMessagePopup } from '../../dashboard/store/dashboard.actions';

@Injectable()
export class ErrorMessageInterceptor implements HttpInterceptor {
  constructor(private store: Store) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(event => {
        if ((event.error || {}).notifyUser) {
          this.store.dispatch(new OpenMessagePopup(event.error.message, event.error));
        }
        return throwError(event);
      })
    );
  }
}
