<div (clickOutside)="isOpen = false" class="time-picker-select">
  <div [ngStyle]="{'border-color': borderColor}"
       [class.has-error]="options.isEdit && !value"
       (click)="isOpen = true"
       class="input-container">
    <div>
      <label>
        <input
          [placeholder]="placeholderValue"
          [(ngModel)]="value"
          [attr.class]="'input-value'"
          value=""
          readonly/>
      </label>
    </div>
    <div class="arrow-time">
      <img alt="arrow" src="assets/img/time-picker.svg">
    </div>
  </div>
  <div [hidden]="!isOpen" class="time-slots">
    <div [hidden]="!options.isMinTime">
      <perfect-scrollbar (touchmove)="onTouchmove($event)" [config]="configScroll" class='scroll-time-slots'>
        <ng-container *ngFor="let item of timeSlots; let i = index">
          <div *ngIf="i !== timeSlots.length-1"
               (click)="value = item.name; isOpen = false;"
               [class.active] ="value === item.name"
               [class.disabled]="item.disable"
               class="time-slots__item">
            {{ item.name }}
            <img *ngIf="value === item.name" alt="select" src="assets/img/checked-time.svg">
          </div>
        </ng-container>
      </perfect-scrollbar>
    </div>

    <div [hidden]="!options.isMaxTime">
      <perfect-scrollbar (touchmove)="onTouchmove($event)" [config]="configScroll" class='scroll-time-slots'>
        <div *ngFor="let item of timeSlots; let i = index"
             (click)="value = item.name; isOpen = false;"
             [class.active] ="value === item.name"
             [class.disabled]="item.disable"
             class="time-slots__item">
          {{ item.name }}
          <img *ngIf="value === item.name" alt="select" src="assets/img/checked-time.svg">
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>
