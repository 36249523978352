import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { Store } from '@ngxs/store';
import { RoleEnum } from '../models';

@Directive({
  selector: '[appPermissionDisabled]'
})
export class PermissionDisabledDirective {
  @Input()
  set appPermissionDisabled(permission: string[]) {
    const permissions: string[] =  this.store.selectSnapshot(({app}) => app.currentUser?.permissions);
    const roles = this.store.selectSnapshot(({app}) => app.currentUser?.roles);
    let access = false;
    permission?.forEach((per) => {
      if (permissions?.includes(per)) {
        access = true;
      }
    });
    if (permission[0] === 'user.create') {
      access = permissions.some((per) => per.includes('user.create'));
    }
    if (access || roles && roles[0]?.title === RoleEnum.SuperAdmin || roles && roles[0]?.title === RoleEnum.Employee) {
      this.renderer.removeAttribute(this.elRef.nativeElement, 'permission-disabled' );
    } else {
      this.renderer.setAttribute(this.elRef.nativeElement, 'permission-disabled', 'true');
    }
  }

  constructor(  private store: Store,
                private elRef: ElementRef,
                private renderer: Renderer2) {}
}
