import { Action, Selector, State, StateContext } from '@ngxs/store';
import { switchMap, tap } from 'rxjs/operators';
import {
  GetLessons,
  GetLessonsCategoriesListForFilter,
  GetLessonById,
  CreateLesson,
  UpdateLesson,
  DeleteLessonById,
  SetPage,
  ConfirmSeenById,
  CloseConfirmPopup,
  IsEducationDataLoaded,
  GetUserListForFilter
} from './educations.actions';
import { EducationsService } from '../../services/education.service';
import { Injectable } from '@angular/core';

export interface LessonsStateModel {
  nameLesson: string;
  description: string;
  category: string;
  listCategory: any[];
  userListForFilter: any[];
  listLessons: any[];
  lessonById: any;
  url: string;
  filters: any;
  totalLessons: number;
  page: number;
  isCloseDoc: boolean;
  isEducationDataLoaded: boolean;
  defaultCategory: any;
}

export const defaultState: LessonsStateModel = {
  nameLesson: '',
  description: '',
  category:	'',
  url: '',
  listCategory: [],
  listLessons: [],
  lessonById: {},
  userListForFilter: [],
  filters: {
    query: '',
    categories: []
  },
  totalLessons: 0,
  page: 1,
  isCloseDoc: false,
  isEducationDataLoaded: false,
  defaultCategory: {}
};

@State({
  name: 'lessons',
  defaults: defaultState
})

@Injectable()
export class EducationState {

  constructor(private service: EducationsService) {
  }

  @Selector()
  static nameLesson(state) {
    return state.nameLesson;
  }

  @Selector()
  static description(state) {
    return state.description;
  }

  @Selector()
  static userListForFilter(state) {
    return state.userListForFilter;
  }

  @Selector()
  static totalLessons(state) {
    return state.totalLessons;
  }

  @Selector()
  static category(state) {
    return state.category;
  }

  @Selector()
  static url(state) {
    return state.url;
  }

  @Selector()
  static listCategory(state) {
    return state.listCategory;
  }

  @Selector()
  static defaultCategory(state) {
    return state.defaultCategory;
  }

  @Selector()
  static listLessons(state) {
    return state.listLessons;
  }

  @Selector()
  static lessonById(state) {
    return state.lessonById;
  }

  @Selector()
  static page(state) {
    return state.page;
  }

  @Selector()
  static isCloseDoc(state) {
    return state.isCloseDoc;
  }

  @Selector()
  static isEducationDataLoaded(state) {
    return state.isEducationDataLoaded;
  }

  @Action(GetLessonsCategoriesListForFilter)
  getLessonsCategoriesListForFilter(ctx: StateContext<LessonsStateModel>) {
    return this.service.getLessonCategoriesForFilter().pipe(tap((data: any[]) => {
      ctx.patchState({
        listCategory: data,
        defaultCategory: data.filter(category => category?.isDefault)
      });
    }));
  }

  @Action(GetUserListForFilter)
  getUserListForFilter(ctx: StateContext<LessonsStateModel>, { params }) {
    return this.service.getUserListForFilter(params).pipe(tap((users: any[]) => {
      ctx.patchState({
        userListForFilter: users.map(user => {
          return {
            name: user.name?.trim() || '',
            email: user.email,
          };
        })
      });
    }));
  }

  @Action(GetLessons)
  getAllLessons(ctx: StateContext<LessonsStateModel>, { filters }: GetLessons) {
    const state = ctx.getState();
    return this.service.getLessons({ ...filters, page: state.page })
    .pipe(
      tap(({ listLessons, totalLessons }: any) => {
        ctx.patchState({
          listLessons,
          totalLessons
        });
      })
    );
  }

  @Action(GetLessonById)
  getLessonById(ctx: StateContext<LessonsStateModel>, { id }: GetLessonById) {
    return this.service.getLessonsById(id).pipe(tap((data: any[]) => {
      ctx.patchState({
        lessonById: data,
      });
    }));
  }

  @Action(CreateLesson)
  createLesson(ctx: StateContext<LessonsStateModel>, { payload }: CreateLesson) {
    return this.service.createLessons(payload);
  }


  @Action(UpdateLesson)
  updateLesson(ctx: StateContext<LessonsStateModel>, { id, payload }: UpdateLesson) {
    const state = ctx.getState();
    const obj = {
      ...payload,
      category: payload.category.id
    };
    return this.service.updateLesson(id, obj).pipe(switchMap(() => {
      return this.getAllLessons(ctx, state.filters);
    }));
  }

  @Action(DeleteLessonById)
  deleteLessonById(ctx: StateContext<LessonsStateModel>, { id }: DeleteLessonById) {
    return this.service.deleteLesson(id);
  }

  @Action(SetPage)
  setPage(ctx: StateContext<LessonsStateModel>, { page }: SetPage) {
    ctx.patchState({
      page
    });
  }

  @Action(ConfirmSeenById)
  confirmSeenById(ctx: StateContext<LessonsStateModel>, { id }: ConfirmSeenById) {
    return this.service.confirmSeen(id).pipe(tap(() => {
      const updatedListLesson = ctx.getState().listLessons.map(lesson => {
        if (lesson.id === id) {
          lesson.isSeen = true;
        }
        return lesson;
      });
      ctx.patchState({
        listLessons: updatedListLesson
      });
    }));
  }

  @Action(CloseConfirmPopup)
  closeConfirmPopup(ctx: StateContext<LessonsStateModel>, { isCloseDoc }: CloseConfirmPopup) {
    ctx.patchState({
      isCloseDoc
    });
  }

  @Action(IsEducationDataLoaded)
  isEducationDataLoaded(ctx: StateContext<LessonsStateModel>, { isEducationDataLoaded }) {
    ctx.patchState({
      isEducationDataLoaded
    });
  }
}


