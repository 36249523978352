import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  OnInit,
  AfterViewInit,
  HostListener,
  OnDestroy,
  Renderer2
} from '@angular/core';
import { AngularSignaturePadModule, SignaturePadComponent } from '@almothafar/angular-signature-pad';

@Component({
  selector: 'app-signature-popup',
  templateUrl: './signature-popup.component.html',
  styleUrls: ['./signature-popup.component.scss'],
  standalone: true,
  imports: [AngularSignaturePadModule]
})
export class SignaturePopupComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() data: { id: string, password: string };
  @Output() closed: EventEmitter<any> = new EventEmitter();
  @ViewChild('signature', {static: true}) public signaturePad: SignaturePadComponent;
  email = '';
  disableBtn = true;

  @HostListener('window:resize')
  onResize() {
    this.clear();
    this.initSignaturePad();
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange() {
    this.initSignaturePad();
  }

  constructor(
    private renderer: Renderer2
  ) {
  }

  ngOnInit() {
    this.initSignaturePad();
    this.fixedPage(true);
  }

  ngOnDestroy() {
    this.fixedPage(false);
  }

  ngAfterViewInit() {
    this.disableBtn = this.signaturePad.isEmpty();
  }

  drawStart() {
    this.disableBtn = false;
  }

  save() {
    this.closed.emit(this.signaturePad.toDataURL('png'));
  }

  clear() {
    this.disableBtn = true;
    this.signaturePad.clear();
  }

  initSignaturePad() {
    const canvas: HTMLCanvasElement = document.querySelector('canvas');
    let value = null;
    if (window.innerWidth < 550) {
      canvas.setAttribute('width', `${window.innerWidth - 60}px`);
    }
    if (window.innerWidth > 550) {
      canvas.setAttribute('width', '500px');
    }
    value = window.innerHeight - 230 >= 400 ? 400 : window.innerHeight - 230;
    canvas.setAttribute('height', value < 70 ? '70px' : `${value}px`);
  }

  private fixedPage(enabled: boolean) {
    const el: Element = document.querySelector('app-dashboard .dashboard');
    const mainWrapper: Element = document.querySelector('.main-wrapper');
    const body: HTMLBodyElement = document.querySelector('body');
    if (enabled) {
      if (el) {
        this.renderer.addClass(el, 'fixed');
      }
      if (mainWrapper) {
        this.renderer.addClass(mainWrapper, 'fixed');
      }
      this.renderer.addClass(body, 'scrollDisabled');
    } else {
      if (el) {
        this.renderer.removeClass(el, 'fixed');
      }
      if (mainWrapper) {
        this.renderer.removeClass(mainWrapper, 'fixed');
      }
      this.renderer.removeClass(body, 'scrollDisabled');
    }
  }

  public validateSign(): boolean {
    const arrayPointSign = this.signaturePad.toData();
    const len: number = arrayPointSign.reduce((acc: number, value) => {
      return value.points.length > 3 ?  acc + value.points.length : acc ;
    }, 0);
    return len > 20;
  }
}
