import { ChangeDetectorRef, Component, EventEmitter, Input, NgModule, OnDestroy, OnInit, Output } from '@angular/core';
import { DocumentTypes, EmployeesService } from '../../dashboard/services/employees.service';
import { Observable, ReplaySubject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { RoleEnum } from '../../shared/models';
import { environment } from '../../../environments/environment';
import { EnvironmentEnum } from '../../shared/enums/app.enum';
import { MaskDirective } from '../../shared/directives/mask.directive';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { PermissionEmployeeEnum } from '../../shared/enums/permission.enum';
import { PermissionAccessService } from '../../shared/services/permission-access.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

export interface DocumentPopupActions {
  sign: (docIds: number[], allDocs: boolean) => Observable<any>;
  print: (docIds: number[], allDocs: boolean) => Observable<any>;
  text: (docIds: number[], phone: string, allDocs: boolean) => Observable<any>;
  email: (docIds: number[], allDocs: boolean) => Observable<any>;
}

enum DisabledDocKey {
  TestFolder = 'test_folder_v2'
}

@Component({
  selector: 'app-choosed-documents',
  templateUrl: './choose-documents.component.html',
  styleUrls: ['./choose-documents.component.scss']
})

export class ChooseDocumentsComponent implements OnInit, OnDestroy {
  @Input() set enabledIsClickSend(value: boolean) {
    if (value) {
      this.isClickSend = false;
    }
  }

  constructor(
    private employeeService: EmployeesService,
    private cd: ChangeDetectorRef,
    private permissionService: PermissionAccessService,
  ) {
  }

  get selectedDocs() {
    if (this.documents.length === 1) {
      this.documents[0].checked = true;
    }
    return this.documents.filter((doc: DocumentTypes) => doc.checked);
  }

  @Input() actions: Partial<DocumentPopupActions>;
  @Input() mode: 'full' | 'offer' = 'full';
  @Input() isEditDocument = false;
  @Input() preselectedDocs: any[];
  @Input() newAppForm = false;
  @Input() isUserTpl = false;
  @Input() listOfSendingDoc: any[] = [];
  @Input() idEmployee: string;
  @Input() withoutId = false;

  @Output() closed: EventEmitter<any> = new EventEmitter();
  @Output() selDocuments: EventEmitter<any> = new EventEmitter();
  private destroy$: ReplaySubject<void> = new ReplaySubject<void>(1);
  next = false;
  documents: DocumentTypes[] = null;
  allSelected = false;
  activePhoneField = false;
  phone = '+1';
  isClickSend = false;
  phoneMeta = {
    error: null,
    success: false
  };
  filter = '';
  phoneMask = ['+', '1', '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  errors: any[] = [];
  blockRequest = false;
  RoleEnum = RoleEnum;
  DisabledDocKey = DisabledDocKey;

  protected readonly PermissionEmployeeEnum = PermissionEmployeeEnum;

  ngOnInit() {
    this.getDocumentTypes();
  }

  ngOnDestroy() {
    this.isClickSend = false;
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectAll() {
    this.allSelected = !this.allSelected;
    this.documents = this.documents.map((doc: DocumentTypes) => {
      if (!doc.isDisabled) {
        return {...doc, checked: this.allSelected};
      } else {
        return {...doc, checked: false};
      }
    });
    if (this.isUserTpl) {
      const data: DocumentTypes[] = this.allSelected ? this.documents : [];
      this.emitSelectedDocuments(data);
    }
  }

  toggle(index) {
    this.documents[index].checked = !this.documents[index].checked;
    this.allSelected = this.documents.filter((doc: DocumentTypes) => doc.checked).length === this.documents.length;
    if (this.isUserTpl) {
      this.emitSelectedDocuments(this.selectedDocs);
    }
    if (this.selectedDocs.length === 1 && this.selectedDocs[0].key === DisabledDocKey.TestFolder) {
      this.selectedDocs[0].checked = false;
      this.emitSelectedDocuments(this.selectedDocs);
    }
  }

  chooseAction(action: string) {
    if (this.actions && this.actions[action]) {
      const docIds: number[] = this.selectedDocs.map((item: DocumentTypes) => item.id);
      if (action === 'text') {
        this.isClickSend = true;
        this.phoneMeta = {...this.phoneMeta, error: false};
        if (this.blockRequest) {
          return;
        }
        this.blockRequest = true;
        this.actions
          .text(docIds, this.phone.replace(/-/g, '').replace(/_/g, ''), this.allSelected)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: () => {
              this.blockRequest = false;
              this.isClickSend = false;
              this.phoneMeta = {...this.phoneMeta, success: true};
              this.cd.detectChanges();
              setTimeout(() => {
                this.activePhoneField = false;
                this.phoneMeta = {error: null, success: false};
                this.cd.detectChanges();
              }, 3000);
            },
            error: (err: any) => {
              this.isClickSend = false;
              this.blockRequest = false;
              this.phoneMeta = {...this.phoneMeta, error: Object.values(err.error.violations || {}).join(' ')};
              this.cd.detectChanges();
            }
          });
      } else {
        if (this.blockRequest) {
          return;
        }
        this.blockRequest = true;
        if (!this.newAppForm) {
          this.isClickSend = true;
          this.actions[action](docIds, this.allSelected).pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.blockRequest = false;
            this.isClickSend = false;
            this.cd.detectChanges();
            this.closed.emit();
          }, (err) => {
            this.blockRequest = false;
            this.isClickSend = false;
            this.errors = Object.values(err.error.violations || {});
            this.cd.detectChanges();
          });
        } else {
          this.isClickSend = true;
          this.actions[action](docIds, this.allSelected).pipe(takeUntil(this.destroy$)).pipe(
            finalize(() => {
              this.blockRequest = false;
              this.isClickSend = false;
              this.cd.detectChanges();
            })
          ).subscribe();
          this.blockRequest = false;
        }
      }
    }
  }

  toggleStep() {
    this.filter = '';
    if (this.documents.filter((doc: DocumentTypes) => doc.checked).length) {
      this.next = !this.next;
    }
  }

  showDoc(docName) {
    return this.filter === '' || docName.toLowerCase().includes(this.filter.toLowerCase());
  }

  private emitSelectedDocuments(documents: DocumentTypes[]) {
    this.selDocuments.emit(documents);
  }

  private getDocumentTypes() {
    this.employeeService.getDocumentTypes(this.idEmployee, this.withoutId).pipe(take(1)).subscribe({
      next: (data: DocumentTypes[]) => {
        if (this.mode === 'full') {
          this.documents = data.map((item: DocumentTypes) => (
            {...item, checked: false}
          ));
          if (this.preselectedDocs) {
            this.preselectedDocs.forEach((doc: any) => {
              if (doc.key === 'annual_competency_perform_eval_checklist') {
                this.documents.push({...doc, checked: false});
              }
            });
          }
          if (environment.client === EnvironmentEnum.Aac) {
            this.documents = this.isEditDocument ?
              this.documents.filter((item: DocumentTypes): boolean => item.key !== 'orientation_checklist_for_staff') :
              this.documents
                .filter((item: DocumentTypes) =>
                  item.key !== 'orientation_checklist_for_staff' &&
                  item.key !== 'annual_competency_perform_eval_checklist');
          } else {
            this.documents = this.isEditDocument
              ? this.documents
              : this.documents.filter((item: DocumentTypes): boolean => item.key !== 'annual_competency_perform_eval_checklist');
          }
        } else {
          this.documents = data.filter((item: DocumentTypes) => item.name.toLowerCase().includes('offer'));
          this.toggle(0);
          this.toggleStep();
        }

        if (this.listOfSendingDoc.length) {
          this.documents.forEach((elementDoc: DocumentTypes) => {
            this.listOfSendingDoc.forEach(docFromSMS => {
              if (elementDoc.id === docFromSMS.id) {
                elementDoc.checked = docFromSMS.checked;
              }
            });
          });
          this.allSelected = this.documents.filter((doc: DocumentTypes) => doc.checked).length === this.documents.length;
        }

        if (this.preselectedDocs && this.preselectedDocs.length) {
          this.preselectedDocs.forEach((doc: DocumentTypes) => {
            const index: number = this.documents.findIndex((document: DocumentTypes): boolean => document.id === doc.id);
            if (this.documents[index]) {
              this.toggle(index);
            }
          });
          this.toggleStep();
        }
        this.cd.detectChanges();
      },
      error: () => {
        this.documents = [];
      }
    });
  }

  public hasPermissionPrint(): Observable<boolean> {
    return this.permissionService.hasAllPermissions([
      PermissionEmployeeEnum.ApproveEmployee,
      PermissionEmployeeEnum.DownloadDocuments
    ]);
  }
}

@NgModule({
  declarations: [ChooseDocumentsComponent],
  imports: [PerfectScrollbarModule, CommonModule, FormsModule, ReactiveFormsModule, MaskDirective, SharedModule],
  exports: [ChooseDocumentsComponent],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class ChooseDocumentsModule {
}
