import { ChangeDetectorRef, Component, Input, NgModule, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { UploadDocument } from '../../dashboard/store/document-history/history.actions';
import { CommonModule } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { Documents } from '../../shared/models';

enum PageUploadTextEnum {
  PageUploaded = 'Page uploaded',
  Uploading = 'Uploading'
}

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss']
})
export class UploadDocumentComponent implements OnDestroy {
  @Input() documentsList: Documents[];
  @Input() draftList: any[];
  private destroy$: ReplaySubject<void> = new ReplaySubject<void>(1);
  public error: { [key: number]: string } = {0: ''};
  pageNumber = 1;
  next = false;
  selectedDocument: Documents;
  blockBackButton = false;
  filter = '';
  disableSelectPage = false;
  PageUploadTextEnum = PageUploadTextEnum;

  constructor(
    private store: Store,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleStep(document?: Documents) {
    this.setDocument(document);
    this.next = !this.next;
  }

  setPage(page: number) {
    this.pageNumber = page;
    this.selectedDocument?.pages?.map((item, index: number): boolean => item.checked = page === (index + 1));
  }

  setDocument(document: Documents) {
    if (document) {
      if (document.pages.length === document.totalPages) {
        this.setPage(1);
      }
      document.pages = document.pages.map((doc: any, index: number) => {
        return {
          checked: index === 0,
          title: typeof doc === 'string' || !doc ? doc : doc?.title
        };
      });
      this.selectedDocument = document;
    } else {
      this.selectedDocument.pages.map(item => item.checked = false);
      this.selectedDocument = null;
    }
  }

  checkShowDoc(docName: string): boolean {
    return !this.filter || docName.toLowerCase().includes(this.filter.toLowerCase());
  }

  uploadDocument(selectedDocument: Documents) {
    const input: HTMLInputElement = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/png,image/jpeg';
    input.click();
    input.addEventListener('change', (event: Event) => {
      this.disableSelectPage = true;
      const file: File = (<any>event.target).files[0];
      selectedDocument.pages[this.pageNumber - 1].title = PageUploadTextEnum.Uploading;
      this.store.dispatch(new UploadDocument({
        documentType: selectedDocument.id,
        page: this.pageNumber,
        finished:
          this.documentsList.every((document: Documents): boolean => {
            return document.pages
              .filter(docPage => docPage?.title === PageUploadTextEnum.PageUploaded || docPage?.checked).length === document.totalPages;
          }),
        file
      })).pipe(takeUntil(this.destroy$)).subscribe({
        next: () => {
          selectedDocument.pages[this.pageNumber - 1].title = PageUploadTextEnum.PageUploaded;
          this.error[this.pageNumber - 1] = '';
          this.disableSelectPage = false;
          this.cd.detectChanges();
        },
        error: (error: any) => {
          this.error[this.pageNumber - 1] = error.error?.violations?.file && error.error?.violations?.file[0] || error.errorMessages;
          selectedDocument.pages[this.pageNumber - 1].title = '';
          this.disableSelectPage = false;
          this.cd.detectChanges();
        }
      });
      this.cd.detectChanges();
    });
  }
}

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [UploadDocumentComponent],
  exports: [UploadDocumentComponent]
})
export class UploadDocumentModule {
}
