const imageTypes = [
  'jpg',
  'png',
  'jpeg',
  'gif'
];
export const isImageExt = (fileExt: string) => {
  return imageTypes.includes(fileExt);
};

export const isImage = (file: File) => {
  const type = file.type.split('/')[1] || file.type.split('/')[0];
  return isImageExt(type);
};
