import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { take } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { LoginUseMicrosoftUrl } from '../../store/app.actions';

@Component({
  selector: 'app-login-by-microsoft-redirect-url',
  templateUrl: './login-by-microsoft-redirect-url.component.html',
  styleUrls: ['./login-by-microsoft-redirect-url.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginByMicrosoftRedirectUrlComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private store: Store
  ) {
  }

  ngOnInit() {
    this.route.queryParams.pipe(take(1)).subscribe((params: Params) => {
      if (params?.token) {
        this.store.dispatch(new LoginUseMicrosoftUrl(params.token));
      }
    });
  }
}
