import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Store } from '@ngxs/store';
import { AuthService } from '../auth.service';
import { Logout } from '../../store/app.actions';
import { UserModel } from '../../shared/models';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private store: Store,
    private router: Router
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser: UserModel = this.store.selectSnapshot(({app}) => app.currentUser);
    const oldToken = this.store.selectSnapshot(({app}) => app.oldToken);
    const newToken = this.store.selectSnapshot(({app}) => app.newToken);
    if (currentUser && currentUser.token) {
      let currentToken: string = currentUser?.token;
      if (oldToken !== newToken) {
        currentToken = newToken;
      }
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentToken}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if ((error.status === 401 && currentUser) || (error.message === 'Token has expired')) {
          this.store.dispatch(new Logout());
        }
        if (error.status === 403) {
          this.router.navigate(['/dashboard/403']);
        }
        if (error.status === 404) {
          this.router.navigate(['/dashboard/404']);
        }
        if ((error.status >= 500 && error.status <= 599)) {
          this.router.navigate(['/dashboard/500']);
        }
        return throwError(() => error);
      })
    );
  }
}
