import { CalendarService } from '../../services/calendar.service';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  CreateEvent,
  DeleteEvent,
  EditEvent,
  GetAuthLink,
  GetEventById,
  GetEvents, GetToken, GetTokenSuccess,
  ModalEvent, SetEventSuccess, SetSelectedEvent,
  TypeModalEvent
} from './calendar.action';
import { Event } from './interfaces/event.interface';
import { finalize, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

export interface CalendarStateModel {
  events: Event[];
  stateModal: TypeModalEvent;
  activeEventModal: Event;
  authLink: string;
  pageToken: string;
  selectedEvent: Event;
}

const defaultState: CalendarStateModel = {
  events: [],
  stateModal: TypeModalEvent.Close,
  activeEventModal: null,
  authLink: '',
  pageToken: '',
  selectedEvent: null
};

@State({
  name: 'calendarState',
  defaults: defaultState
})

@Injectable()
export class CalendarState {
  constructor(
    private service: CalendarService,
    private store: Store
  ) {
  }

  @Selector()
  static authLink(state): string {
    return state.authLink;
  }

  @Selector()
  static selectedEvent(state): string {
    return state.selectedEvent;
  }

  @Selector()
  static events(state): Event[] {
    return state.events;
  }

  @Selector()
  static pageToken(state): Event[] {
    return state.pageToken;
  }

  @Selector()
  static stateModal(state): Observable<TypeModalEvent> {
    return state.stateModal;
  }

  @Selector()
  static activeEventModal(state): Observable<TypeModalEvent> {
    return state.activeEventModal;
  }

  @Action(GetAuthLink)
  getAuthLink(ctx: StateContext<CalendarStateModel>) {
    return this.service.getCalendarAuthLink().pipe(
      tap((authLink: any) => {
        ctx.patchState({
          authLink: authLink.link
        });
      })
    );
  }

  @Action(GetEvents)
  getEvents(ctx: StateContext<CalendarStateModel>, { params, uid }: GetEvents) {
    return this.service.getEvents(params, uid).pipe(
      tap(({items, pageToken}) => {
        items.map(item => {
            item.summary = item.summary === null ? 'No title' : item.summary;
            return item;
        });
        ctx.patchState({
          events: items,
          pageToken: pageToken,
        });
      })
    );
  }

  @Action(CreateEvent)
  createEvent(ctx: StateContext<CalendarStateModel>, {event, uid}) {
    const state = ctx.getState();
    if ( event.start.data ===  event.end.data) {
      event.start = { dateTime: event.start.datetime };
      event.end = { dateTime: event.end.datetime };
    }
    return this.service.creatEvent(event, uid).pipe(
      tap((newEvent) => {
            this.store.dispatch(new SetEventSuccess());
            ctx.patchState({
              events: [...state.events, newEvent],
              selectedEvent: null
            });
      }));
  }

  @Action(GetEventById)
  getEventById(ctx: StateContext<CalendarStateModel>, {id, uid}: GetEventById) {
    return this.service.getEventById(id, uid);
  }

  @Action(EditEvent)
  editEvent(ctx: StateContext<CalendarStateModel>, {id, event, uid}) {
    const state = ctx.getState();
    if (event.start.data ===  event.end.data) {
      event.start = { dateTime: event.start.datetime };
      event.end = { dateTime: event.end.datetime };
    }
    return this.service.updateEvent(id, event, uid).pipe(
      tap((newEvent: Event) => {
        this.store.dispatch(new SetEventSuccess());
        ctx.patchState({
          events: state.events.map((e) => e.id === id ? newEvent : e),
          selectedEvent: null
        });
      })
    );
  }

  @Action(DeleteEvent)
  deleteEvent(ctx: StateContext<CalendarStateModel>, {id, uid}: DeleteEvent) {
    const state = ctx.getState();
    return this.service.deleteEvent(id, uid).pipe(
      tap(() => ctx.patchState({
        events: state.events.filter((event) => event.id !== id)
      }))
    );
  }

  @Action(ModalEvent)
  modalEvent(ctx: StateContext<CalendarStateModel>, {stateModal, id}: ModalEvent) {
    const state = ctx.getState();

    ctx.patchState({
      stateModal,
      activeEventModal: id ? state.events.find(event => event.id === id) : null
    });
  }

  @Action(GetToken)
  getToken(ctx: StateContext<CalendarStateModel>, code: string) {
    return this.service.getToken(code).pipe(finalize(() => this.store.dispatch(new GetTokenSuccess())));
  }

  @Action(SetSelectedEvent)
  setSelectedEvent(ctx: StateContext<CalendarStateModel>, {event}: SetSelectedEvent) {
    ctx.patchState({selectedEvent: event});
  }
}
