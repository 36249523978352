import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, switchMap, take } from 'rxjs/operators';
import { GetAuthLink } from '../dashboard/store/calendar/calendar.action';
import { Logout } from '../store/app.actions';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserModel } from '../shared/models';
import { getDataFromLocalStorage } from '../shared/helpers/other';
import { PermissionCalendarEnum } from '../shared/enums/permission.enum';

export interface LoginDto {
  email: string;
  password: string;
}

@Injectable()
export class AuthService {
  constructor(
    private http: HttpClient,
    private store: Store,
    @Inject('API_URL') private apiUrl: string
  ) {
  }

  signIn(credentials: LoginDto): Observable<any> {
    return this.http.post(`${this.apiUrl}/v2/auth/login`, credentials);
  }

  getLoginUseMicrosoftUrl(): Observable<{ redirectUrl: string }> {
    return this.http.get<{ redirectUrl: string }>(`${this.apiUrl}/v2/saml2/microsoft/login`);
  }

  getUser(token: string = getDataFromLocalStorage('user')?.token): Observable<UserModel> {
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<UserModel>(`${this.apiUrl}/users/me`, {headers})
      .pipe(
        map((user: UserModel) => {
          return {
            ...user,
            token
          };
        })
      );
  }

  getPagination(token: string): Observable<{ key: string; perPage: number }[]> {
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<{ key: string; perPage: number }[]>(`${this.apiUrl}/user-settings/pagination`, {headers});
  }

  logout() {
    const permission: string = this.store.selectSnapshot(({app}) => app.currentUser.permissions);
    if (permission.includes(PermissionCalendarEnum.ViewEvents)) {
      this.store.dispatch(new GetAuthLink());
    }
    this.http.post(`${this.apiUrl}/v2/auth/logout`, {}).pipe(
      switchMap(() => {
        return this.store.dispatch(new Logout());
      }),
      take(1)
    ).subscribe();
  }
}
