export const timeAvailability = {
  time: [
    {
      'id': 1,
      'name': 'Full Time',
      'createdAt': '2018-10-15T00:00:00+0000'
    },
    {
      'id': 3,
      'name': 'Part Time',
      'createdAt': '2018-10-15T00:00:02+0000',
    }],
  availabilities: [
    {
      'id': 2,
      'name': 'Monday Through Friday',
      'createdAt': '2018-10-15T00:00:01+0000'
    },

    {
      'id': 4,
      'name': 'Evenings/Weekends',
      'createdAt': '2018-10-15T00:00:03+0000'
    }
  ]
};

