import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { switchMap, take, tap } from 'rxjs/operators';
import {
  DelAdmissionsNotesById,
  DownloadAdmission,
  GetAdmissionById,
  GetAdmissionsNotesById,
  GetAdmissionStatusList,
  GetSupervisors,
  SetAdmissionsNotesById,
  SetPage,
  SetPerPage,
  SyncHHA,
  UpdateAdmissionsNotesById,
  UpdateStartDate,
  UpdateSupervisor,
  GetAdmissionsList,
  GetAdmissionSyncStatus,
  SetAdmissionsNotePage,
  GetAdmissionsNotesByAdmissionId,
  ClearAdmissionNotes,
  GetPatientNoteReasons,
  UpdateAdmissionsNotesByAdmissionId,
  DelAdmissionsNotesByAdmissionId,
  GetAdmissionDetails,
  GenerateAdmission,
  SetAdmissionsDetailsPage,
  DeleteAdmission,
  ChangeAdmissionCreatedDate,
  GetAdmissionsAllNotesById,
  SetAdmissionsNotePageScroll,
  ChangeAdmissionOffice,
  ChangeAdmissionSignDate,
  GetPatientNoteReasonsByPatientId,
  GetAdmissionsListType,
  SetAssignedUserAdmission,
  GetUserListToAssignAdmission,
  DeleteAssignedUserAdmission,
  GetUserListToAssignFilterAdmission
} from './admissions.actions';
import { AdmissionsService } from '../../services/admissions.service';
import { UserService } from '../../services/user.service';
import { Admission } from '../../admissions/admissions.model';
import { Note } from '../../incidents/incidents.model';
import { OpenMessagePopup } from '../dashboard.actions';
import { Injectable } from '@angular/core';
import { StaticDataService } from 'src/app/static-data.service';
import { Reason } from '../patients/patients-list/patients.state';
import { openLink } from '../../../shared/helpers/open-link';
import { Observable } from 'rxjs';
import { concatenateStrSeparatedByCommas } from '../../../shared/helpers/other';

export interface AdmissionDto {
  address: string;
  city: string;
  dob: string;
  id: string;
  patient: string;
  patientId: string;
  phone: string;
  physicianName: string;
  physicianPhone: string;
  ssn: string;
  startDate: string;
  state: string;
  status: { id: number, title: string };
  supervisor: { id: string, name: string };
  type: string;
  zip: string;
}

export interface AdmissionsStateModel {
  list: AdmissionDto[];
  totalNotes: number;
  notes: any[];
  admissionNotes: AdmissionDto[];
  admissionAllNotes: AdmissionDto[];
  userList: [];
  userListFilter: [];
  page: number;
  perPage: number;
  total: number;
  query: string;
  type: string;
  notePage: number;
  notePageScroll: number;
  reason: Reason[];
  detailsPageAdm: number;
  statuses: any[];
  statusList: any[];
  supervisorsList: any[];
  startDate: string;
  listAdmissionPatient: any[];
  totalAdmissionPatient: number;
  currentAdmission: any;
  types: AdmissionTypes[];
}

export interface AdmissionTypes {
  id: number;
  title: string;
  key: string;
}

export const defaultState: AdmissionsStateModel = {
  list: [],
  totalNotes: 0,
  notes: [],
  admissionNotes: null,
  admissionAllNotes: null,
  userList: [],
  userListFilter: [],
  page: 1,
  notePage: 1,
  notePageScroll: 1,
  detailsPageAdm: 1,
  perPage: 10,
  total: 0,
  query: '',
  type: '',
  statuses: [],
  statusList: [],
  supervisorsList: [],
  startDate: '',
  reason: [],
  listAdmissionPatient: [],
  totalAdmissionPatient: 0,
  currentAdmission: {},
  types: []
};


@State({
  name: 'admissions',
  defaults: defaultState
})

@Injectable()
export class AdmissionsState {

  constructor(
    private service: AdmissionsService,
    private userService: UserService,
    private staticService: StaticDataService,
    private store: Store
  ) {
  }

  @Selector()
  static list(state) {
    return state.list;
  }

  @Selector()
  static admissionNotes(state) {
    return state.admissionNotes;
  }

  @Selector()
  static admissionAllNotes(state) {
    return state.admissionAllNotes;
  }

  @Selector()
  static total(state) {
    return state.total;
  }

  @Selector()
  static page(state) {
    return state.page;
  }

  @Selector()
  static types(state): AdmissionTypes[] {
    return state.types;
  }

  @Selector()
  static statuses(state) {
    return state.statusList;
  }

  @Selector()
  static usersToAssign(state) {
    return state.userList;
  }

  @Selector()
  static usersToAssignFilter(state) {
    return state.userListFilter;
  }

  @Selector()
  static supervisors(state) {
    return state.supervisorsList;
  }

  @Selector()
  static notePage(state) {
    return state.notePage;
  }

  @Selector()
  static notePageScroll(state) {
    return state.notePageScroll;
  }

  @Selector()
  static totalNotes(state) {
    return state.admissionNotes.totalNotes;
  }

  @Selector()
  static detailsPageAdm(state) {
    return state.detailsPageAdm;
  }

  @Selector()
  static listAdmissionPatient(state) {
    return state.listAdmissionPatient;
  }

  @Selector()
  static totalAdmissionPatient(state) {
    return state.totalAdmissionPatient;
  }

  @Selector()
  static reason(state) {
    return state.reason;
  }

  getFilters(state) {
    let {supervisors} = state;
    const {query, page, perPage, statuses, portalPatientStatuses, startDate, endDate, type, assigned} = state;
    if (!supervisors) {
      supervisors = [];
    }
    return {
      query, page, perPage, startDate, endDate, type,
      'statuses[]': statuses,
      'supervisors[]': supervisors,
      'portalPatientStatuses[]': portalPatientStatuses,
      'assigned[]': assigned,
    };
  }

  @Action(GetAdmissionsList)
  getList(ctx: StateContext<AdmissionsStateModel>, {filterData, sort}: GetAdmissionsList, refresh?) {
    if (!refresh) {
      ctx.patchState({
        // page: 1,
        perPage: this.store.selectSnapshot(({app}) => app.perPages?.find(per => per?.key === 'admission')?.perPage)
      });
    }
    if (!filterData) {
      filterData = {
        statuses: [],
        supervisors: [],
        portalPatientStatuses: [],
        assigned: [],
        query: '',
        endDate: '',
        startDate: '',
        type: '',
      };
    }
    ctx.patchState({
      ...filterData
    });
    const state: AdmissionsStateModel = ctx.getState();
    let obj = {
      ...this.getFilters(state)
    };
    if (sort) {
      obj = {
        ...obj,
        ...sort
      };
    }
    return this.service.getList({
      ...obj
    })
      .pipe(tap(({data, total}: any) => {
        ctx.patchState({
          list: data.map(item => ({...item, signed: item.status.title === 'Signed'})),
          total: total
        });
      }));
  }

  @Action(GetAdmissionDetails)
  getAdmissionDetails(ctx: StateContext<AdmissionsStateModel>, {patientId, filters}: GetAdmissionDetails) {
    const state: AdmissionsStateModel = ctx.getState();
    let payload = {
      page: state.detailsPageAdm,
      perPage: this.store.selectSnapshot(({app}) => app.perPages?.find(per => per?.key === 'admission_detail')?.perPage)
    };
    if (filters) {
      payload = {
        ...payload,
        ...filters,
        page: state.detailsPageAdm,
        'portalPatientStatuses[]': filters?.portalPatientStatuses ? filters?.portalPatientStatuses : [],
        'statuses[]': filters?.statuses,
        'supervisors[]': filters?.supervisors,
        'assigned[]': filters?.assigned,
      };
      delete payload['statuses'];
      delete payload['supervisors'];
      delete payload['portalPatientStatuses'];
      delete payload['assigned'];
    }

    return this.service.getAdmissionDetails(patientId, payload).pipe(
      tap(({listAdmissionPatient, totalAdmissionPatient}: any) => {
        ctx.patchState({
          list: listAdmissionPatient.map((item: any) => {
            return {
              ...item,
              documents: concatenateStrSeparatedByCommas(item.documents)
            };
          }),
          totalAdmissionPatient
        });
      })
    );
  }

  @Action(UpdateSupervisor)
  updateSupervisor(ctx: StateContext<AdmissionsStateModel>, {id, supervisorId}: UpdateSupervisor) {
    return this.service.setSupervisor(id, supervisorId);
  }

  @Action(UpdateStartDate)
  updateStartDate(ctx: StateContext<AdmissionsStateModel>, {id, startDate}: UpdateStartDate) {
    return this.service.setStartDate(id, startDate);
  }

  @Action(GetAdmissionStatusList)
  getStatuses(ctx: StateContext<AdmissionsStateModel>): Observable<any> {
    return this.service.getStatuses().pipe(tap((data: any[]) => {
      ctx.patchState({
        statusList: data
      });
    }));
  }

  @Action(GetAdmissionsListType)
  getTypes(ctx: StateContext<AdmissionsStateModel>): Observable<AdmissionTypes[]> {
    return this.service.getTypes().pipe(tap((data: AdmissionTypes[]) => {
      ctx.patchState({
        types: data
      });
    }));
  }

  @Action(SetPage)
  setPage(ctx: StateContext<AdmissionsStateModel>, {page}: SetPage) {
    ctx.patchState({
      page
    });
  }

  @Action(ChangeAdmissionCreatedDate)
  changeAdmissionCreatedDate(ctx: StateContext<AdmissionsStateModel>, payload: ChangeAdmissionCreatedDate): Observable<any> {
    return this.userService.changeAdmissionCreatedDate(payload).pipe(take(1));
  }

  @Action(SetPerPage)
  setPerPage(ctx: StateContext<AdmissionsStateModel>, {perPage}: SetPerPage) {
    ctx.patchState({
      perPage
    });
    this.getList(ctx, {});
  }

  @Action(GetSupervisors)
  getSupervisors(ctx: StateContext<AdmissionsStateModel>): Observable<any[]> {
    return this.userService.getSupervisors()
      .pipe(tap((data: any[]) => {
        ctx.patchState({
          supervisorsList: data
        });
      }));
  }

  @Action(DownloadAdmission)
  downloadAdmission(ctx: StateContext<AdmissionsStateModel>, {id, format}: DownloadAdmission) {
    return this.service.download(id, format).pipe(tap(({link}: any) => {
      if (link) {
        openLink(link);
      }
    }));
  }

  @Action(GetAdmissionById)
  getAdmissionById(ctx: StateContext<AdmissionsStateModel>, {id, discharge}: GetAdmissionById) {
    return this.service.getById(id, discharge);
  }

  @Action(GetAdmissionsNotesById)
  getAdmissionsNotesById(ctx: StateContext<any>, {id, filters}) {
    const state = ctx.getState();
    const obj = {
      page: state.notePage,
      perPage: filters?.perPage || 10,
      notSavePerPage: filters?.notSavePerPage ? 1 : 0
    };
    return this.service.getAdmissionsNotesById(id, obj)
      .pipe(
        tap(({data, totalNotes}) => {
          const list = ctx.getState().list
            .map(admission => {
              if (admission.id === id) {
                if (state.notePage > 1 && Object.values(data || {}).length) {
                  admission.notes = [...admission.notes, ...Object.values(data || {})];
                } else if (Object.values(data || {}).length) {
                  admission.notes = Object.values(data || {});
                } else if (totalNotes === 0) {
                  admission.notes = Object.values(data || {});
                }
              }
              return admission;
            });
          ctx.patchState({
            list,
            listAdmissionPatient: list,
            notes: data,
            totalNotes,
          });
        })
      );
  }

  @Action(GetAdmissionsAllNotesById)
  getAdmissionsAllNotesById(ctx: StateContext<any>, {id, filters}) {
    const state = ctx.getState();
    const obj = {
      page: state.notePageScroll,
      perPage: filters?.perPage || 10,
      notSavePerPage: filters?.notSavePerPage ? 1 : 0
    };
    return this.service.getAdmissionsNotesById(id, obj)
      .pipe(
        tap(({data, totalNotes}) => {
          let admissionAllNotes;
          if (state.notePageScroll > 1 && Object.values(data || {}).length) {
            admissionAllNotes = [...state.admissionAllNotes.data, ...Object.values(data || {})];
          } else if (Object.values(data || {}).length) {
            admissionAllNotes = Object.values(data || {});
          } else if (totalNotes === 0) {
            admissionAllNotes = Object.values(data || {});
          }
          ctx.patchState({
            admissionAllNotes: {
              data: admissionAllNotes,
              totalNotes,
            },
          });
        })
      );
  }

  @Action(SetAdmissionsNotesById)
  setAdmissionsNotesById(ctx: StateContext<any>, {data}) {
    return this.service.setAdmissionsNotesById(data.id, data.text, data.reason).pipe(tap((value: Note) => {
      const list = ctx.getState().list.map(admission => {
        if (admission.id === data.id) {
          admission.note = value.text;
          if (admission.notes) {
            admission.notes.unshift(value);
          } else {
            admission.notes = [value];
          }
        }
        return admission;
      });
      ctx.patchState({
        list
      });
    }));
  }

  @Action(UpdateAdmissionsNotesById)
  updateAdmissionsNotesById(ctx: StateContext<any>, {data}) {
    return this.service.updateAdmissionsNoteById(data.id, data.note.id, data.note.text, data.note.reason).pipe(tap((value: Note) => {
      const list = ctx.getState().list.map(admission => {
        if (admission.id === data.id) {
          admission.notes = admission.notes.map(note => note.id === value.id ? value : note);
          admission.note = admission.notes[0].text;
        }
        return admission;
      });
      ctx.patchState({
        list
      });
    }));
  }

  @Action(DelAdmissionsNotesById)
  deleteIncidentNotesById(ctx: StateContext<any>, {data}) {
    return this.service.deleteAdmissionsNotesById(data.id, data.uuid).pipe(tap(() => {
      const list = ctx.getState().list.map(admission => {
        if (admission.id === data.id) {
          if (admission.notes) {
            admission.notes = admission.notes.filter(item => item.id !== data.uuid);
            admission.note = admission.notes.length ? admission.notes[0].text : '';
          } else {
            admission.notes = ctx.getState().notes.map(note => {
              if (note.id !== data.uuid) {
                return note;
              }
            }).filter((note): boolean => {
              return note != null;
            });
          }
        }
        return admission;
      });
      ctx.patchState({
        list
      });
    }));
  }

  @Action(SetAdmissionsNotePage)
  setAdmissionsNotePage(ctx: StateContext<any>, {page}: SetAdmissionsNotePage) {
    return ctx.patchState({
      notePage: page
    });
  }

  @Action(SetAdmissionsNotePageScroll)
  setAdmissionsNotePageScroll(ctx: StateContext<any>, {page}: SetAdmissionsNotePage) {
    return ctx.patchState({
      notePageScroll: page
    });
  }

  @Action(GetPatientNoteReasonsByPatientId)
  getPatientNoteReasonsByPatientId(ctx: StateContext<any>, {id}: any) {
    return this.staticService.getPatientNoteReason(id).pipe(
      tap((res: Reason[]) => {
        ctx.patchState({
          reason: res
        });
      })
    );
  }

  @Action(GetPatientNoteReasons)
  getPatientNoteReasons(ctx: StateContext<any>, {id, discharge}: any) {
    return this.service.getById(id, discharge)
      .pipe(
        switchMap(((incident: any) =>
          this.staticService.getPatientNoteReason(incident.patient.id).pipe(
            tap((res: Reason[]) => {
              ctx.patchState({
                reason: res
              });
            })
          ))
        ));
  }

  @Action(SetAdmissionsDetailsPage)
  settAdmissionsDetailsPage(ctx: StateContext<any>, {page}: SetAdmissionsDetailsPage) {
    return ctx.patchState({
      detailsPageAdm: page
    });
  }

  @Action(SyncHHA)
  syncHHA(): Observable<any> {
    return this.service.sync().pipe(
      tap((data: { message: string, notifyUser: boolean }) => {
        this.store.dispatch(new OpenMessagePopup(data.message));
      })
    );
  }

  @Action(GetAdmissionSyncStatus)
  getSyncStatus(ctx: StateContext<any>): Observable<any[]> {
    return this.service.getSyncStatus().pipe(tap((data: any[]) => {
      ctx.patchState({
        syncStatus: data
      });
    }));
  }

  @Action(GetAdmissionsNotesByAdmissionId)
  getAdmissionsNotesByAdmissionId(ctx: StateContext<any>, {admissionId}) {
    return this.service.getAdmissionsNotesById(admissionId).subscribe((res: Admission[] | any) => {
      ctx.patchState({
        admissionNotes: res,
        admissionAllNotes: res,
        notePageScroll: 1,
      });
    });
  }

  @Action(UpdateAdmissionsNotesByAdmissionId)
  updateAdmissionsNotesByAdmissionId(ctx: StateContext<any>, {data}) {
    return this.service.updateAdmissionsNoteById(data.id, data.note.id, data.note.text, data.note.reason);
  }

  @Action(DelAdmissionsNotesByAdmissionId)
  delAdmissionsNotesByAdmissionId(ctx: StateContext<any>, {data}) {
    return this.service.deleteAdmissionsNotesById(data.id, data.uuid);
  }

  @Action(ClearAdmissionNotes)
  clearAdmissionNotes(ctx: StateContext<any>) {
    ctx.patchState({
      admissionNotes: null
    });
  }

  @Action(GenerateAdmission)
  generateAdmission(ctx: StateContext<any>, {id, type}) {
    return this.service.generateAdmission(id, type).pipe(
      tap((data: any) => {
        ctx.patchState({
          currentAdmission: data,
        });
      })
    );
  }

  @Action(DeleteAdmission)
  deleteAdmission(ctx: StateContext<any>, {id}: DeleteAdmission) {
    return this.service.deleteAdmission(id);
  }

  @Action(ChangeAdmissionOffice)
  changeAdmissionOffice(ctx: StateContext<any>, {id, departmentId}: ChangeAdmissionOffice) {
    return this.service.changeAdmissionOffice(id, departmentId);
  }

  @Action(ChangeAdmissionSignDate)
  changeAdmissionSignDate(ctx: StateContext<any>, {id, date}: ChangeAdmissionSignDate) {
    return this.service.changeAdmissionSignDate(id, date);
  }

  @Action(SetAssignedUserAdmission)
  setAssignedUser(ctx: StateContext<any>, {id, users}: SetAssignedUserAdmission) {
    return this.service.setAssignedUser(id, users);
  }

  @Action(GetUserListToAssignAdmission)
  getUserToAssign(ctx: StateContext<any>, {search}: GetUserListToAssignAdmission) {
    return this.service.getUserList(search, 0).pipe(
      tap((users) => {
        ctx.patchState({
          userList: users,
        });
      })
    );
  }

  @Action(GetUserListToAssignFilterAdmission)
  getUserToAssignFilter(ctx: StateContext<any>, {search}: GetUserListToAssignFilterAdmission) {
    return this.service.getUserList(search, 1).pipe(
      tap((users) => {
        ctx.patchState({
          userListFilter: users,
        });
      })
    );
  }

  @Action(DeleteAssignedUserAdmission)
  deleteAssignedUser(ctx: StateContext<any>, {admissionId, userId}: DeleteAssignedUserAdmission) {
    return this.service.deleteAssignedUser(admissionId, userId);
  }
}
