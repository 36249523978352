<div class="slide-container__body">
  <div class="slide-container__row">
    <div (click)="close();" class="close">
      <i class="icon-close"></i>
    </div>
    <div class="slide-container__content">
      <div class="slide-container__title">Search users</div>
      <div class="search-users">
        <label class="search-users__label">
          <input
            [formControl]="query"
            placeholder="Search by first or last name, email or role"
            type="search"/>
        </label>
        <div class="search-users__drop">
          <ng-container *ngIf="!(users$ | async)?.length; else search">
            <span class="no-users">No user to display</span>
          </ng-container>
          <ng-template #search>
            <ng-container *ngFor="let user of users$ | async">
              <div class="form-group">
                <input
                  (change)="checkItems(user, $event)" [id]="user.id + 'user'"
                  [checked]="checked(user)"
                  [disabled]="disableUncheck(user)"
                  type="checkbox"/>
                <label [for]="user.id + 'user'">
                  <span>{{user.name}}</span>
                </label>
              </div>
            </ng-container>
          </ng-template>
        </div>
        <div *ngFor="let user of usersSelected.value" class="search-users__selected">
          <div class="search-users__selected--item">
            <button (click)="delete(user)" class="icon-delete"></button>
            {{ user.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="slide-container__foot">
    <button (click)="close()" class="cancel">Cancel</button>
    <button
      (click)="assign()"
      [disabled]="!usersSelected.value.length"
      class="btn violet">
      Assign
    </button>
  </div>
</div>
