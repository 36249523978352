<div class="main-wrapper" cdkScrollable>
  <div *ngIf="showLoader" id="myLoader" class="overlay">
    <div class="icon">
      <img src="/assets/icons/upload.svg" alt=""/>
    </div>
    <div class="text">Loading</div>
  </div>
  <ngx-loading-bar [color]="'#6469b7'" [includeSpinner]="false"></ngx-loading-bar>
  <router-outlet></router-outlet>
  <ng-template #popupPortal></ng-template>

  <app-connection *ngIf="!(networkStatus$ | async)"></app-connection>
</div>
