import { Directive, HostListener, OnInit } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[appTrimControlValue]'
})
export class TrimControlValueDirective implements OnInit {
  protected control: AbstractControl = null;

  @HostListener('keypress', ['$event'])
  onInput(event) {
    if (!event.target.value.trim().length) {
      const value: string = this.control.value;
      if (value) {
        this.control.setValue(value.trim());
      }
      this.control.updateValueAndValidity();
    }
  }

  constructor(private ngControl: NgControl) {
  }

  ngOnInit(): void {
    this.control = this.ngControl.control;
  }
}
