import { UserListFilters } from '../../services/user.service';

export class GetLessonsCategoriesListForFilter {
  static readonly type = '[Educations] Get lessons categories list';
  constructor() {
  }
}

export class GetUserListForFilter {
  static readonly type = '[Educations] Get user list for filter';

  constructor(public params: UserListFilters) {
  }
}

export class GetLessons {
  static readonly type = '[Educations] Get lessons list';
  constructor(public filters?: any) {
  }
}

export class GetLessonById {
  static readonly type = '[Educations] Get lesson by id';
  constructor(public id: string | number) {
  }
}

export class CreateLesson {
  static readonly type = '[Educations] Create lesson';
  constructor(public payload: any) {
  }
}

export class UpdateLesson {
  static readonly type = '[Educations] Update lesson';
  constructor(public id: string | number, public payload: any) {
  }
}

export class DeleteLessonById {
  static readonly type = '[Educations] Delete lesson by id';
  constructor(public id: string | number) {
  }
}

export class SetPage {
  static readonly type = '[Educations] Set page';
  constructor(public page: number) {
  }
}

export class ConfirmSeenById {
  static readonly type = '[Educations] Confirm seen lesson';
  constructor(public id: string) {
  }
}

export class CloseConfirmPopup {
  static readonly type = '[Educations] Close confirm popup';
  constructor(public isCloseDoc: boolean) {
  }
}

export class IsEducationDataLoaded {
  static readonly type = '[Educations] Is education data loaded';
  constructor(public isEducationDataLoaded: boolean) {
  }
}
