export class SetTempPhoto {
  static readonly type = '[User Form] Set photo for upload';

  constructor(public file: { name: string, value: string }) {
  }
}

export class DeleteTempPhoto {
  static readonly type = '[User Form] Delete temporal photo';
}

export class SetFormErrors {
  static readonly type = '[User Form] Set errors';

  constructor(public error: any) {
  }
}

export class SetLoadingStatus {
  static readonly type = '[User Form] Set button loading status';

  constructor(public loadingConfig: { photoLoading?: boolean, formLoading?: boolean }) {
  }
}

export class SetUserSign {
  static readonly type = '[User Form] Set User sign';

  constructor(public signUrl: string) {
  }
}
