export enum AdmissionTypeEnum {
  Admission = 1,
  SupervisorReport = 2,
  DischargeSummary = 3
}

export enum TrainingCompletedEnum {
  Person = 'person',
  Phone = 'phone',
  None = 'none'
}
