import { Action, Select, Selector, State, StateContext } from '@ngxs/store';
import { AdmissionsService } from '../../services/admissions.service';
import {
  ApproveAdmission,
  CreateAdmissionFromAttachment,
  DeclineAdmission,
  DocumentsListAdmission,
  GetAdmissionById,
  GetAdmissionByToken,
  GetAdmissionDepartments,
  GetAdmissionPayers,
  GetAdmissionStates,
  GetRatings,
  GetRelationships,
  GetReligions,
  GetStatuses,
  LoadAdmission,
  ResetFormValueAndDischargeSummery,
  ResetSignatureVerificationAndConfirmationErrors,
  ResetState,
  SaveAdmission,
  SaveScheduleDaysVisit,
  SetAction,
  SetButtonOptions,
  SetFirstSignShowArrowAdmission,
  SetPatientSigner,
  SetPatientSignerWithoutUser,
  SetSignatureVerificationAndConfirmationErrors,
  SetSigned,
  SetSignedWithoutLogin,
  SetStarted,
  SignDocument,
  SignDocumentWithoutLogin
} from './admission-form.actions';
import { catchError, take, tap } from 'rxjs/operators';
import { StaticDataService } from '../../../static-data.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AdmissionTypeEnum } from '../../../shared/enums/admission.enum';
import { DischargeSummary } from './admission-model';
import { ButtonsOptions } from '../../admission-application/interfaces';
import { MessagePopupComponent } from '../../../popups/message-popup/message-popup.component';
import { SignaturePopupComponent } from '../../../popups/signature-popup/signature-popup.component';
import { UpdateProfileUserSign } from '../users/users.actions';
import { PopupFactoryService } from '../../../popups/popup-factory.service';

interface AdmissionFormStateModel {
  formValue: any;
  listAdmissionDocuments: any;
  relationships: any[];
  ratings: any[];
  religions: any[];
  states: any[];
  statuses: any;
  list: any[];
  payers: { id: string, title: string }[];
  createAdmissionFromAttachmentPayload: any;
  error: any;
  load: boolean;
  updateFirstSignShowArrowAdmission?: boolean;
  config: {
    isCanBeComplete: boolean;
    currentId: string;
    currentTypeOfApplication: AdmissionTypeEnum;
  };
  buttonsOptions: ButtonsOptions;
  dischargeSummary: DischargeSummary | any;
  action: {
    name: string,
    data: any,
  };
}

export interface Payers {
  id: number;
  title: string;
  checked: boolean;
}

const defaultState: any = {
  formValue: {},
  listAdmissionDocuments: [],
  relationships: [],
  ratings: [],
  religions: [],
  states: [],
  statuses: {},
  list: [],
  createAdmissionFromAttachmentPayload: null,
  error: {},
  updateFirstSignShowArrowAdmission: false,
  load: false,
  config: {
    isCanBeComplete: false,
    currentId: '',
    currentTypeOfApplication: AdmissionTypeEnum.Admission,
  },
  dischargeSummary: {},
  buttonsOptions: {
    disableAutoSave: true,
    disableGenerateDocuments: true,
    disableCreateWithAttach: true,
    disableApprove: false,
    disabledSentToOffice: false,
  }
};

@State({
  name: 'admissionForm',
  defaults: defaultState
})

@Injectable()
export class AdmissionFormState {
  @Selector()
  static isCanBeComplete(state) {
    return state.config.isCanBeComplete;
  }

  @Selector()
  static currentTypeOfApplication(state) {
    return state.config.currentTypeOfApplication;
  }

  @Selector()
  static createAt(state) {
    return state.createdAt;
  }

  @Selector()
  static action(state) {
    return state.action;
  }

  @Selector()
  static list(state) {
    return state.list;
  }

  @Selector()
  static payers(state) {
    return state.payers;
  }

  @Selector()
  static formValue(state) {
    return state.formValue;
  }

  @Selector()
  static dischargeSummary(state) {
    return state.dischargeSummary;
  }

  @Selector()
  static error(state) {
    return state.error;
  }

  @Selector()
  static listSentDocument(state) {
    return state.formValue.patientSigner.documentsKeys;
  }

  @Selector()
  static caregivers(state) {
    return state.formValue.haveCriminal.caregivers;
  }

  @Select()
  static listAdmissionDocuments(state) {
    return state.listAdmissionDocuments;
  }

  @Selector()
  static relationships(state) {
    return state.relationships;
  }

  @Selector()
  static ratings(state) {
    return state.ratings;
  }

  @Selector()
  static religions(state) {
    return state.religions;
  }

  @Selector()
  static states(state) {
    return state.states;
  }

  @Selector()
  static createAdmissionFromAttachmentPayload(state) {
    return state.createAdmissionFromAttachmentPayload;
  }

  @Selector()
  static updateFirstSignShowArrowAdmission(state) {
    return state.updateFirstSignShowArrowAdmission;
  }

  @Selector()
  static buttonsOptions(state) {
    return state.buttonsOptions;
  }

  constructor(
    private service: AdmissionsService,
    private staticData: StaticDataService,
    private popup: PopupFactoryService,
  ) {
  }

  @Action(GetAdmissionById)
  getById(ctx: StateContext<AdmissionFormStateModel>, {id, discharge}) {
    return this.service.getById(id, discharge).pipe(tap((data: any) => {
      if (!discharge) {
        ctx.patchState({
          formValue: {
            ...data,
            patient: {
              ...data.patient,
              stateId: data.patient?.state?.id
            }
          },
          config: {
            isCanBeComplete: data.isCanBeComplete,
            currentId: data.id,
            currentTypeOfApplication: data.admissionType,
          }
        });
      } else {
        ctx.patchState({
          dischargeSummary: {
            ...data,
          },
          config: {
            isCanBeComplete: data.isCanBeComplete,
            currentId: data.id,
            currentTypeOfApplication: data.admissionType,
          }
        });
      }
    }));
  }

  @Action(GetAdmissionByToken)
  getAdmissionByToken(ctx: StateContext<AdmissionFormStateModel>, {token}: GetAdmissionByToken) {
    return this.service.getAdmissionInfoByToken(token).pipe(tap((data: any) => {
      if (data.admissionType === 3) {
        ctx.patchState({
          dischargeSummary: {
            ...data,
          },
          config: {
            isCanBeComplete: data.isCanBeComplete,
            currentId: data.id,
            currentTypeOfApplication: data.admissionType,
          }
        });
      } else {
        ctx.patchState({
          formValue: {
            ...data,
            patient: {
              ...data.patient,
              stateId: data.patient.state?.id
            }
          },
          config: {
            isCanBeComplete: data.isCanBeComplete,
            currentId: data.id,
            currentTypeOfApplication: data.admissionType,
          }
        });
      }
    }));
  }

  @Action(GetAdmissionStates)
  getStates(ctx: StateContext<AdmissionFormStateModel>): Observable<any[]> {
    return this.staticData.getStates().pipe(tap((data: any[]) => {
      ctx.patchState({
        states: data
      });
    }));
  }

  @Action(GetRelationships)
  getRelationships(ctx: StateContext<AdmissionFormStateModel>): Observable<any[]> {
    return this.service.getRelationships().pipe(tap((data: any[]) => {
      ctx.patchState({
        relationships: data
      });
    }));
  }

  @Action(GetReligions)
  getReligions(ctx: StateContext<AdmissionFormStateModel>): Observable<any[]> {
    return this.service.getReligions().pipe(tap((data: any[]) => {
      ctx.patchState({
        religions: data
      });
    }));
  }

  @Action(SaveAdmission)
  saveAdmission(ctx: StateContext<AdmissionFormStateModel>, {payload}: SaveAdmission) {
    const state: AdmissionFormStateModel = ctx.getState();
    const discharge: boolean = state.config.currentTypeOfApplication === AdmissionTypeEnum.DischargeSummary;
    return this.service.autoSave(state.config.currentId, payload, discharge).pipe(
      tap((data: any) => {
          if (discharge) {
            ctx.patchState({
              dischargeSummary: {
                ...data
              },
              config: {
                isCanBeComplete: data.isCanBeComplete,
                currentId: data.id,
                currentTypeOfApplication: data.admissionType,
              }
            });
          } else {
            ctx.patchState({
              formValue: {
                ...data,
                patient: {
                  ...data.patient,
                  stateId: data.patient.state.id
                }
              },
              config: {
                isCanBeComplete: data.isCanBeComplete,
                currentId: data.id,
                currentTypeOfApplication: data.admissionType,
              }
            });
          }
        }
      ),
      catchError((err) => {
        ctx.patchState({
          formValue: {
            ...state.formValue,
            isCanBeComplete: false,
          },
          config: {
            ...state.config,
            isCanBeComplete: false,
          }
        });
        return throwError(err);
      })
    );

  }

  @Action(ApproveAdmission)
  approveAdmission(ctx: StateContext<AdmissionFormStateModel>): Observable<any> {
    const state: AdmissionFormStateModel = ctx.getState();
    return this.service.approve(state.config.currentId);
  }

  @Action(DeclineAdmission)
  declineAdmission(ctx: StateContext<AdmissionFormStateModel>, {comment, withSigns}) {
    const state: AdmissionFormStateModel = ctx.getState();
    return this.service.decline(state.config.currentId, comment, withSigns);
  }

  @Action(GetRatings)
  getRating(ctx: StateContext<AdmissionFormStateModel>): Observable<any[]> {
    return this.service.getRatings().pipe(tap((data: any[]) => {
      ctx.patchState({
        ratings: data
      });
    }));
  }

  @Action(SetStarted)
  setStarted(ctx: StateContext<AdmissionFormStateModel>, {dataSend}: SetStarted) {
    const state = ctx.getState();
    return this.service.setStarted(state.config.currentId, dataSend).pipe(
      catchError((err) => {
        if (err.status === 422) {
          {
            this.popup.createPopup({
              popupComponent: MessagePopupComponent,
              preventBgClick: true,
              popupData: {
                message: 'Please, add your signature in profile settings',
                closeRoute: null,
                yellowHeader: true
              }
            }).subscribe(() => {
              setTimeout(() => {
                this.popup.createPopup({
                  popupComponent: SignaturePopupComponent,
                  title: 'Leave your signature',
                  hideIcon: true
                }).pipe(
                  take(1),
                ).subscribe(({data}) => {
                  if (data) {
                    ctx.dispatch(new UpdateProfileUserSign(data));
                  }
                });
              });
            });
          }
        }
        return  throwError(err);
      })
    );
  }

  @Action(GetStatuses)
  getStatuses(ctx: StateContext<AdmissionFormStateModel>): Observable<any[]> {
    return this.service.getStatuses().pipe(tap((data: any[]) => {
      ctx.patchState({
        statuses: (<any>data.reduce((acc, curr) => ({...acc, [curr.title]: curr.id}), {}))
      });
    }));
  }

  @Action(SetPatientSigner)
  setPatientSigner(ctx: StateContext<AdmissionFormStateModel>, {relationship, reason}: SetPatientSigner) {
    const state: AdmissionFormStateModel = ctx.getState();
    return this.service.setPatientSigner(state.formValue.id, relationship, reason);
  }

  @Action(SetPatientSignerWithoutUser)
  setPatientSignerWithoutUser(ctx: StateContext<AdmissionFormStateModel>, {token, relationship, reason}: SetPatientSignerWithoutUser) {
    return this.service.setPatientSignerWithoutUser(token, relationship, reason);
  }

  @Action(SetSignatureVerificationAndConfirmationErrors)
  setSignatureVerificationAndConfirmationErrors(ctx: StateContext<AdmissionFormStateModel>, {errorKey}) {
    const state: AdmissionFormStateModel = ctx.getState();
    state.error[errorKey] = 'The relationship field is required when sign is present.';
    ctx.patchState({
      error: state.error
    });
  }

  @Action(ResetSignatureVerificationAndConfirmationErrors)
  resetSignatureVerificationAndConfirmationErrors(ctx: StateContext<AdmissionFormStateModel>) {
    ctx.patchState({
      error: {}
    });
  }

  @Action(SignDocument)
  signDocument(ctx: StateContext<AdmissionFormStateModel>, {signs, documentKey}: SignDocument) {
    const state: AdmissionFormStateModel = ctx.getState();
    let isDischarge = false;
    if (state.config.currentTypeOfApplication === AdmissionTypeEnum.DischargeSummary) {
      isDischarge = true;
    }
    return this.service.signDocument(state.config.currentId, documentKey, signs, isDischarge).pipe(
      tap((data: any) => {
        if (state.config.currentTypeOfApplication === AdmissionTypeEnum.DischargeSummary) {
          ctx.patchState({
            dischargeSummary: {
              ...data
            }
          });
        } else {
          ctx.patchState({
            formValue: {
              ...data,
              patient: {
                ...data.patient,
                stateId: data.patient.state.id
              }
            }
          });
        }
      }),
      catchError((err: any) => {
        ctx.patchState({
          error: err.error?.violations || {}
        });
        return throwError(err);
      })
    );
  }

  @Action(SignDocumentWithoutLogin)
  signDocumentWithoutLogin(ctx: StateContext<AdmissionFormStateModel>, {
    token,
    signs,
    documentKey,
  }: SignDocumentWithoutLogin) {
    return this.service.signDocumentWithoutLogin(token, documentKey, signs)
      .pipe(
        tap((data: any) => {
          ctx.patchState({
            formValue: {
              ...data,
              patient: {
                ...data.patient,
                stateId: data.patient.state.id
              }
            }
          });
        }),
        catchError((err: any) => {
          ctx.patchState({
            error: err.error.violations || {}
          });
          return throwError(err);
        })
      );
  }

  @Action(SetSigned)
  setSigned(ctx: StateContext<AdmissionFormStateModel>): Observable<any> {
    const state: AdmissionFormStateModel = ctx.getState();
    return this.service.setSigned(state.config.currentId);
  }

  @Action(SetSignedWithoutLogin)
  setSignedWithoutLogin(ctx: StateContext<AdmissionFormStateModel>, {token, isEmployee}: SetSignedWithoutLogin) {
    return this.service.setSignedWithoutLogin(token, isEmployee);
  }

  @Action(SaveScheduleDaysVisit)
  saveScheduleDaysVisit(ctx: StateContext<AdmissionFormStateModel>, {payload}: SaveScheduleDaysVisit) {
    return this.service.saveScheduleDaysVisit(payload);
  }

  @Action(CreateAdmissionFromAttachment)
  createAdmissionFromAttachment(ctx: StateContext<AdmissionFormStateModel>, {payload}: CreateAdmissionFromAttachment) {
    ctx.patchState({
      createAdmissionFromAttachmentPayload: payload
    });
    return this.service.createAdmissionFromAttachment(payload);
  }

  @Action(DocumentsListAdmission)
  documentsListAdmission(ctx: StateContext<AdmissionFormStateModel>, {id}: DocumentsListAdmission) {
    return this.service.getDocumentAdmissionTypes(id).pipe(tap((data: any) => {
      ctx.patchState({
        listAdmissionDocuments: data.listAdmissionDocuments
      });
    }));
  }

  @Action(GetAdmissionDepartments)
  getAdmissionDepartments(ctx: StateContext<AdmissionFormStateModel>): Observable<any[]> {
    return this.staticData.getDepartments().pipe(tap((data: any[]) => ctx.patchState({list: data})));
  }

  @Action(GetAdmissionPayers)
  getAAdmissionPayers(ctx: StateContext<AdmissionFormStateModel>): Observable<any[]> {
    return this.staticData.getPayers().pipe(tap((data: any[]) => ctx.patchState({payers: data})));
  }

  @Action(LoadAdmission)
  removeAdmissionId(ctx: StateContext<AdmissionFormStateModel>, {load}) {
    ctx.patchState({
      load: load
    });
  }

  @Action(SetFirstSignShowArrowAdmission)
  setFirstSignShowArrowAdmission(ctx: StateContext<AdmissionFormStateModel>) {
    ctx.patchState({
      updateFirstSignShowArrowAdmission: !ctx.getState().updateFirstSignShowArrowAdmission
    });
  }

  @Action(SetButtonOptions)
  setButtonOptions(ctx: StateContext<AdmissionFormStateModel>, {save}) {
    const state: AdmissionFormStateModel = ctx.getState();
    ctx.patchState({
      buttonsOptions: {
        ...state.buttonsOptions,
        ...save,
      }
    });
  }

  @Action(SetAction)
  setAction(ctx: StateContext<AdmissionFormStateModel>, {action}) {
    ctx.patchState({
      action: {
        ...action
      },
    });
  }

  @Action(ResetState)
  resetState(ctx: StateContext<AdmissionFormStateModel>) {
    ctx.patchState({
      action: {
        name: '',
        data: '',
      },
      buttonsOptions: {
        disableAutoSave: true,
        disableGenerateDocuments: true,
        disableCreateWithAttach: true,
        disableApprove: false,
        disabledSentToOffice: false,
      }
    });
  }

  @Action(ResetFormValueAndDischargeSummery)
  resetFormValueAndDischargeSummery(ctx: StateContext<AdmissionFormStateModel>) {
    ctx.patchState({
      formValue: {},
      dischargeSummary: {}
    });
  }
}
