import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PatientFilter } from '../store/patients/patients-list/patients.state';

@Injectable({
  providedIn: 'root'
})
export class PatientsService {
  url: string;

  constructor(
    private http: HttpClient,
    @Inject('API_URL') private apiUrl: string
  ) {
    this.url = `${this.apiUrl}/patients`;
  }

  getAll(params: any) {
    return this.http.get(this.url, {params, observe: 'response'}).pipe(map(res => ({
      data: res.body,
      total: res.headers.get('X-Items-Count')
    })));
  }

  getPatientsFilter(params: {search: string}) {
    return this.http.get<PatientFilter[]>(`${this.url}/filter`, {params});
  }

  getById(id: string) {
    return this.http.get(`${this.url}/${id}`);
  }

  getIncidents(id: string, params: any) {
    return this.http.get(`${this.url}/${id}/incidents`, {params: this.parseParams(params), observe: 'response'})
      .pipe(map(success => ({
        list: success.body,
        total: success.headers.get('X-Items-Count')
      })));
  }

  getAdmissions(id: string, params: any) {
    return this.http.get(`${this.url}/${id}/admissions`, {params: this.parseParams(params), observe: 'response'})
      .pipe(map(success => ({
        list: (<any[]>success.body).map(item => ({...item, signed: item.status.title === 'Signed'})),
        total: success.headers.get('X-Items-Count')
      })));
  }

  getAttachmets(id: string, params: any) {
    return this.http.get(`${this.url}/${id}/attachments`, {params: this.parseParams(params), observe: 'response'})
      .pipe(map(success => ({
          list: success.body,
          total: success.headers.get('X-Items-Count')
        })
      ));
  }

  postAttachment(body: any, id: string) {
    const formData = new FormData();
    Object.keys(body || {}).forEach(key => formData.append(key, body[key]));
    return this.http.post(`${this.url}/${id}/attachments`, formData);
  }

  updatePatientAttachment(id: string, attachmentId: string, body: any) {
    return this.http.put(`${this.url}/${id}/attachments/${attachmentId}`, body);
  }

  deletePacientAttachment(id: string, attachmentId: string) {
    return this.http.delete(`${this.url}/${id}/attachments/${attachmentId}`);
  }

  getPatientAttachmentLink(id: string, attachmentIds: string | string[]) {
    if (Array.isArray(attachmentIds)) {
      const params = {'ids[]': attachmentIds};
      return this.http.get(`${this.url}/${id}/attachments/multiple`, {params});
    } else {
      return this.http.get(`${this.url}/${id}/attachments/${attachmentIds}`);
    }
  }

  addPacientAttachmentComment(id: string, attachmentId: string, comment: string) {
    return this.http.post(`${this.url}/${id}/attachments/${attachmentId}/comment`, {comment});
  }

  syncAttachment(id, attachmentId) {
    return this.http.post(`${this.url}/${id}/attachments/${attachmentId}/hha-sync`, {});
  }

  setPatients(patient) {
    return this.http.post(`${this.url}`, patient);
  }

  private parseParams(params): any {
    let newParams = {...params};
    Object.keys(newParams || {}).forEach(key => {
      if (Array.isArray(newParams[key])) {
        newParams = {
          ...newParams,
          [key + '[]']: newParams[key]
        };
        delete newParams[key];
      }
      if (newParams[key] === null) {
        newParams[key] = '';
      }
    });
    return newParams;
  }

  getUserListToAssign(search: string, params: number) {
    return this.http.get<any>(`${this.apiUrl}/v2/patients/assign/users`, {params: <any>{search: search, forFilter: params }} );
  }

  setAssignUserToPatient(uuid: string[], assignIds:  string[]) {
    return this.http.post<any>(`${this.apiUrl}/v2/patients/assign`, { assignIds: assignIds, ids: uuid });
  }

  deleteAssignedUser(patientId: string, userId: string){
    return this.http.delete<any>(`${this.apiUrl}/v2/patients/${patientId}/assign/${userId}`);

  }
}
