import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Observer, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { Payload } from '../../popups/schedule-visit/schedule-visit.component';
import { PopupFactoryService } from '../../popups/popup-factory.service';
import { MessagePopupComponent } from '../../popups/message-popup/message-popup.component';
import { Router } from '@angular/router';
import { AdmissionTypes } from '../store/admissions/admissions.state';
import { StatusAdmission } from '../store/reports/reports.model';
import { getDataFromLocalStorage } from '../../shared/helpers/other';

@Injectable({
  providedIn: 'root'
})
export class AdmissionsService {
  url = `${this.apiUrl}/admissions`;

  constructor(
    private router: Router,
    private popup: PopupFactoryService,
    private http: HttpClient,
    @Inject('API_URL') private apiUrl: string
  ) {
  }

  getList(params: any): Observable<any> {
    return this.http.get(this.url, {params, observe: 'response'}).pipe(map((res: HttpResponse<any>) => {
      return {
        data: res.body,
        total: +res.headers.get('X-Items-Count')
      };
    }));
  }

  getById(id: string | number, discharge: boolean = false): Observable<any> {
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const user = getDataFromLocalStorage('user');
    const token = (user || {}).token || urlParams.get('jwt');
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return discharge ? this.http.get(`${this.url}/${id}/discharge`, {headers}) : this.http.get(`${this.url}/${id}`, {headers});
  }

  getAdmissionInfoByToken(token: string): Observable<any> {
    return this.http.get(`${this.url}/patient/admission/${token}`).pipe(
      catchError((err: any) => {
        if (err) {
          this.popup.createPopup({
            popupComponent: MessagePopupComponent,
            preventBgClick: false,
            popupData: {
              message: 'Link is not valid as Admission has been already signed',
              closeRoute: null,
              yellowHeader: true
            }
          }).pipe(take(1)).subscribe(() => {
            this.router.navigate(['/']);
          });
        }
        return throwError(err);
      })
    );
  }

  autoSave(id, payload, discharge: boolean = false): Observable<any> {
    return discharge ? this.http.put(`${this.url}/${id}/discharge`, payload) : this.http.put(`${this.url}/${id}`, payload);
  }

  getRatings(): Observable<any> {
    return this.http.get(`${this.url}/rating`);
  }

  getStatuses(): Observable<StatusAdmission[]> {
    return this.http.get<StatusAdmission[]>(`${this.url}/status`);
  }

  getTypes(): Observable<AdmissionTypes[]> {
    return this.http.get<AdmissionTypes[]>(`${this.url}/types`);
  }

  getRelationships(): Observable<any> {
    return this.http.get(`${this.url}/relationships`);
  }

  getReligions(): Observable<any> {
    return this.http.get(`${this.url}/religion`);
  }

  getAdmissionDetails(id, payload?): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.http.get(`${this.url}/${id}/details`, {params: payload, observe: 'response'})
        .pipe(take(1))
        .subscribe({
          next: (success: HttpResponse<any[]>) => {
            observer.next({
              listAdmissionPatient: success.body,
              totalAdmissionPatient: +success.headers.get('X-Items-Count')
            });
            observer.complete();
          },
          error: (err: any) => {
            observer.error(err);
          }
        });
    });
  }

  setStarted(id, dataSend): Observable<any> {
    return this.http.post(`${this.url}/${id}/status/started`, {...dataSend});
  }

  setStartDate(id, startDate): Observable<any> {
    return this.http.post(`${this.url}/${id}/start-date`, {startDate});
  }

  setSupervisor(id, supervisorId): Observable<any> {
    return this.http.post(`${this.url}/${id}/supervisor/${supervisorId}`, {});
  }

  setPatientSigner(id, relationship: number, reason: string): Observable<any> {
    return this.http.post(`${this.url}/${id}/patient-signer`, {relationship, reason});
  }

  setPatientSignerWithoutUser(token, relationship: number, reason: string): Observable<any> {
    return this.http.post(`${this.url}/patient/admission/${token}/patient-signer`, {relationship, reason});
  }

  signDocument(id, documentKey, signs, discharge = false): Observable<any> {
    delete signs.supervisorSignerName;
    return discharge
      ? this.http.post(`${this.url}/${id}/sign-document/discharge`, {documentKey, signs})
      : this.http.post(`${this.url}/${id}/sign-document`, {documentKey, signs});
  }

  signDocumentWithoutLogin(token, documentKey, signs): Observable<any> {
    delete signs.supervisorSignerName;
    return this.http.post(`${this.url}/patient/admission/${token}/sign-document`, {documentKey, signs});
  }

  changeAdmissionSignDate(id, date): Observable<any> {
    return this.http.post(`${this.url}/${id}/sign-at`, {id, signAt: date});
  }

  setSigned(id): Observable<any> {
    return this.http.post(`${this.url}/${id}/signed`, {id});
  }

  setSignedWithoutLogin(token, isEmployee: boolean = false): Observable<any> {
    return this.http.post(`${this.url}/patient/admission/${token}/signed${isEmployee ? '/employee' : ''}`, {});
  }

  download(id, type: 'pdf' | 'zip'): Observable<any> {
    return this.http.get(`${this.url}/${id}/download/${type}`);
  }

  setAdmissionsNotesById(id: string, text: string, reason: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/admissions/${id}/notes`, {text, reason: reason ? +reason : null});
  }

  getAdmissionsNotesById(id: string, filters?: any): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.http.get(`${this.apiUrl}/admissions/${id}/notes`, {params: filters, observe: 'response'})
        .pipe(take(1))
        .subscribe({
          next: (success: HttpResponse<any[]>) => {
            observer.next({
              data: success.body,
              totalNotes: +success.headers.get('X-Items-Count')
            });
            observer.complete();
          },
          error: (err: any) => {
            observer.error(err);
          }
        });
    });
  }

  updateAdmissionsNoteById(id: string, uuid: string, text: string, reason: number): Observable<any> {
    return this.http.put(`${this.apiUrl}/admissions/${id}/notes/${uuid}`, {text, reason: +reason || null});
  }

  deleteAdmissionsNotesById(id: string, uuid: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/admissions/${id}/notes/${uuid}`);
  }

  sync(): Observable<any> {
    return this.http.get(`${this.url}/sync`);
  }

  getSyncStatus(): Observable<any> {
    return this.http.get(`${this.url}/sync/status`);
  }

  saveScheduleDaysVisit(payload: Payload): Observable<any> {
    const data = payload.isEmpty ? null : {
      ...payload
    };
    return this.http.post(`${this.url}/${payload.id}/schedule`, data);
  }

  createAdmissionFromAttachment(payload: Payload): Observable<any> {
    const data = payload.isEmpty ? null : {
      ...payload
    };
    return this.http.post(`${this.url}/${payload.id}/from-attachment`, data);
  }

  generateAdmission(id, type): Observable<any> {
    const data = {
      id, type
    };
    return this.http.post(`${this.url}/${id}/generate/${type}`, data);
  }

  deleteAdmission(id): Observable<any> {
    return this.http.delete<any>(`${this.url}/${id}`);
  }

  getDocumentAdmissionTypes(id): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.http.get(`${this.url}/${id}/document-list`).pipe(take(1)).subscribe({
        next: (data: any) => {
          observer.next({
            listAdmissionDocuments: data
          });
          observer.complete();
        },
        error: (err: any) => {
          observer.error(err);
        }
      });
    });
  }

  approve(id): Observable<any> {
    return this.http.post(`${this.url}/${id}/approved`, id);
  }

  decline(id, comment, withSigns): Observable<any> {
    const data = {id, comment, withSigns};
    return this.http.post(`${this.url}/${id}/decline`, data);
  }

  updateCarePlan(id, patientUUId, payerId, updateTimesheet): Observable<any> {
    const data = {id: patientUUId, payerId, updateTimesheet};
    return this.http.post(`${this.url}/${id}/payer`, data);
  }

  changeAdmissionOffice(id: string, departmentId: number): Observable<any> {
    return this.http.post(`${this.url}/${id}/department`, {departmentId: departmentId});
  }

  setAssignedUser(uuid: string[], assignIds:  string[]): Observable<any> {
    return this.http.post(`${this.apiUrl}/v2/admissions/assign`, { assignIds: assignIds, ids: uuid });
  }

  getUserList(search: string, params: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/v2/admissions/assign/users`, {params: {search: search, forFilter: params}});
  }

  deleteAssignedUser(admissionId: string, userId: string): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/v2/admissions/${admissionId}/assign/${userId}`);
  }
}
