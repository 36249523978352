import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[appBindRadio]'
})
export class BindRadioDirective {

  @Input('appBindRadio') control: FormControl;
  @Input() targetValue: any;

  @HostBinding('checked') get checked() {
    return this.control.value === this.targetValue;
  }

  @HostListener('click') onClick() {
    this.control.markAsDirty();
    return this.control.patchValue(this.targetValue);
  }


  constructor() { }

}
