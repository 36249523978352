import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { FilterPlanDetails } from '../store/educations/education-plan-details/education-plan-details.interface';
import { DataSendNotifyPlan, FilterPlan, NewPlan } from '../store/educations/education-plan/education-plan.interface';
import { deEscapeHtml } from '../../shared/helpers/deEscapeHtml';

@Injectable({
  providedIn: 'root'
})
export class EducationsService {

  url = `${this.apiUrl}/educations/lessons`;

  constructor(
    private http: HttpClient,
    @Inject('API_URL') private apiUrl: string
  ) {
  }

  getLessonCategoriesForFilter() {
    return this.http.get(`${this.url}/categories/filter`);
  }

  getUserListForFilter(params) {
    return this.http.get(`${this.url}/users/filter`, {
      params: {
        ...params,
        search: (deEscapeHtml(params.search || '') as string).trim(),
      }
    });
  }

  getLessonCategories(filters: any) {
    let params = {};
    if (filters) {
      params = {
        ...filters
      };
    }
    return this.http.get(`${this.url}/categories`, {params, observe: 'response'}).pipe(map(res => {
      return {
        listOfCategories: res.body,
        totalCategories: +res.headers.get('X-Items-Count')
      };
    }));
  }

  getLessons(filters: any) {
    let params = {perPage: 20, page: filters.page};
    if (filters) {
      params = {
        ...params,
        ...filters,
      };
      if (filters.categories) {
        params = {
          ...params,
        };
        params['categories[]'] = filters.categories;
        delete params['categories'];
      }
    }
    return this.http.get(this.url, {params, observe: 'response'}).pipe(map(res => {
      return {
        listLessons: res.body,
        totalLessons: +res.headers.get('X-Items-Count')
      };
    }));
  }

  createLessons(payload: any) {
    const obj = {
      ...payload,
      category: payload?.category?.id
    };
    if (payload.video) {
      if (!payload.url.length) {
        obj.url = null;
      }
    } else {
      delete obj['video'];
      return this.http.post(this.url, obj);
    }
  }

  createNewCategory(title: string) {
    return this.http.post(`${this.url}/categories`, {title});
  }

  updateCategory(data: any) {
    return this.http.put(`${this.url}/categories/${data.id}`, {title: data.title});
  }

  getLessonsById(id: string | number) {
    return this.http.get(`${this.url}/${id}`);
  }

  updateLesson(id: string | number, payload: any) {
    return this.http.put(`${this.url}/${id}`, payload);
  }

  deleteLesson(id: string | number) {
    return this.http.delete(`${this.url}/${id}`);
  }

  deleteCategory(id: string) {
    return this.http.delete(`${this.url}/categories/${id}`);
  }

  confirmSeen(id: string) {
    return this.http.post(`${this.url}/${id}/confirm`, {});
  }

  getPlanList(filters: FilterPlan) {
    return this.http.get(`${this.url}/plans`, {params: filters as any, observe: 'response'}).pipe(map(res => {
      return {
        planList: res.body,
        totalPlans: +res.headers.get('X-Items-Count')
      };
    }));
  }

  createNewPlan(formCreate: NewPlan) {
    return this.http.post(`${this.url}/plans`, formCreate);
  }

  updatePlan(formCreate: NewPlan, id: string) {
    return this.http.put(`${this.url}/plans/${id}`, formCreate);
  }

  deletePlan(planId: string) {
    return this.http.delete(`${this.url}/plans/${planId}`);
  }

  getPlanById(id: string) {
    return this.http.get(`${this.url}/plans/${id}`);
  }

  getPlanDetailsList(filters: FilterPlanDetails, id: string) {
    return this.http.get(`${this.url}/plans/${id}/details`, {params: filters as any, observe: 'response'}).pipe(map(res => {
      return {
        planDetailsList: res.body,
        totalPlansDetails: +res.headers.get('X-Items-Count')
      };
    }));
  }

  sendNotify(dataSend: DataSendNotifyPlan, id: string) {
    return this.http.post(`${this.url}/plans/${id}/notify`, dataSend);
  }

  sendNotifyPlanDetail(idPlan: string, idPlanDetail: string, message: string) {
    return this.http.post(`${this.url}/plans/${idPlan}/notify/${idPlanDetail}`, {message});
  }
}
