import { Directive, ElementRef, HostBinding, HostListener, Renderer2, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appMarkInvalid]'
})
export class MarkInvalidDirective {

  hasError = false;
  errors = {
    required: 'Field is required',
    email: 'Email is invalid',
    emailTaken: 'Email is already taken',
    ssnTaken: 'SSN is already taken',
    incorrectEmail: 'The email must be a valid email address'
  };

  constructor(
    @Self() private ngControl: NgControl,
    private renderer: Renderer2,
    private elRef: ElementRef
  ) {
  }

  @HostBinding('style.border-color')
  get borderColor() {
    if (this.ngControl.control) {
      if (this.ngControl.control.invalid && this.ngControl.control.touched) {
        this.appendErrorSpan();
        return '#dc7070';
      } else {
        this.removeErrorSpan();
        return '';
      }
    } else {
      return '';
    }
  }

  @HostListener('focus')
  onBlur() {
    if (this.ngControl.control) {
      this.ngControl.control.markAsTouched();
    }
  }

  private appendErrorSpan() {
    const error = Object.keys(this.ngControl.control.errors || {}).slice(-1)[0];
    const parent = this.renderer.parentNode(this.elRef.nativeElement);
    if (parent) {
      this.renderer.setStyle(parent, 'position', 'relative');
    }

    if (this.hasError) {
      const oldChild = parent.querySelector('span.err-msg');
      this.renderer.removeChild(parent, oldChild);
    }
    const errorSpan = this.renderer.createElement('span');
    this.renderer.addClass(errorSpan, 'err-msg');
    this.renderer.addClass(this.elRef.nativeElement, 'err');
    this.renderer.setProperty(errorSpan, 'innerText', this.errors[error] || this.ngControl.control.errors.message ||
      this.getError() || 'Field is invalid');
    this.renderer.appendChild(parent, errorSpan);
    this.hasError = true;
  }

  private getError() {
    if (typeof this.ngControl.control.errors === 'string') {
      return this.ngControl.control.errors;
    }
  }

  private removeErrorSpan() {
    this.renderer.removeClass(this.elRef.nativeElement, 'err');
    const parent = this.renderer.parentNode(this.elRef.nativeElement);
    const oldChild = parent.querySelector('span.err-msg');
    if (oldChild) {
      if (oldChild.parentNode === parent) {
        this.renderer.removeChild(parent, oldChild);
      }
      this.hasError = false;
    }
  }
}
