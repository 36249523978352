import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import * as textMask from 'text-mask-core/dist/textMaskCore.js';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appMask]',
  standalone: true,

  host: {
    '(input)': 'handleInput()'
  }
})
export class MaskDirective implements AfterViewInit, OnDestroy {
  @Input() mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]; // dd/mm/yyyy
  @Input() initConform = false;

  maskedInputController;

  @HostListener('input')
  handleInput() {
    this.maskedInputController.update();
  }

  constructor(
    private element: ElementRef,
    private control: NgControl
  ) {
  }

  ngAfterViewInit() {
    this.maskedInputController = textMask.createTextMaskInputElement({
      inputElement: this.element.nativeElement,
      mask: this.mask
    });
    if (this.control && this.initConform && this.control.value) {
      this.control.control.patchValue(textMask.conformToMask(this.element.nativeElement.value, this.mask, {}).conformedValue);
    }
    this.maskedInputController.update();
    this.element.nativeElement.addEventListener('change', () => this.maskedInputController.update(), {passive: false});
  }

  ngOnDestroy() {
    // this.maskedInputController.destroy();
  }
}
