import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppState } from '../../store/app.state';
import { map } from 'rxjs/operators';
import { RoleEnum } from '../models';

@Injectable({
  providedIn: 'root'
})
export class PermissionAccessService {
  @Select(AppState.currentUser) user$: Observable<any>;

  hasPermission(permission: string): Observable<boolean> {
    return this.user$
      .pipe(map((user) => {
          if (user && user?.roles && (user?.roles[0]?.title === RoleEnum.SuperAdmin || user?.roles[0]?.title === RoleEnum.Employee)) {
            return true;
          }
          return user.permissions?.includes(permission);
        }
      ));
  }

  hasPermissionGroup(permission: string[]): Observable<boolean> {
    return this.user$
      .pipe(map((user): boolean => {
          if (user && user?.roles && (user?.roles[0]?.title === RoleEnum.SuperAdmin || user?.roles[0]?.title === RoleEnum.Employee)) {
            return true;
          }
          let access = false;
          permission.forEach((per: string) => {
            if (user.permissions?.includes(per)) {
              access = true;
            }
          });
          return access;
        }
      ));
  }

  hasAllPermissions(permissions: string[]): Observable<boolean> {
    return this.user$.pipe(
      map((user): boolean => {
        if (user && user?.roles && (user?.roles[0]?.title === RoleEnum.SuperAdmin || user?.roles[0]?.title === RoleEnum.Employee)) {
          return true;
        }
        return permissions.every((el: string) => user.permissions?.includes(el));
      })
    );
  }

  checkPartInclude(permission): Observable<boolean> {
    return this.user$.pipe(
      map((user) => {
        if (user && user?.roles && (user?.roles[0]?.title === RoleEnum.SuperAdmin || user?.roles[0]?.title === RoleEnum.Employee)) {
          return true;
        }
        return user.permissions?.some((per) => per?.includes(permission));
      })
    );
  }
}
