import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { LoginByMicrosoftRedirectUrlComponent } from './auth/login-by-microsoft-redirect-url/login-by-microsoft-redirect-url.component';

const routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'login/sso',
    component: LoginByMicrosoftRedirectUrlComponent
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'employee',
    loadChildren: () => import('./dashboard/document/document.module').then(m => m.DocumentModule),
  },
  {
    path: 'education',
    loadChildren: () => import('./dashboard/education/education.module').then(m => m.EducationModule),
  },
  {
    path: 'admissions',
    loadChildren: () => import('./dashboard/admission-document/admission-document.module').then(m => m.AdmissionDocumentModule)
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy'})
  ],
  declarations: [],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
