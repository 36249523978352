import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import {
  Login,
  Logout,
  ResetAuthErrors,
  SetCurrentUser,
  UpdateUserName,
  UpdateUserStatus,
  ValidateUser,
  GetPerPages,
  GetRules,
  GetLoginUseMicrosoftUrl,
  LoginUseMicrosoftUrl,
} from './app.actions';
import { AuthService } from '../auth/auth.service';
import { catchError, tap, switchMap, take } from 'rxjs/operators';
import { EMPTY, Observable, of, Subscription } from 'rxjs';
import { SetUserSign } from '../dashboard/store/user-form/user-form.actions';
import { ResetAdminPanel } from '../dashboard/store/employees/employees.actions';
import { StaticDataService } from '../static-data.service';
import { rules as mockRules } from '../../environments/draft-rules';
import { environment } from '../../environments/environment';
import { UserModel } from '../shared/models';
import { PopupFactoryService } from '../popups/popup-factory.service';
import { getDataFromLocalStorage } from '../shared/helpers/other';

@State({
  name: 'app',
  defaults: {
    currentUser: null,
    perPages: null,
    meta: {
      authForm: {
        error: ''
      }
    },
    oldToken: '',
    newToken: '',
    rules: {},
    microsoftRedirectUrl: null
  }
})

@Injectable()
export class AppState {
  @Selector()
  static user(state) {
    return {
      ...state.currentUser.account,
      email: state.currentUser.email,
      roles: state.currentUser.roles
    };
  }

  @Selector()
  static currentUser(state) {
    return {
      ...state.currentUser
    };
  }

  @Selector()
  static role(state) {
    return (state.currentUser || {})?.roles[0]?.title || null;
  }

  @Selector()
  static perPages(state) {
    return {
      ...state.perPages
    };
  }

  @Selector()
  static rules(state) {
    return state.rules;
  }

  @Selector()
  static microsoftRedirectUrl(state) {
    return state.microsoftRedirectUrl;
  }

  @Selector()
  static statusFilterName(state) {
    return state.rules.patient.statusFilterName;
  }

  static getModuleRules(moduleName: string) {
    return createSelector([AppState.rules], (rules) => rules[moduleName] ?? {});
  }

  constructor(
    private auth: AuthService,
    private staticService: StaticDataService,
    private router: Router,
    private popup: PopupFactoryService
  ) {
  }

  @Action(Login)
  login(ctx: StateContext<any>, {credentials}: Login) {
    const state = ctx.getState();
    return this.auth.signIn(credentials)
      .pipe(
        switchMap(({token}: any) => {
          if (token) {
            this.auth.getPagination(token).subscribe((perPages: { key: string; perPage: number }[]) => {
              ctx.patchState({
                ...ctx.getState(),
                perPages: perPages
              });
            });
            ctx.patchState({
              newToken: token
            });
            return this.auth.getUser(token);
          }
        }),
        catchError(() => {
          ctx.patchState({
            meta: {
              ...state.meta,
              authForm: {
                errors: 'Error'
              }
            }
          });
          return of(null);
        })
      ).subscribe({
        next: (user: UserModel) => {
          ctx.patchState({
            oldToken: user?.token
          });
          ctx.dispatch(new SetCurrentUser(user)).pipe(take(1)).subscribe(() => {
            this.router.navigate(['/dashboard']);
          });
        },
        error: () => {
          ctx.patchState({
            meta: {
              ...state.meta,
              authForm: {
                errors: 'Error'
              }
            }
          });
        }
      });
  }

  @Action(GetLoginUseMicrosoftUrl)
  getLoginUseMicrosoft(ctx: StateContext<any>): Subscription {
    return this.auth.getLoginUseMicrosoftUrl().pipe(take(1)).subscribe({
      next: (res: { redirectUrl: string }) => {
        if (res?.redirectUrl) {
          ctx.patchState({
            microsoftRedirectUrl: res.redirectUrl
          });
        }
      }
    });
  }

  @Action(LoginUseMicrosoftUrl)
  loginUseMicrosoftUrl(ctx: StateContext<any>, {token}: LoginUseMicrosoftUrl) {
    if (token) {
      const state = ctx.getState();
      this.auth.getPagination(token).subscribe((perPages: { key: string; perPage: number }[]) => {
        ctx.patchState({
          ...ctx.getState(),
          perPages: perPages
        });
      });
      ctx.patchState({
        newToken: token
      });
      this.auth.getUser(token).pipe(take(1)).subscribe({
        next: (user: UserModel) => {
          ctx.patchState({
            oldToken: user?.token
          });
          ctx.dispatch(new SetCurrentUser(user)).pipe(take(1)).subscribe(() => {
            this.router.navigate(['/dashboard']);
          });
        },
        error: () => {
          ctx.patchState({
            meta: {
              ...state.meta,
              authForm: {
                errors: 'Error'
              }
            }
          });
        }
      });
    }
  }

  @Action(SetUserSign)
  setUserSign(ctx: StateContext<any>, {signUrl}: SetUserSign) {
    const state = ctx.getState();
    ctx.patchState({
      currentUser: {
        ...state.currentUser,
        account: {
          ...state.currentUser.account,
          signUrl
        }
      }
    });
  }

  @Action(SetCurrentUser)
  setCurrentUser(ctx: StateContext<any>, {user}: SetCurrentUser) {
    const state = ctx.getState();
    if (user) {
      const currentUser = {
        ...user,
        token: user?.token ? user.token : state.currentUser?.token,
        account: {
          ...user.account,
          name: user.account.name || (user.account.firstName || '') + ' ' + (user.account.lastName || ''),
        }
      };
      localStorage.setItem('user', JSON.stringify(user));
      return ctx.patchState({
        currentUser
      });
    }
  }

  @Action(UpdateUserStatus)
  updateUserStatus(ctx: StateContext<any>, {status}: UpdateUserStatus) {
    const state = ctx.getState();
    ctx.patchState({
      currentUser: {
        ...state.currentUser,
        documentHistory: {
          ...(state.currentUser.documentHistory || {}),
          status
        }
      }
    });
  }

  @Action(Logout)
  logout(ctx: StateContext<any>) {
    localStorage.clear();
    ctx.patchState({
      currentUser: null
    });
    ctx.dispatch(new ResetAdminPanel());
    this.router.navigate(['/login']).then((): void => {
      this.popup.closePopup();
    });
  }

  @Action(UpdateUserName)
  updateUserName(ctx: StateContext<any>, {firstName, lastName}: UpdateUserName) {
    const state = ctx.getState();
    ctx.patchState({
      currentUser: {
        ...state.currentUser,
        account: {
          ...state.currentUser.account,
          name: `${firstName} ${lastName}`
        }
      }
    });
  }

  @Action(ValidateUser)
  validateUser(ctx: StateContext<any>): Promise<any> {
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const user: UserModel = getDataFromLocalStorage('user');
    const token: string = (user || {}).token || urlParams.get('jwt');
    if (token) {
      return this.auth.getUser(token).pipe(
        tap((userData: any) => {
          ctx.patchState({
            ...ctx.getState(),
            currentUser: {
              ...userData,
              account: {
                ...userData.account,
                name: userData.account.name || userData.account.firstName + ' ' + userData.account.lastName,
              },
              token
            }
          });
        }),
        catchError(() => {
          localStorage.removeItem('user');
          return of(null);
        })
      ).toPromise();
    } else {
      return Promise.resolve();
    }
  }

  @Action(GetPerPages)
  getPerPages(ctx: StateContext<any>): Subscription {
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const user: UserModel = getDataFromLocalStorage('user');
    const token: string = (user || {}).token || urlParams.get('jwt');
    if (token) {
      return this.auth.getPagination(token).subscribe((perPages: { key: string; perPage: number }[]) => {
        ctx.patchState({
          ...ctx.getState(),
          perPages: perPages
        });
      });
    }
  }

  @Action(ResetAuthErrors)
  resetAuthErrors(ctx: StateContext<any>) {
    const state = ctx.getState();
    ctx.patchState({
      meta: {
        ...state.meta,
        authForm: {
          ...state.meta.authForm,
          errors: ''
        }
      }
    });
  }

  @Action(GetRules)
  getRules(ctx: StateContext<any>): Observable<any> {
    return this.staticService.getRules()
      .pipe(
        tap((rules: any) => ctx.patchState({rules: environment.mockRules ? mockRules : rules})),
        catchError(() => EMPTY)
      );
  }
}
