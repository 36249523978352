import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { UserModel } from '../../../shared/models';
import { EmployeeFilter, EmployeesService, GenerateEmployeeResponse } from '../../services/employees.service';
import {
  SetAssignUserToEmployee,
  ChangeDates,
  ClearGeneratedEmployee,
  DeleteAttachment,
  DownloadAttachment,
  DownloadDocument,
  DownloadMultipleAttachments,
  GenerateEmployee,
  GetAllEmployees,
  GetAttachments,
  GetSalesListForFilter,
  GetUserListToAssignEmployee,
  HhaExchangeSync,
  OpenGenerateEmployee,
  ResetAdminPanel,
  ResetFilters,
  SendEmail,
  SendMessage,
  SetNoteEmployee,
  SetPage,
  UpdateAttachment,
  UploadDocument,
  DeleteUserToAssignEmployee,
  GetUserListToAssignFilterEmployee
} from './employees.actions';
import { tap } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { MessagePopupComponent } from '../../../popups/message-popup/message-popup.component';
import { HistoryStateModel } from '../document-history/history.state';
import { PopupFactoryService } from '../../../popups/popup-factory.service';
import { forkJoin, Observable } from 'rxjs';
import { ChooseDepartmentComponent } from '../../../popups/choose-department/choose-department.component';
import { AttachmentsService } from '../../services/attachments.service';
import { isImageExt } from '../../../shared/helpers/is-image';
import { Injectable } from '@angular/core';
import { openLink } from '../../../shared/helpers/open-link';

export interface EmployeesStateModel {
  list: any[];
  attachmentsList: any[];
  page: number;
  editFormValue: any;
  total: number;
  totalAtt: number;
  salesList: any[];
  usersList: any[];
  usersListFilter: any[];
  salesListForFilter: any[];
  filters: {
    model: EmployeeFilter;
    status: string;
    dirty: boolean;
  };
  meta: {
    isLoading: boolean;
  };
  random: {
    uid: string;
    password: string;
    autoLoginLink: string;
    loginPageLink: string;
  };
}

export const defaultState: EmployeesStateModel = {
  list: [],
  attachmentsList: [],
  total: 0,
  totalAtt: 0,
  page: 1,
  editFormValue: {},
  salesList: [],
  salesListForFilter: [],
  usersList: [],
  usersListFilter: [],
  filters: {
    model: {
      perPage: 10,
      dateFrom: '',
      dateTo: '',
      statuses: [],
      caregiverStatuses: [],
      assigned: [],
      sales: [],
      search: '',
      skillCategories: []
    },
    dirty: false,
    status: ''
  },
  random: {
    uid: '',
    password: '',
    autoLoginLink: '',
    loginPageLink: '',
  },
  meta: {
    isLoading: true
  }
};

@State({
  name: 'employees',
  defaults: defaultState
})

@Injectable()
export class EmployeesState {

  @Selector()
  static employeeList(state) {
    return state.list;
  }

  @Selector()
  static isLoading(state) {
    return state.meta.isLoading;
  }

  @Selector()
  static salesList(state) {
    return state.salesList;
  }

  @Selector()
  static salesListForFilter(state) {
    return state.salesListForFilter;
  }

  @Selector()
  static usersToAssign(state) {
    return state.usersList;
  }

  @Selector()
  static usersToAssignFilter(state) {
    return state.usersListFilter;
  }

  @Selector()
  static random(state) {
    return state?.random;
  }

  @Selector()
  static page(state) {
    return state.page;
  }

  @Selector()
  static total(state) {
    return state.total;
  }

  @Selector()
  static totalAtt(state) {
    return state.totalAtt;
  }

  @Selector()
  static attachments(state) {
    return state.attachmentsList.map(item => ({
      ...item,
      url: isImageExt(item.file.ext) ? item.file.url : '/assets/img/no_preview.png',
      isImage: isImageExt(item.file.ext)
    }));
  }

  constructor(
    private api: EmployeesService,
    private userService: UserService,
    private popup: PopupFactoryService,
    private attachments: AttachmentsService,
    private store: Store
  ) {
  }

  @Action(GetAllEmployees)
  getAllEmployees(ctx: StateContext<EmployeesStateModel>, {filters, sort}) {
    const state: EmployeesStateModel = ctx.getState();
    let obj = {
      ...state.filters.model,
      ...filters,
      page: state.page
    };
    if (sort) {
      obj = {
        ...obj,
        ...sort
      };
    }
    return this.api.getAll({...obj}).pipe(tap(({data, total}) => {
      ctx.patchState({
        list: data,
        total,
        meta: {
          isLoading: false
        }
      });
    }));
  }

  @Action(SetPage)
  setPage(ctx: StateContext<EmployeesStateModel>, {page}: SetPage) {
    ctx.patchState({
      page
    });
  }

  @Action(GetSalesListForFilter)
  GetSalesListForFilter(ctx: StateContext<EmployeesStateModel>, {search}) {
    return this.userService.getAllForFilter(<any>{rows: 1000, search})
      .pipe(tap(({users}) =>
        ctx.patchState({
          salesListForFilter: users.map(user => {
            return {
              id: user.id + '/sales',
              uid: user.uid,
              name: user.name.trim(),
            };
          })
        })
      ));
  }

  @Action(OpenGenerateEmployee)
  openGenerateEmployee(ctx: StateContext<EmployeesStateModel>): Observable<any> {
    return this.popup.createPopup({
      popupComponent: ChooseDepartmentComponent,
    }).pipe(tap(() => ctx.dispatch(new ClearGeneratedEmployee())));
  }

  @Action(GenerateEmployee)
  generateEmployee(ctx: StateContext<EmployeesStateModel>, {employeeData}: GenerateEmployee) {
    return this.api.generateEmployee(employeeData).pipe(tap((res: GenerateEmployeeResponse) => {
      ctx.patchState({
        random: {
          uid: res.id,
          password: res.password,
          autoLoginLink: res.autoLoginLink,
          loginPageLink: res.loginPageLink
        }
      });
    }));
  }

  @Action(ClearGeneratedEmployee)
  clearGeneratedEmployee(ctx: StateContext<EmployeesStateModel>) {
    ctx.patchState({
      random: defaultState?.random
    });
  }

  @Action(SendEmail)
  sendEmail(ctx: StateContext<EmployeesStateModel>, {email}: SendEmail) {
    const state: EmployeesStateModel = ctx.getState();
    return this.api.sendEmail(email, state?.random);
  }

  @Action(SendMessage)
  sendMessage(ctx: StateContext<EmployeesStateModel>, {phone}: SendMessage) {
    const state: EmployeesStateModel = ctx.getState();
    return this.api.sendMessage(phone, state?.random);
  }

  @Action(DownloadDocument)
  downloadDocument(ctx: StateContext<EmployeesStateModel>, {uid, historyId, format}: DownloadDocument) {
    const currentUser: UserModel = this.store.selectSnapshot(({app}) => app.currentUser);
    return this.api.downloadDocument(uid, currentUser?.roles[0]?.id, format, historyId).pipe(tap(({link}: any) => {
      if (link) {
        openLink(link);
      }
    }));
  }

  @Action(ResetAdminPanel)
  resetList(ctx: StateContext<EmployeesStateModel>) {
    ctx.setState(defaultState);
  }

  @Action(HhaExchangeSync)
  hhaExchangeSync(ctx: StateContext<HistoryStateModel>, {userIds}: HhaExchangeSync) {
    return forkJoin(userIds.map((id: string) => this.api.hhaExchangeSync(id))).pipe(tap(() => {
      this.popup.createPopup({
        popupComponent: MessagePopupComponent,
        preventBgClick: true,
        popupData: {message: 'Successfully sent to HHAExchange', closeRoute: null}
      }).subscribe();
      ctx.dispatch(new GetAllEmployees());
    }));
  }

  @Action(ChangeDates)
  changeDates(ctx: StateContext<EmployeesStateModel>, {uuid, newDate, typeDate}: ChangeDates) {
    return this.api.changeDocumentDate(uuid, newDate, typeDate);
  }

  @Action(GetAttachments)
  getAttachmentsList(ctx: StateContext<EmployeesStateModel>, {filters, sort}: GetAttachments) {
    if (filters.hhaExchange !== 0 && filters.hhaExchange !== 1) {
      delete filters.hhaExchange;
    }
    let obj = {
      ...filters
    };
    if (sort) {
      obj = {
        ...obj,
        ...sort
      };
    }
    return this.attachments.getAttachments(obj).pipe(
      tap((attObj: any) => {
        ctx.patchState({
          attachmentsList: attObj.attachmentsList,
          totalAtt: attObj.totalAtt
        });
      }));
  }

  @Action(DeleteAttachment)
  deleteAttachments(ctx: StateContext<EmployeesStateModel>, {attachmentId}: DeleteAttachment) {
    return this.attachments.deleteAttachment(attachmentId);
  }

  @Action(DownloadAttachment)
  downloadAttachment(ctx: StateContext<EmployeesStateModel>, {attachmentIds}: DownloadAttachment) {
    return this.attachments.getAttachmentLink(attachmentIds)
      .pipe(tap((res: any) => {
        if (res?.link || res?.file.url) {
          openLink(res.link || res.file.url);
        }
      }));
  }

  @Action(DownloadMultipleAttachments)
  downloadMultipleAttachments(ctx: StateContext<EmployeesStateModel>, {ids}: DownloadMultipleAttachments) {
    return this.attachments.getAttachmentLink(ids)
      .pipe(tap(({link}: any) => {
        if (link) {
          openLink(link);
        }
      }));
  }

  @Action(UploadDocument)
  uploadDocument(ctx: StateContext<EmployeesStateModel>, {title, file}: UploadDocument) {
    return this.attachments.postAttachment({title, file});
  }

  @Action(UpdateAttachment)
  updateAttachment(ctx: StateContext<EmployeesStateModel>, {attachmentId, title}: UpdateAttachment) {
    return this.attachments.updateAttachment(attachmentId, {title});
  }

  @Action(SetNoteEmployee)
  setNote(ctx: StateContext<EmployeesStateModel>, {uid, text}: SetNoteEmployee) {
    const state: EmployeesStateModel = ctx.getState();
    const list: any[] = [...state.list];
    const employee = list.find(e => e.uid === uid);

    if (employee.notes) {
      employee.notes.text = text;
    } else {
      employee.notes = {
        uid,
        text
      };
    }
    ctx.patchState({list});
  }

  @Action(ResetFilters)
  resetFilters(ctx: StateContext<EmployeesStateModel>): EmployeesStateModel {
    return ctx.patchState({
      filters: {
        model: {
          perPage: this.store.selectSnapshot(({app}) => app.perPages?.find(per => per?.key === 'dashboard')?.perPage),
          dateFrom: '',
          dateTo: '',
          statuses: [],
          sales: [],
          caregiverStatuses: [],
          assigned: [],
          search: '',
          skillCategories: []
        },
        dirty: false,
        status: ''
      }
    });
  }

  @Action(GetUserListToAssignEmployee)
  getUserListToAssign(ctx: StateContext<EmployeesStateModel>, {search}: GetUserListToAssignEmployee) {
    return  this.api.getUserList(search, 0).pipe(
      tap((users) =>  {
        ctx.patchState({
          usersList: users
        });
      })
    );
  }

  @Action(GetUserListToAssignFilterEmployee)
  getUserListToAssignFilter(ctx: StateContext<EmployeesStateModel>, {search}: GetUserListToAssignFilterEmployee) {
    return  this.api.getUserList(search, 1).pipe(
      tap((users) =>  {
        ctx.patchState({
          usersListFilter: users
        });
      })
    );
  }

  @Action(SetAssignUserToEmployee)
  assignUserToEmployee(ctx: StateContext<EmployeesStateModel>, {uid, assignIds}: SetAssignUserToEmployee) {
     return  this.api.assignedUser(uid, assignIds);
  }

  @Action(DeleteUserToAssignEmployee)
  deleteAssigned(ctx: StateContext<EmployeesStateModel>, { employeeId, userId }: DeleteUserToAssignEmployee) {
     return this.api.deleteAssignedUser(employeeId, userId);
  }
}
