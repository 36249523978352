import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  ClearEvents,
  CreateEvent,
  DeleteEvent,
  EditEvent,
  GetAdmissionAuthLink,
  GetEventById,
  GetEvents,
  GetAdmissionToken,
  GetAdmissionTokenSuccess,
  ModalEvent,
  SetEventSuccess,
  SetSelectedEvent,
  TypeModalEvent
} from './admissions-calendar.action';
import { finalize, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AdmissionsCalendarService } from '../../services/admissions-calendar.service';
import { Injectable } from '@angular/core';

export interface CalendarStateModel {
  events: Event[] | any;
  stateModal: TypeModalEvent;
  activeEventModal: Event;
  authLink: string;
  pageToken: string;
  selectedEvent: Event;
  patientId: string;
  role: string;
}

const defaultState: CalendarStateModel = {
  events: [],
  stateModal: TypeModalEvent.Close,
  activeEventModal: null,
  authLink: '',
  pageToken: '',
  selectedEvent: null,
  patientId: '',
  role: ''
};

@State({
  name: 'admissionsCalendarState',
  defaults: defaultState
})

@Injectable()
export class AdmissionsCalendarState {
  constructor(
    private service: AdmissionsCalendarService,
    private store: Store
  ) {
  }

  @Selector()
  static authLink(state): string {
    return state.authLink;
  }

  @Selector()
  static selectedEvent(state): string {
    return state.selectedEvent;
  }

  @Selector()
  static events(state): Event[] {
    return state.events;
  }

  @Selector()
  static pageToken(state): Event[] {
    return state.pageToken;
  }

  @Selector()
  static stateModal(state): Observable<TypeModalEvent> {
    return state.stateModal;
  }

  @Selector()
  static activeEventModal(state): Observable<TypeModalEvent> {
    return state.activeEventModal;
  }

  @Action(GetAdmissionAuthLink)
  getAdmissionAuthLink(ctx: StateContext<CalendarStateModel>) {
    return this.service.getCalendarAuthLink().pipe(
      tap((authLink: any) => {
        ctx.patchState({
          authLink: authLink.link
        });
      })
    );
  }

  @Action(GetEvents)
  getEvents(ctx: StateContext<CalendarStateModel>, { params, patientId }: GetEvents) {
    return this.service.getEvents(params, patientId)
      .pipe(
        tap(({ items, pageToken }: any) => {
          items.map(item => {
            item.summary = item.summary === null ? 'No title' : item.summary;
            return item;
          });
          ctx.patchState({
            events: items,
            pageToken: pageToken,
            patientId: patientId
          });
        })
      );
  }

  @Action(CreateEvent)
  createEvent(ctx: StateContext<CalendarStateModel>, { event, patientId, role }) {
    const state = ctx.getState();
    if (event.start.data === event.end.data) {
      event.start = { dateTime: event.start.datetime };
      event.end = { dateTime: event.end.datetime };
    }
    return this.service.creatEvent(event, patientId, role).pipe(
      tap((newEvent: any) => {
        this.store.dispatch(new SetEventSuccess());
        ctx.patchState({
          events: [...state.events, newEvent],
          selectedEvent: null
        });
      }));
  }

  @Action(GetEventById)
  getEventById(ctx: StateContext<CalendarStateModel>, { id, uid, role }: GetEventById) {
    return this.service.getEventById(id, uid, role);
  }

  @Action(EditEvent)
  editEvent(ctx: StateContext<CalendarStateModel>, { id, event, uid, role }) {
    const state = ctx.getState();
    if (event.start.data === event.end.data) {
      event.start = { dateTime: event.start.datetime };
      event.end = { dateTime: event.end.datetime };
    }
    return this.service.updateEvent(id, event, uid, role).pipe(
      tap((newEvent: any) => {
        this.store.dispatch(new SetEventSuccess());
        ctx.patchState({
          events: state.events.map((e) => e.id === id ? newEvent : e),
          selectedEvent: null
        });
      })
    );
  }

  @Action(DeleteEvent)
  deleteEvent(ctx: StateContext<CalendarStateModel>, { id, uid, role }: DeleteEvent) {
    const state = ctx.getState();
    return this.service.deleteEvent(id, uid, role).pipe(
      tap(() => ctx.patchState({
        events: state.events.filter((event) => event.id !== id)
      }))
    );
  }

  @Action(ModalEvent)
  modalEvent(ctx: StateContext<CalendarStateModel>, { stateModal, id }: ModalEvent) {
    const state = ctx.getState();
    ctx.patchState({
      stateModal,
      activeEventModal: id ? state.events.find(event => event.id === id) : null
    });
  }

  @Action(GetAdmissionToken)
  getToken(ctx: StateContext<CalendarStateModel>, code: string) {
    return this.service.getToken(code).pipe(finalize(() => this.store.dispatch(new GetAdmissionTokenSuccess())));
  }

  @Action(SetSelectedEvent)
  setSelectedEvent(ctx: StateContext<CalendarStateModel>, { event }: SetSelectedEvent) {
    ctx.patchState({ selectedEvent: event });
  }

  @Action(ClearEvents)
  clearEvents(ctx: StateContext<CalendarStateModel>) {
    ctx.patchState({
      events: []
    });
  }
}
