function removeEmptyObjectValues(obj = {}): any {
  Object.keys(obj).forEach((k: string) => (obj[k] === '' && obj[k] !== undefined) && delete obj[k]);
  return obj;
}

function resizeWindowTrigger(): void {
  window.dispatchEvent(new Event('resize'));
}

function getDataFromLocalStorage(key: string, isParse: boolean = true) {
  try {
    return isParse
      ? JSON.parse(localStorage.getItem(key))
      : localStorage.getItem(key);
  } catch (err: any) {
    return null;
  }
}

function concatenateStrSeparatedByCommas(arr: string[]): string {
  if (arr.some((elem: string): boolean => (typeof elem === 'string' || typeof elem === 'number'))) {
    return arr.filter((elem: string) => (typeof elem === 'string' || typeof elem === 'number') && elem).join(', ');
  } else {
    return '';
  }
}

function triggerInputElementBlur() {
  const inputElement: HTMLInputElement = document.activeElement as HTMLInputElement;
  if (inputElement && inputElement.tagName === 'INPUT') {
    inputElement.blur();
  }
}

function capitalizeAndTrimWords(value: string): string {
  if (value.split(' ').length > 1) {
    return value.split(' ').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join('');
  } else {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}

function rmDuplicatesElementsByKey(arr: any[], key: string): any[] {
  const uniqueKeys: Set<any> = new Set();
  return arr.filter((item): boolean => {
    if (!uniqueKeys.has(item[key])) {
      uniqueKeys.add(item[key]);
      return true;
    }
    return false;
  });
}

export {
  removeEmptyObjectValues,
  resizeWindowTrigger,
  getDataFromLocalStorage,
  concatenateStrSeparatedByCommas,
  triggerInputElementBlur,
  capitalizeAndTrimWords,
  rmDuplicatesElementsByKey
};
