import { AdmissionFormState } from '../../dashboard/store/admission-form/admission-form.state';
import { DocumentsListAdmission, SetAction } from '../../dashboard/store/admission-form/admission-form.actions';
import { ChangeDetectorRef, Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { Select, Store } from '@ngxs/store';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { take, } from 'rxjs/operators';
import { AdmissionDocumentsEnums, RoleEnum } from '../../shared/models';
import * as moment from 'moment';
import { ReportStatusEnum } from '../../shared/enums/other.enums';
import { MaskDirective } from '../../shared/directives/mask.directive';
import { AdmissionTypeEnum } from '../../shared/enums/admission.enum';
import { PermissionAccessService } from '../../shared/services/permission-access.service';
import { PermissionAdmissionEnum } from '../../shared/enums/permission.enum';
import { AdmissionDocumentsModel } from '../../shared/models/admission-documents.model';
import { rmDuplicatesElementsByKey } from '../../shared/helpers/other';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

enum AllDocumentsValueEnum {
  NotAllSelected = 0,
  AllAdmission = 1,
  AllSR = 2,
  AllAdmissionsAndSR = 3
}

@Component({
  selector: 'app-choosed-documents-admission',
  templateUrl: './choose-documents-admission.component.html',
  styleUrls: ['./choose-documents-admission.component.scss']
})

export class ChooseDocumentsAdmissionComponent implements OnInit {
  @Input() errorSend: any = {};
  @Input() isReport = false;
  @Input() isDisableSendAction = false;
  @Input() id = '';
  @Input() typeOfApplication: AdmissionTypeEnum;
  @Input() withAdmission = false;
  @Input() checkedDocs = null;
  @Input() status: number;

  @Output() closed: EventEmitter<any> = new EventEmitter();
  @Output() nextStep: any = new EventEmitter();
  @Output() actionSign: any = new EventEmitter();

  @Select(AdmissionFormState.listAdmissionDocuments) listAdmissionDocuments$: Observable<any[]>;
  @Select(AdmissionFormState.listSentDocument) listSentDocument$: Observable<any[]>;
  @Select(AdmissionFormState.formValue) formValue$: Observable<any>;
  @Select(AdmissionFormState.dischargeSummary) dischargeSummary$: Observable<any>;

  next = false;
  documents: AdmissionDocumentsModel[] = null;
  allSelectedAdmission = false;
  allSelectedSR = false;
  activePhoneField = false;
  phoneSend = '+1';
  phoneSendRep = '+1';
  emailSend = '';
  emailSendRep = '';
  dataSend = {};
  filter = '';
  phoneMask = ['+', '1', '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  errors: any[] = [];
  activeEmailField = false;
  public sentDocks: AdmissionDocumentsModel[] = [];
  public hideSelectAll = false;
  public AdmissionTypeEnum = AdmissionTypeEnum;
  RoleEnum = RoleEnum;
  created = null;
  showCalendars: any = {
    created: false
  };
  role = this.store.selectSnapshot(({app}) => app.currentUser?.roles[0]?.title);
  protected readonly PermissionAdmissionEnum = PermissionAdmissionEnum;
  private canBeSupervisor;
  uncheckAdmissionPackage = false;
  uncheckSRPackage = false;

  constructor(
    private cd: ChangeDetectorRef,
    private store: Store,
    private permissionService: PermissionAccessService,
  ) {
  }

  ngOnInit() {
    this.patchCreatedField();
    this.permissionService.hasPermission(PermissionAdmissionEnum.CanBeSupervisor)
      .pipe(take(1)).subscribe((value: boolean) => {
      this.canBeSupervisor = value;
    });
    combineLatest([
      this.store.dispatch(new DocumentsListAdmission(this.id)), this.listSentDocument$
    ]).pipe(take(1)).subscribe({
      next: ([documents, sentDocuments]) => {
        const data = documents.admissionForm.listAdmissionDocuments;
        this.documents = data.map((item: AdmissionDocumentsModel) => {
          if (item.key === AdmissionDocumentsEnums.HomemakerSupervisoryReport) {
            return {...item, checked: true};
          }
          if (item.key === AdmissionDocumentsEnums.DischargeTransferSummaryForm) {
            return {...item, checked: true};
          }
          if (sentDocuments?.find((doc): boolean => doc === item.key)) {
            return {...item, checked: true};
          } else {
            return {...item, checked: false};
          }
        });

        this.sentDocks = JSON.parse(JSON.stringify(this.documents));
        if (this.sentDocks.length) {
          this.hideSelectAll = this.sentDocks.reduce((acc: boolean, item: AdmissionDocumentsModel): boolean => {
            return !item.checked ? item.checked : acc;
          }, true);
        }
        if (this.documents.length) {
          this.allSelectedAdmission = this.uncheckAdmissionPackage =
            this.documents.filter((doc: AdmissionDocumentsModel): boolean => doc.type === 'admission')?.length
              ? this.documents.filter((doc: AdmissionDocumentsModel): boolean => doc.type === 'admission')
                .every((doc: AdmissionDocumentsModel) => doc.checked)
              : false;
          this.allSelectedSR = this.uncheckSRPackage =
            this.documents.filter((doc: AdmissionDocumentsModel): boolean => doc.type === 'supervisory_report')?.length
              ? this.documents.filter((doc: AdmissionDocumentsModel): boolean => doc.type === 'supervisory_report')
                .every((doc: AdmissionDocumentsModel) => doc.checked)
              : false;
        }
        this.cd.detectChanges();
      },
      error: () => {
        this.documents = [];
      }
    });
  }

  closeCalendar(formControlName: string) {
    this.showCalendars[formControlName] = false;
  }

  isDisabledChange(item): boolean {
    if (item.key === 'HomemakerSupervisoryReport') {
      return true;
    }
    if (this.canBeSupervisor && this.status === ReportStatusEnum.ToBeCorrected) {
      return !!this.sentDocks.find((doc: AdmissionDocumentsModel): boolean => doc.key === item.key).checked;
    } else {
      if (this.status !== ReportStatusEnum.Signed && this.status !== ReportStatusEnum.SentForSignatures) {
        return false;
      } else {
        return !!this.sentDocks.find((doc: AdmissionDocumentsModel): boolean => doc.key === item.key).checked;
      }
    }
  }

  shouldBlockSendPhone(): boolean {
    if (this.role === RoleEnum.Employee) {
      return !this.created || (this.parsePhone(this.phoneSend).length < 12 || this.isDisableSendAction);
    } else {
      return !this.created || (this.parsePhone(this.phoneSend).length < 12 ||
        (this.parsePhone(this.phoneSendRep).length && this.parsePhone(this.phoneSendRep).length < 12) || this.isDisableSendAction);
    }
  }

  selectAll(typeOfApplication) {
    if (typeOfApplication === AdmissionTypeEnum.Admission) {
      this.allSelectedAdmission = !this.allSelectedAdmission;
      this.documents = this.documents.map((doc: AdmissionDocumentsModel) => {
        if (this.isDisabledChange(doc) || doc.type === 'supervisory_report') {
          return doc;
        } else {
          return {...doc, checked: this.allSelectedAdmission};
        }
      });
    } else {
      this.allSelectedSR = !this.allSelectedSR;
      this.documents = this.documents.map((doc: AdmissionDocumentsModel) => {
        if (this.isDisabledChange(doc) || doc.type === 'admission') {
          return doc;
        } else {
          return {...doc, checked: this.allSelectedSR};
        }
      });
    }
  }

  toggle(document: AdmissionDocumentsModel) {
    const currentDoc: AdmissionDocumentsModel =
      this.documents.find((doc: AdmissionDocumentsModel) => doc.type === document.type && doc.key === document.key);
    currentDoc.checked = !currentDoc.checked;
    this.allSelectedAdmission =
      this.documents.filter((doc: AdmissionDocumentsModel) => doc.type === 'admission' && doc.checked).length
        ? (this.documents.filter((doc: AdmissionDocumentsModel) => doc.type === 'admission' && doc.checked)?.length ===
          this.documents.filter((doc: AdmissionDocumentsModel): boolean => doc.type === 'admission')?.length)
        : false;
    this.allSelectedSR =
      this.documents.filter((doc: AdmissionDocumentsModel) => doc.type === 'supervisory_report' && doc.checked)?.length
        ? (this.documents.filter((doc: AdmissionDocumentsModel) => doc.type === 'supervisory_report' && doc.checked)?.length ===
          this.documents.filter((doc: AdmissionDocumentsModel): boolean => doc.type === 'supervisory_report')?.length)
        : false;
  }

  get selectedDocs() {
    return rmDuplicatesElementsByKey(this.documents.filter((doc: AdmissionDocumentsModel) => doc.checked), 'key');
  }

  chooseAction(action) {
    if (action === 'sign') {
      this.dataSend = {
        isFromAction: true,
        keys: this.selectedDocs.map((item: AdmissionDocumentsModel) => item.key),
        isAllDocuments: this.getIsAllDocumentsValue(),
        createdAt: moment(this.created).isValid() ? moment(this.created).format('YYYY-MM-DD') : null
      };
      this.store.dispatch(new SetAction({name: 'actionSetStarted', data: this.dataSend}));
      this.activeEmailField = false;
      this.activePhoneField = false;
    } else if (action === 'email') {
      this.activePhoneField = false;
      this.activeEmailField = true;
      this.phoneSend = '+1';
      this.phoneSendRep = '+1';
      this.errorSend = {};
    } else if (action === 'phone') {
      this.emailSend = '';
      this.emailSendRep = '';
      this.errorSend = {};
      this.activePhoneField = true;
      this.activeEmailField = false;
    }
  }

  parsePhone(phone): string {
    let newPhone: string;
    if (phone) {
      if (phone.length >= 16) {
        newPhone = phone.replace(/-*_*\(*\)*/g, '')
          .substr(0, 12);
      } else {
        newPhone = phone.replace(/-*_*\(*\)*/g, '')
          .substr(0, 12);
      }
    } else {
      newPhone = '';
    }
    return newPhone === '+1' ? '' : newPhone;
  }

  sendDocs(action) {
    this.dataSend = {
      action,
      placeForSend: action === 'email'
        ? {email: this.emailSend, secondEmail: this.emailSendRep}
        : {phone: this.parsePhone(this.phoneSend), secondPhone: this.parsePhone(this.phoneSendRep)},
      keys: this.selectedDocs.map((item: AdmissionDocumentsModel) => item.key),
      isAllDocuments: this.getIsAllDocumentsValue(),
      createdAt: moment(this.created).isValid() ? moment(this.created).format('YYYY-MM-DD') : null
    };
    this.nextStep.emit(this.dataSend);
    this.store.dispatch(new SetAction({name: 'sentDocs', data: this.dataSend}));
  }

  toggleStep() {
    this.filter = '';
    if (this.documents.filter((doc: AdmissionDocumentsModel) => doc.checked).length) {
      this.next = !this.next;
    }
  }

  showDoc(docName) {
    return this.filter === '' || docName.toLowerCase().includes(this.filter.toLowerCase());
  }

  private patchCreatedField() {
    if (this.typeOfApplication === AdmissionTypeEnum.DischargeSummary) {
      this.dischargeSummary$.pipe(take(1)).subscribe((form) => {
        this.created = moment(form.createdAt).isValid() ? moment(form.createdAt).format('MM/DD/YYYY') : '';
      });
    } else {
      this.formValue$.pipe(take(1)).subscribe((formValue: any) => {
        this.created = moment(formValue.createdAt).isValid() ? moment(formValue.createdAt).format('MM/DD/YYYY') : '';
      });
    }
  }

  public removeError(name) {
    delete this.errorSend[name];
    if (!this.errorSend || (this.errorSend && !Object.keys(this.errorSend || {})?.length)) {
      this.isDisableSendAction = false;
    }
  }

  private getIsAllDocumentsValue(): AllDocumentsValueEnum {
    if (this.allSelectedAdmission && this.allSelectedSR) {
      return AllDocumentsValueEnum.AllAdmissionsAndSR;
    }
    if (this.allSelectedAdmission && !this.allSelectedSR) {
      return AllDocumentsValueEnum.AllAdmission;
    }
    if (!this.allSelectedAdmission && this.allSelectedSR) {
      return AllDocumentsValueEnum.AllSR;
    }
    if (!this.allSelectedAdmission && !this.allSelectedSR) {
      return AllDocumentsValueEnum.NotAllSelected;
    }
  }
}

@NgModule({
  declarations: [ChooseDocumentsAdmissionComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, PerfectScrollbarModule, MaskDirective, SharedModule],
  exports: [ChooseDocumentsAdmissionComponent],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class ChooseDocumentsAdmissionModule {
}
