import { PopupFactoryService } from '../../popups/popup-factory.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {
  private connection = (navigator as any).connection || (navigator as any).mozConnection || (navigator as any).webkitConnection;
  private networkStatus: BehaviorSubject<boolean> =
    new BehaviorSubject(this.onlineStatusService.getStatus() === OnlineStatusType.ONLINE);
  networkStatus$: Observable<boolean> = this.networkStatus.asObservable();

  constructor(
    private popup: PopupFactoryService,
    private onlineStatusService: OnlineStatusService
  ) {
  }

  setNetworkStatus() {
    const isOnline: boolean = this.onlineStatusService.getStatus() === OnlineStatusType.ONLINE;
    this.networkStatus.next(isOnline);
  }

  init() {
    this.subscribeToNetworkStatus();
    this.setNetworkStatus();
    if (this.connection) {
      this.connection.addEventListener('change', () => {
        this.setNetworkStatus();
      });
    }
  }

  isOnline(): boolean {
    return this.networkStatus.value;
  }

  private subscribeToNetworkStatus() {
    this.onlineStatusService.status.subscribe(() => {
      this.setNetworkStatus();
    });
  }
}
