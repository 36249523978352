export class OpenUserForm {
  static readonly type = '[Dashboard] Open user sidebar';

  constructor(public userData: any, public uid?: string, public currentUserEdit: boolean = false, public employeeEdit: boolean = false) {
  }
}

export class OpenAdminSignWarn {
  static readonly type = '[Dashboard] Open signature warning for admin';

  constructor() {
  }
}

export class OpenMessagePopup {
  static readonly type = '[Dashboard] Open message popup';

  constructor(public message: string, public error?: any) {
  }
}
