import { Injectable } from '@angular/core';
import { PopupFactoryService } from './popups/popup-factory.service';
import { interval } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MessagePopupComponent } from './popups/message-popup/message-popup.component';
import { environment } from '../environments/environment';

const VERSION_CHECK_INTERVAL = 180000; // 3 min;

@Injectable({
  providedIn: 'root'
})
export class VersionCheckerService {

  private version = environment.version;

  constructor(private popupService: PopupFactoryService, private http: HttpClient) {
  }

  public startChecking() {
    interval(VERSION_CHECK_INTERVAL).pipe(
      switchMap(() => this.checkVersion()),
      tap((res: { version: number }) => {
        if (res?.version && (this.version !== res?.version)) {
          this.version = res.version;
          this.showPopup();
        }
      })
    ).subscribe();
  }

  public showPopup() {
    this.popupService.createPopup({
      popupComponent: MessagePopupComponent,
      popupData: {
        message: 'You are using an outdated version of the application - reload the page to avoid operational problems',
        closeBtnText: 'Reload',
        yellowHeader: true,
        closeWithoutRedirect: true,
      },
      preventBgClick: true,
      hideCloseBtn: true
    }).subscribe(() => window.location.reload());
  }

  private checkVersion() {
    return this.http.get('/version/');
  }
}
