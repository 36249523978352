import { AdmissionDto } from '../admissions/admissions.state';
import { Payload } from '../../../popups/schedule-visit/schedule-visit.component';

export class UpdateAdmissionById {
  static readonly type = '[Admission form] Update admission by id';

  constructor(public payload: AdmissionDto) {
  }
}

export class GetAdmissionById {
  static readonly type = '[Admission form] Get admission by id';

  constructor(public id: string | number, public discharge?: boolean) {
  }
}

export class GetAdmissionByToken {
  static readonly type = '[Admissions] Get admission by token';

  constructor(public token: string) {
  }
}

export class GetAdmissionStates {
  static readonly type = '[Admission form] Get state list';

  constructor() {
  }
}

export class GetReligions {
  static readonly type = '[Admission form] Get religions';

  constructor() {
  }
}

export class GetRelationships {
  static readonly type = '[Admission form] Get relationships';

  constructor() {
  }
}

export class GetRatings {
  static readonly type = '[Admission form] Get rating';

  constructor() {
  }
}

export class SaveAdmission {
  static readonly type = '[Admission form] Save admission';

  constructor(public payload: any) {
  }
}

export class ApproveAdmission {
  static readonly type = '[Admission form] Approve admission';

  constructor() {
  }
}

export class DeclineAdmission {
  static readonly type = '[Admission form] Decline admission';

  constructor(public comment: string, public withSigns: boolean) {
  }
}

export class SetStarted {
  static readonly type = '[Admission form] set started status';

  constructor(public dataSend?: any) {
  }
}

export class GetStatuses {
  static readonly type = '[Admission form] Get statuses';

  constructor() {
  }
}

export class SignDocument {
  static readonly type = '[Admission form] Sign document';

  constructor(public documentKey: string, public signs: any) {
  }
}

export class SignDocumentWithoutLogin {
  static readonly type = '[Admission form without login] Sign document';

  constructor(public token: string, public documentKey: string, public signs: any) {
  }
}

export class SetSignatureVerificationAndConfirmationErrors {
  static readonly type = '[Set Signature Verification And Confirmation Errors] Sign document';

  constructor(public errorKey: string) {
  }
}

export class ResetSignatureVerificationAndConfirmationErrors {
  static readonly type = '[Reset Signature Verification And Confirmation Errors] Sign document';

  constructor() {
  }
}

export class SetSigned {
  static readonly type = '[Admission form] set status signed';

  constructor(public id: string) {
  }
}

export class SetSignedWithoutLogin {
  static readonly type = '[SetSignedWithoutLogin] set status signed';

  constructor(public token: string, public isEmployee?: boolean) {

  }
}

export class SetPatientSigner {
  static readonly type = '[Admission form] Set patient signer';

  constructor(public relationship: number | null = null, public reason?: string) {
  }
}

export class SetPatientSignerWithoutUser {
  static readonly type = '[Admission form without user login] Set patient signer';

  constructor(public token: string, public relationship: number | null = null, public reason?: string) {
  }
}

export class SaveScheduleDaysVisit {
  static readonly type = '[Admission Schedule] Save Schedule Days Visit';

  constructor(public payload: Payload) {
  }
}

export class CreateAdmissionFromAttachment {
  static readonly type = '[Admission create from attachment] Create from attachment';

  constructor(public payload: Payload) {
  }
}

export class DocumentsListAdmission {
  static readonly type = '[Admission list documents] Get admission documents';

  constructor(public id: string) {
  }
}

export class GetAdmissionDepartments {
  static readonly type = '[Admission list documents] Get admission departments';
}

export class GetAdmissionPayers {
  static readonly type = '[Admission payers] Get admission payers';
}

export class LoadAdmission {
  static readonly type = '[Admission] Load admission';

  constructor(public load: boolean) {
  }
}

export class SetFirstSignShowArrowAdmission {
  static readonly type = '[Set First Sign Show Arrow Admission] Set First Sign Show Arrow Admission';

  constructor() {
  }
}

export class SetButtonOptions {
  static readonly type = '[Set ButtonOptions Admission] SetButtonOptions';

  constructor(public save: any) {
  }
}

export class SetAction {
  static readonly type = '[Set Actions Admission] set actions admission';

  constructor(public action: any) {
  }
}

export class ResetState {
  static readonly type = '[Reset state] reset state admission';
}

export class ResetFormValueAndDischargeSummery {
  static readonly type = '[Reset] ResetFormValueAndDischargeSummery';
}
