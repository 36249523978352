import { Directive, HostListener, Input, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appRegexOnly]'
})
export class RegexOnlyDirective {

  excludeKeyCodes = [37, 39, 46, 8];

  private regexp: RegExp;
  private excludeKeyCodesCheck = true;

  @Input() set appRegexOnly(value: string) {
    this.regexp = new RegExp(value);
  }
  @Input() set code(value: boolean) {
    this.excludeKeyCodesCheck = value;
  }

  @HostListener('keypress', ['$event'])
  onInput(event: any) {
    if (!this.regexp.test(event.key) &&  ( !this.excludeKeyCodesCheck || this.excludeKeyCodesCheck && this.excludeKeyCodes.indexOf(event.keyCode) < 0)){
      event.preventDefault();
      event.stopPropagation();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event) {
    event.preventDefault();
  }

  constructor(@Self() private ngControl: NgControl) { }

}
