import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { RoleEnum } from '../models';
import { PermissionAccessService } from '../services/permission-access.service';
import { AppState } from '../../store/app.state';

@Injectable({
  providedIn: 'root'
})

export class PermissionGuard implements CanActivate {

  @Select(AppState.currentUser) user$: Observable<any>;
  user: any;

  constructor(private store: Store,
              private router: Router,
              private permissionService: PermissionAccessService
  ) {
    this.user$.subscribe((user) => {
      this.user = user;
    });
  }


  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.user?.roles[0]?.title === RoleEnum.SuperAdmin || this.user?.roles[0]?.title === RoleEnum.Employee) {
      return true;
    } else {
      return this.permissionService.hasPermission(route?.data?.permission);
    }
  }
}
