<div [class.disabled]="disabled" class="list">
  <div
    *ngIf="!isSingleselect else singleselect"
    (click)="toggleDrop()"
    [class.show]="openDrop"
    class="list__head">
    {{ name }}
    <span *ngIf="value?.length || allItemCheckedLength" class="count">
      ({{ saveAllData ? (allItemCheckedLength || 0) : (value?.length || 0) }})
    </span>
  </div>

  <ng-template #singleselect>
    <div (click)="toggleDrop()" [class.show]="openDrop" class="list__head">
      <ng-container *ngIf="checkedItems$ | async as checkedItems">
        {{ checkedItems?.length
        ? checkedItems[0][bindLabel]
        : selectedValue?.length
          ? selectedValue[0][bindLabel]
          : name
        }}
      </ng-container>
    </div>
  </ng-template>

  <div [ngClass]="{'show': openDrop, 'with-search': withSearchField}" class="list__drop">
    <div *ngIf="!itemList?.length && (!!searchValue || !withSearchField)" class="no-items">{{ noItemsText }}</div>

    <div *ngIf="type !== 'radio'; else radioTempalte">
      <label *ngIf="withSearchField" class="search-wrap">
        <input
          (keyup)="onSearch($event.target['value'])"
          [(ngModel)]="searchValue"
          type="text"
          class="search-select"
          placeholder="Search"/>
      </label>

      <div *ngIf="isSelectAll" class="form-group">
        <input
          (change)="selectAll()"
          [checked]="isSelectedAll && value?.length === itemList.length"
          type="checkbox"
          id="select-all"/>
        <label for="select-all">
          <span class="status-name">Select all</span>
        </label>
      </div>
      <div class="scroll">
        <div *ngFor="let item of itemList; let i = index; trackBy: trackByFn" class="form-group"
             >
          <input *ngIf="isSingleselect && !withSearchField" class="no-display"/>
          <input
            (change)="toggleItem(i, true)"
            [id]="prefix ? prefix + item[bindValue] : item[bindValue]"
            [disabled]="disableEmpty && checkChecked(item[bindValue])"
            [checked]="saveAllData ? item.checked : checkChecked(item[bindValue])"
            type="checkbox"/>
          <label for="{{ prefix ? prefix + item[bindValue] : item[bindValue] }}"
                 [attr.data-e2e]="'multiselect-' + item[bindLabel]">
            <span *ngIf="item.color" [style.background]="item.color" class="status-color"></span>
            <span class="status-name">{{ item[bindLabel] }}</span>
          </label>
        </div>
      </div>
    </div>

    <ng-template #radioTempalte>
      <label *ngIf="withSearchField" class="search-wrap">
        <input type="text" class="search-select" placeholder="Search">
      </label>

      <input
        *ngIf="needHaveEmptyRadioButton"
        [name]="'radio-group' + number"
        [checked]="!value && value !== 0"
        type="radio"
        id="none"
        style="display: none"/>

      <div *ngFor="let item of itemList; let i = index; trackBy: trackByFn"

           class="radio-item select">
        <input
          (change)="toggleItem(i)"
          [name]="'radio-group' + number"
          [checked]="item.checked"
          [id]="'changeType' + item.id + number"
          type="radio"/>
        <label for="{{'changeType' + item.id + number}}"
               [attr.data-e2e]="'multiselect-' + item[bindLabel]">
          <span class="status-name">{{ item[bindLabel] }}</span>
        </label>
      </div>
    </ng-template>
  </div>
</div>
