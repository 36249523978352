import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

export interface PopupFactoryInterface {
  popupComponent: any;
  popupData?: any;
  title?: string;
  subscribers?: string[];
  popupBodyClass?: string;
  hideIcon?: boolean;
  hideCloseBtn?: boolean;
  preventBgClick?: boolean;
  isHeightFull?: boolean;
  isReadLatterConfirm?: boolean;
}

@Injectable()
export class PopupFactoryService {
  public popupOutput$: Subject<any> = new Subject();
  private popupSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public scrollSuppress: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public popup$: Observable<any> = this.popupSubject.pipe(filter(form => form !== null));
  public closePopup$: EventEmitter<void> = new EventEmitter();

  constructor() {
  }

  createPopup({
                popupComponent,
                isReadLatterConfirm,
                popupData,
                title,
                subscribers,
                popupBodyClass,
                hideIcon,
                hideCloseBtn,
                preventBgClick,
                isHeightFull
              }: PopupFactoryInterface) {
    return new Observable((observer: Observer<any>) => {
      this.popupSubject.next({
        popupComponent,
        popupData,
        isReadLatterConfirm,
        title,
        subscribers,
        popupBodyClass,
        hideIcon,
        hideCloseBtn,
        preventBgClick,
        isHeightFull
      });
      this.popupOutput$.pipe(take(1)).subscribe(data => observer.next(data));
    }).pipe(take(1));
  }

  closePopup() {
    this.closePopup$.emit();
  }
}
