import { Action, Selector, State, StateContext } from '@ngxs/store';
import { StaticDataService } from '../../../static-data.service';
import { GetDepartments } from './departments.actions';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

interface DepartmentsStateModel {
  list: any[];
}

export const defaultState: DepartmentsStateModel = {
  list: []
};

@State({
  name: 'departments',
  defaults: defaultState
})

@Injectable()
export class DepartmentsState {

  constructor(
    private service: StaticDataService
  ) {
  }

  @Selector()
  static list(state) {
    return state.list;
  }

  @Action(GetDepartments)
  getDepartments(ctx: StateContext<DepartmentsStateModel>) {
    return this.service.getDepartments().pipe(tap((data: any[]) => ctx.patchState({list: data})));
  }
}
