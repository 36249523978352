import { AfterContentInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { CalendarEvent } from '../../../models/calendar.model';

@Component({
  selector: 'app-time-select',
  templateUrl: './time-select.component.html',
  styleUrls: ['./time-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TimeSelectComponent,
      multi: true
    }
  ]
})
export class TimeSelectComponent implements ControlValueAccessor, AfterContentInit {
  @Input() public step = 15;
  @Input() public startTime = '00:00 am';
  @Input() public endTime = '00:00 am';


  @Input() public borderColor = '#CFD0DE';
  @Input() placeholderValue = 'Time';
  @Input() valueTime = '';

  private _minTime = '';
  private _maxTime = '';
  private _overnight = false;
  private _previousDayOvernight: string;

  options = {
    now: new Date(),
    hoursEnd: null,
    minutes: null,
    count: 0,
    countEnd: 0,
    isToday: null,
    isEnd: null,
    isStart: null,
    startDate: null,
    isEdit: null,
    isMinTime: null,
    isMaxTime: null,
    maxTime: null,
    minTime: null,
    isActive: true,
    overnight: false,
    prevPeriodOvernight: false,
    idSlot: '',
  };

  @Input() set maxTime(value: string) {
    this._maxTime = value ? value : this.endTime;
    this.getAvailableSlot();
  }

  @Input() set minTime(value: string) {
    this._minTime = value ? value : this.startTime;
    this.getAvailableSlot();
  }

  @Input() set overnight(value: boolean) {
    this._overnight = value;
    this.getAvailableSlot();
  }

  @Input() set previousDayOvernight(value: string) {
    this._previousDayOvernight = value;
    this.minTime = value;
  }

  get previousDayOvernight() {
    return this._previousDayOvernight;
  }

  get minTime(): string {
    return this._minTime;
  }

  get maxTime(): string {
    return this._minTime;
  }

  get overnight(): boolean {
    return this._overnight;
  }

  @Input() set isMinTime(value: boolean) {
    if (value) {
      this.options.isMinTime = true;
    }
  }

  @Input() set isMaxTime(value: boolean) {
    if (value) {
      this.options.isMaxTime = true;
    }
  }

  @Input() set isStart(value: boolean) {
    if (value) {
      this.options.isStart = true;
    }
  }

  @Input() set isEnd(value: boolean) {
    if (value) {
      this.options.isEnd = true;
    }
  }

  @Input() set isToday(value: boolean) {
    if (value) {
      this.options.isToday = true;
    }
  }

  @Input() set isEdit(value: boolean) {
    if (value) {
      this.options.isEdit = true;
    }
  }

  @Input() set data(value: any) {
    if (value) {
      value = moment(value.date).format();
      this.options.startDate = value.datetime;
      this.getTimeRemaining();
      this.generateTimeSlots();
    }
  }

  @Input() set event(value: CalendarEvent) {
    if (value) {
      if (value.start) {
        this.getTimeRemaining();
        this.checkDateIsToday(value.start);
      }
    }
  }

  @Output()
  change: EventEmitter<any> = new EventEmitter<any>();
  configScroll = {
    wheelPropagation: true,
    autoPropagation: true
  };

  public timeSlots: { name: string, disable: boolean }[] = [];
  public isOpen = false;
  val = '';

  set value(val: string) {
    this.val = val;
    this.onChange(val);
    this.onTouch(val);
  }

  get value() {
    return this.val;
  }

  ngAfterContentInit() {
    if ((this.options.isToday && this.options.isStart)
      || (this.options.isEdit && this.options.isStart)) {
      this.getTimeRemaining();
    }
    this.generateTimeSlots();
  }

  private getTimeRemaining() {
    this.options.minutes = this.options.now.getMinutes();
    this.options.hoursEnd = this.options.now.getHours();
    this.options.hoursEnd = this.options.minutes > 30 ?
      this.options.hoursEnd + 1 :
      this.options.hoursEnd;
  }

  private checkDateIsToday(value: string) {
    if (value) {
      const date: string = value.toString();
      if (new Date().setHours(0, 0, 0, 0) ===
        new Date(date).setHours(0, 0, 0, 0)) {
        this.options.isToday = true;
      } else {
        this.options.isToday = false;
        this.generateTimeSlots();
      }
    }
  }

  public generateTimeSlots(): void {
    const startTime: moment.Moment = moment(this.startTime, 'LT');
    const endTime: moment.Moment = moment(this.endTime, 'LT').add(1, 'day');
    this.timeSlots = [];
    while (startTime <= endTime) {
      const slot: moment.Moment = moment(startTime, 'hh:mm a');
      if (startTime.format('LLL') === endTime.format('LLL')) {
        slot.add(-1, 'minutes');
      }
      this.timeSlots.push({name: slot.format('hh:mm A').replace('00:', '12:'), disable: false});
      startTime.add(this.step, 'minutes');
    }
    this.getAvailableSlot();
  }

  public getAvailableSlot() {
    if (this.options.isStart && !this.previousDayOvernight) {
      return;
    }
    this.timeSlots.forEach((timeSlot) => {
      const startTime: moment.Moment = moment(this.minTime, 'HH:mm a');
      const endTime: moment.Moment = moment(this.maxTime, 'HH:mm a');
      const current: moment.Moment = moment(timeSlot.name, 'HH:mm a');
      if (endTime.isBefore(startTime) || startTime.valueOf() === endTime.valueOf()) {
        endTime.add(1, 'day');
      }
      if (this.overnight) {
        current.add(1, 'day');
        endTime.add(1, 'day');
      }
      const timeTo = !(startTime.isBefore(current) && endTime.isAfter(current));
      const timeFrom = !(startTime.isBefore(current) && endTime.isAfter(current) || startTime.valueOf() === current.valueOf());
      timeSlot.disable = this.previousDayOvernight ? timeFrom : timeTo;
    });
  }

  onTouchmove($event: TouchEvent) {
    $event.stopPropagation();
  }

  onChange: any = () => {
  }

  onTouch: any = () => {
  }

  writeValue(value: any) {
    if (value) {
      this.value = value.replace('00:', '12:');
    } else {
      this.value = '';
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
