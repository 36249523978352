// example of currently used rules

export const rules = {
  admission: {
    bloodPressureLabel: 'Do you remember your High/Low Blood Pressure Levels?', // New Century: Who does patient lives with?,
    showSecondEmergencyContact: false // New Century: true
  },
  employee: {
    showWorkPartnersDocumentLogo: false // impactfulhomecare: false
  }
};
