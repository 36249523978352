import { EmployeeGenerateForm } from './employee-generate-form.model';

export class GetAllEmployees {
  static readonly type = '[Employees] Get all employees';

  constructor(public filters?: any, public sort?: { order: string, dir: string }) {
  }
}

export class GenerateEmployee {
  constructor(public employeeData: EmployeeGenerateForm) {
  }

  static readonly type = '[Employees] Generate random employee';
}

export class OpenGenerateEmployee {
  static readonly type = '[Employees] Open generate popup';
}

export class ClearGeneratedEmployee {
  static readonly type = '[Employees] Clear generated employee';
}

export class DeleteEmployee {
  static readonly type = '[Employees] Delete employee';

  constructor(public uid: string) {
  }
}

export class GetSalesList {
  static readonly type = '[Employees] Get list of sales';

  constructor(public filterData?: {
    search: string;
    statuses: string[];
    creators: string[];
    date: string
    page?: number;
    rows: number;
  }) {
  }
}

export class GetSalesListForFilter {
  static readonly type = '[Employees] Get list of sales for filter';

  constructor(public search?: string) {
  }
}

export class SetPage {
  static readonly type = '[Employees] Set page';

  constructor(public page: number) {
  }
}

export class PostEmployee {
  static readonly type = '[Employee] Create new employee';

  constructor(public employeeData: any) {
  }
}

export class EditEmployee {
  static readonly type = '[Employee] Edit employee';

  constructor(public data: any) {
  }
}

export class CreateEmploee {
  static readonly type = '[Employee] Start employee creation';

  constructor() {
  }
}

export class SendEmail {
  static readonly type = '[Employee] Send email';

  constructor(public email: string) {
  }
}

export class SendMessage {
  static readonly type = '[Employee] Send sms';

  constructor(public phone: number) {
  }
}

export class DownloadDocument {
  static readonly type = '[Employee] Download document';

  constructor(public uid: string, public format: 'archive' | 'pdf', public historyId?: number) {
  }
}

export class PrintDocument {
  static readonly type = '[Employee] Print document';

  constructor() {
  }
}

export class ResetAdminPanel {
  static readonly type = '[Employee] Reset loading state';
}

export class HhaExchangeSync {
  static readonly type = '[Employee] Sync HHAeXchange';

  constructor(public userIds: string[]) {
  }
}

export class ChangeDates {
  static readonly type = '[Employee] Change createdAt';

  constructor(public uuid: string, public newDate: string, public typeDate?: string) {
  }
}

export class GetAttachments {
  static readonly type = '[Employee] Get attachments list (employee)';

  constructor(public filters: any = {}, public sort?: { order: string, dir: string }) {
  }
}

export class RefreshAttachmentsList {
  static readonly type = '[Employee] Refresh attachments list (employee)';

  constructor() {
  }
}

export class UpdateAttachment {
  static readonly type = '[Employee] Update an attachment';

  constructor(public attachmentId: string, public title: string) {
  }
}

export class DeleteAttachment {
  static readonly type = '[Employee] Delete an attachment (employee)';

  constructor(public attachmentId: number | string) {
  }
}

export class DownloadAttachment {
  static readonly type = '[Employee] Download an attachment';

  constructor(public attachmentIds: number | string | string[]) {
  }
}

export class DownloadMultipleAttachments {
  static readonly type = '[Employee] Download multiple attachments';

  constructor(public ids: string[]) {
  }
}

export class UploadDocument {
  static readonly type = '[Employee] Upload document';

  constructor(public title: string, public file: any) {
  }
}

export class SetNoteEmployee {
  static readonly type = '[Employee] Create note';

  constructor(public uid: string, public text: string) {
  }
}

export class DeleteNoteEmployee {
  static readonly type = '[Employee] Delete note';

  constructor(public uid: string) {
  }
}

export class ResetFilters {
  static readonly type = '[Dashboard] Reset filters';

  constructor() {
  }
}

export class GetUserListToAssignEmployee {
  static readonly type = '[Dashboard] Get Assign user';
  constructor(public search?: string) {
  }
}

export class SetAssignUserToEmployee {
  static readonly type = '[Dashboard] Assign user';

  constructor(public uid: string[], public assignIds: string[]) {
  }
}

export class DeleteUserToAssignEmployee {
  static readonly type = '[Dashboard] Delete Assign user';
  constructor(public employeeId: string, public userId: string) {
  }
}

export class GetUserListToAssignFilterEmployee {
  static readonly type = '[Dashboard] Get Assign user for filter';
  constructor(public search?: string) {
  }
}
