import { CloseConfirmPopup } from '../../dashboard/store/educations/educations.actions';
import { EducationState } from '../../dashboard/store/educations/education.state';
import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { takeUntil, filter, take } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { NgxsModule, Select, Store } from '@ngxs/store';
import { NavigationStart, Router, RouterEvent, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

const defaultHandler = ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'];

@Component({
  selector: 'app-popup-base',
  templateUrl: './popup-base.component.html',
  styleUrls: ['./popup-base.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NgxsModule,
    PerfectScrollbarModule
  ]
})
export class PopupBaseComponent implements OnInit, OnDestroy {
  @Input() popupComponent: ComponentRef<any>;
  @Input() popupData: any;
  @Input() title: string;
  @Input() subscribers: string[];
  @Input() popupBodyClass: string;
  @Input() isHeightFull: boolean;
  @Input() hideIcon: boolean;
  @Input() hideCloseBtn: boolean;
  @Input() preventBgClick: boolean;
  @Input() isReadLatterConfirm;
  @Input() popupIsImage: boolean;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild('popupContainer', { read: ViewContainerRef, static: true }) popupContainer: ViewContainerRef;

  @Select(EducationState.isCloseDoc) isCloseDoc$: Observable<any[]>;
  @Select(EducationState.isEducationDataLoaded) isEducationDataLoaded$: Observable<any[]>;
  private destroy$ = new ReplaySubject<void>(1);
  popupSaveState: any;
  config = {
    handlers: defaultHandler,
    wheelPropagation: true,
  };
  factory;
  instance: any = {};

  @HostListener('click', ['$event'])
  onOuterClick(event) {
    if (event.target.tagName.toLowerCase() === 'app-popup-base' && !this.preventBgClick) {
      this.onClose();
    }
  }

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private store: Store
  ) {
    this.clickPopState();
  }

  ngOnInit() {
    if (this.popupContainer) {
      this.popupContainer.clear();
    }
    this.instance = this.popupContainer.createComponent(this.popupComponent as any).instance;
    if (this.popupData) {
      Object.keys(this.popupData || {}).forEach(key => {
        this.instance[key] = this.popupData[key];
      });
    }
    if (this.subscribers) {
      this.popupSaveState = {};
      this.subscribers.forEach(subscriber => {
        this.instance[subscriber].pipe(takeUntil(this.destroy$)).subscribe(data => this.popupSaveState[subscriber] = data);
      });
    }
    if (this.instance.saveState) {
      this.instance.saveState.subscribe(data => {
        this.popupSaveState = data;
      });
    }
    if (this.instance.closed) {
      this.instance.closed.subscribe((data) => {
        this.onClose(data);
      });
    }
    this.subscribeCloseDoc();

    this.popupData?.scrollUpdate$?.pipe(takeUntil(this.destroy$)).subscribe((flag: boolean) => {
      this.config = {
        ...this.config,
        handlers: flag ? [] : defaultHandler,
      };
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.popupContainer.clear();
  }

  onClose(data?): void {
    this.destroy$.next();
    if (this.isReadLatterConfirm) {
      if (this.popupData) {
        if (this.popupData.video.isSeen) {
          this.close.emit({ data, saveState: this.popupSaveState });
        } else {
          this.isEducationDataLoaded$.pipe(take(1)).subscribe((value: any[]) => {
            if (value) {
              this.instance['isConfirm'] = true;
            } else {
              this.close.emit({ data, saveState: this.popupSaveState });
            }
          });
        }
      }
    } else {
      this.instance['isConfirm'] = false;
      this.close.emit({ data, saveState: this.popupSaveState });
    }
  }

  private subscribeCloseDoc() {
    this.isCloseDoc$.pipe(take(2), filter((data: any) => data)).subscribe((() => {
      if (!this.cd['destroyed']) {
        this.cd.detectChanges();
      }
      this.store.dispatch(new CloseConfirmPopup(false));
      this.close.emit({ saveState: this.popupSaveState });
    }));
  }

  private clickPopState() {
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationStart),
      takeUntil(this.destroy$)
    ).subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.onClose();
      }
    });
  }
}
