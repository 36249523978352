import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import {
  DeleteAssignedUserAdmission,
  GetUserListToAssignAdmission,
  SetAssignedUserAdmission
} from '../../../dashboard/store/admissions/admissions.actions';
import { take } from 'rxjs/operators';
import { UserToAssign } from '../../models/assigned-model';
import {
  DeleteUserToAssignEmployee,
  GetUserListToAssignEmployee,
  SetAssignUserToEmployee
} from '../../../dashboard/store/employees/employees.actions';
import {
  DeleteUserToAssignPatient,
  GetUserListToAssignPatient,
  SetAssignUserToPatient
} from '../../../dashboard/store/patients/patients-list/patients.actions';
import { EmployeesState } from '../../../dashboard/store/employees/employees.state';
import { AdmissionsState } from '../../../dashboard/store/admissions/admissions.state';
import { PatientsState } from '../../../dashboard/store/patients/patients-list/patients.state';
import { IncidentsAssignUsersState } from '../../../dashboard/store/incidents/incidents-assign-users/incidents-assign-users.state';
import {
  DeleteUserToAssignIncident,
  GetUserListToAssignIncidents,
  SetAssignUserToIncident
} from '../../../dashboard/store/incidents/incidents-assign-users/incidents-assign-users.actions';

@Component({
  selector: 'app-assigned-slide',
  templateUrl: './assigned-slide.component.html',
  styleUrls: ['./assigned-slide.component.scss']
})
export class AssignedSlideComponent implements OnInit {
  @Input() showSlideAssigned;
  @Input() selectedObjects;
  @Input() type: 'admission' | 'employee' | 'incidents' | 'patient';

  @Output() closeEmitter: EventEmitter<any> = new EventEmitter();
  @Output() updateEmitter: EventEmitter<any> = new EventEmitter();

  @Select(EmployeesState.usersToAssign) usersToAssignedEmployee$: Observable<UserToAssign[]>;
  @Select(AdmissionsState.usersToAssign) usersToAssignedAdmission$: Observable<UserToAssign[]>;
  @Select(PatientsState.usersToAssign) usersToAssignedPatient$: Observable<UserToAssign[]>;
  @Select(IncidentsAssignUsersState.usersToAssign) usersToAssignedIncidents$: Observable<UserToAssign[]>;

  users$: Observable<UserToAssign[]>;
  public query: FormControl<string> = new FormControl('');
  public usersSelected: FormControl<any> = new FormControl([]);

  constructor(
    private store: Store
  ) {
  }

  ngOnInit(): void {
    if (this.selectedObjects.length === 1) {
      this.usersSelected.patchValue(this.selectedObjects[0].assigned ? this.selectedObjects[0].assigned : []);
    }
    if (this.type === 'admission') {
      this.store.dispatch(new GetUserListToAssignAdmission(''));
      this.users$ = this.usersToAssignedAdmission$;
    }
    if (this.type === 'employee') {
      this.store.dispatch(new GetUserListToAssignEmployee(''));
      this.users$ = this.usersToAssignedEmployee$;
    }
    if (this.type === 'patient') {
      this.store.dispatch(new GetUserListToAssignPatient(''));
      this.users$ = this.usersToAssignedPatient$;
    }
    if (this.type === 'incidents') {
      this.store.dispatch(new GetUserListToAssignIncidents(''));
      this.users$ = this.usersToAssignedIncidents$;
    }
    this.changeItems();
  }

  public checked(user) {
    return this.usersSelected.value.some((item): boolean => item.id === user.id);
  }

  public checkItems(user, event) {
    if (event.target.checked) {
      this.usersSelected.patchValue([
          ...this.usersSelected.value,
          user
        ]
      );
    } else {
      this.usersSelected.patchValue(
        this.usersSelected.value.filter(item => item.id !== user.id)
      );
    }
  }

  public disableUncheck(user): boolean {
    if (this.selectedObjects.length > 1 || this.selectedObjects.length === 1 && !this.selectedObjects[0].assigned) {
      return false;
    } else {
      return !!this.selectedObjects[0].assigned?.find((item): boolean => item.id === user.id);
    }
  }

  private changeItems() {
    this.query.valueChanges.subscribe((value: string) => {
      if (this.type === 'admission') {
        this.store.dispatch(new GetUserListToAssignAdmission(value));
      }
      if (this.type === 'employee') {
        this.store.dispatch(new GetUserListToAssignEmployee(value));
      }
      if (this.type === 'patient') {
        this.store.dispatch(new GetUserListToAssignPatient(value));
      }
      if (this.type === 'incidents') {
        this.store.dispatch(new GetUserListToAssignIncidents(value));
      }
    });
  }

  close() {
    this.closeEmitter.emit();
  }

  delete(user) {
    this.usersSelected.patchValue(
      this.usersSelected.value.filter(item => item.id !== user.id)
    );
    if (this.selectedObjects.length === 1
      && this.selectedObjects[0].assigned.find((item): boolean => item.id === user.id)) {
      this.updateEmitter.emit();
      if (this.type === 'admission') {
        this.store.dispatch(new DeleteAssignedUserAdmission(this.selectedObjects[0].id, user.id))
          .pipe(take(1))
          .subscribe(() => {
            this.selectedObjects[0].assigned = this.selectedObjects[0].assigned.filter((item): boolean => item.id !== user.id);
          });
      }
      if (this.type === 'employee') {
        this.store.dispatch(new DeleteUserToAssignEmployee(this.selectedObjects[0].uid, user.id))
          .pipe(take(1))
          .subscribe(() => {
            this.selectedObjects[0].assigned = this.selectedObjects[0].assigned.filter((item): boolean => item.id !== user.id);
          });
      }
      if (this.type === 'incidents') {
        this.store.dispatch(new DeleteUserToAssignIncident(this.selectedObjects[0].id, user.id))
          .pipe(take(1))
          .subscribe(() => {
            this.selectedObjects[0].assigned = this.selectedObjects[0].assigned.filter((item): boolean => item.id !== user.id);
          });
      }
      if (this.type === 'patient') {
        this.store.dispatch(new DeleteUserToAssignPatient(this.selectedObjects[0].id, user.id))
          .pipe(take(1))
          .subscribe(() => {
            this.selectedObjects[0].assigned = this.selectedObjects[0].assigned.filter((item): boolean => item.id !== user.id);
          });
      }
    }
  }

  assign() {
    this.updateEmitter.emit();
    if (this.type === 'admission') {
      this.store.dispatch(new SetAssignedUserAdmission(
        this.selectedObjects.map((item) => item.id),
        this.usersSelected.value.map((item) => item.id))
      ).pipe(take(1))
        .subscribe(() => {
          this.closeEmitter.emit();
        });
    }
    if (this.type === 'employee') {
      this.store.dispatch(
        new SetAssignUserToEmployee(
          this.selectedObjects.map((item) => item.uid),
          this.usersSelected.value.map((item) => item.id))
      ).pipe(take(1))
        .subscribe(() => {
          this.closeEmitter.emit();
        });
    }
    if (this.type === 'incidents') {
      this.store.dispatch(new SetAssignUserToIncident(
        this.selectedObjects.map((item) => item.id),
        this.usersSelected.value.map((item) => item.id))
      ).pipe(take(1))
        .subscribe(() => {
          this.closeEmitter.emit();
        });
    }
    if (this.type === 'patient') {
      this.store.dispatch(new SetAssignUserToPatient(
        this.selectedObjects.map((item) => item.id),
        this.usersSelected.value.map((item) => item.id))
      ).pipe(take(1))
        .subscribe(() => {
          this.closeEmitter.emit();
        });
    }
  }
}
