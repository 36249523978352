import { CreatePayload } from '../../../patients/patients.component';

export class GetPatientList {
  static readonly type = '[Patients] Get patients list';

  constructor(
    public filterData?: { query?: string, startDate?: string, statuses?: string[], supervisors?: string[], innerHHAStatus: string[], assigned: string[] },
    public sortObj?: { order?: string, dir?: string }
  ) {
  }
}

export class GetPatientListFilter {
  static readonly type = '[Patients] Get patients list filter';

  constructor(public filterData?: { search: ''} ) {
  }
}

export class SetPatient {
  static readonly type = '[Patients] Set patient';

  constructor(public patient) {
  }
}

export class GetPatientNoteReasonsFilters {
  static readonly type = '[GetPatientAdmissionNoteReasonsFilter] Get Patient Note Reasons Filter';

  constructor(public patientId: string) {
  }
}

export class GetPatientById {
  static readonly type = '[Patients] Get patient by id';

  constructor(public id: string) {
  }
}

export class DownloadPatient {
  static readonly type = '[Patients] Download patients';

  constructor(public id: string, public format: 'pdf' | 'zip') {
  }
}

export class SetPage {
  static readonly type = '[Patients] Set page';

  constructor(public page: number) {
  }
}

export class SetPerPage {
  static readonly type = '[Patients] Set per page';

  constructor(public perPage: number) {
  }
}

export class GetSupervisors {
  static readonly type = '[Patients] Get supervisors';

  constructor() {
  }
}

export class GetCoordinators {
  static readonly type = '[Patients] Get coordinators';

  constructor() {
  }
}

export class GetPatientStatuses {
  static readonly type = '[Patients] Get Patient Statuses';

  constructor() {
  }
}

export class ResetPatientListErrors {
  static readonly type = '[ResetPatientListErrors] Reset Patient List Errors';
}

export class GetPatientPositions {
  static readonly type = '[GetPatientPositions] Get Patient Positions';
}

export class GetPatientNoteReason {
  static readonly type = '[GetPatientNoteReason] Get Patient Note Reasons';

  constructor(public patientId: string) {
  }
}

export class CreatePatientNote {
  static readonly type = '[CreatePatientNote] Create Patient Note';

  constructor(public payload: CreatePayload) {
  }
}

export class EditPatientNote {
  static readonly type = '[EditPatientNote] Edit Patient Note';

  constructor(public payload: CreatePayload) {
  }
}

export class GetPatientNotes {
  static readonly type = '[GetPatientNotes] Get Patient Note';

  constructor(public patientId: string, public filters?: any, public sort?: any, public getAllNotes?: boolean) {
  }
}

export class DeletePatientNote {
  static readonly type = '[DeletePatientNote] Delete Patient Note';

  constructor(public payload: { patientId: string, noteId: string }) {
  }
}

export class ClearNotes {
  static readonly type = '[ClearNotes] Clear Patient Notes';
}

export class SetNotesPage {
  static readonly type = '[Patient] Set notes table page';

  constructor(public page: number) {
  }
}
export class SetStatusPatientHHa {
  static readonly type = '[Patient] Set hhaStatus';

  constructor(public patientId: number, public status) {
  }
}

export class GetUserListToAssignPatient {
  static readonly type = '[Patient] Get assign users';
  constructor(public search?: string) {
  }
}

export class GetUserListToAssignFilterPatient {
  static readonly type = '[Patient] Get assign filter users';
  constructor(public search?: string) {
  }
}

export class SetAssignUserToPatient{
  static readonly type = '[Patient] Set assign users';

  constructor(public uid: string[], public assignIds: string[]) {
  }
}

export class DeleteUserToAssignPatient {
  static readonly type = '[Patient] Delete assign user';

  constructor(public patientId: string, public userId: string) {
  }
}
