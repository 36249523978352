export const STATUSES = {
  NEW: 1,
  STARTED: 2,
  SENT_FOR_SIGN: 3,
  SIGNED: 4,
  SENT_FOR_CORRECTIONS: 5,
  CORRECTED: 6,
  COMPLETED: 7,
  SUBMITTED_TO_HR: 8,
  PENDING_DOCUMENTS: 9,
  UNCOMPLETED: 10,
  STARTED_AO: 11,
  AO: 12
};

export enum INCIDENT_STATUSES {
  DRAFT = 0,
  NEW = 1,
  DISCHARGED = 5,
}

export enum TIMESHEETS_STATUSES {
  TO_BE_FILLED = 1,
  SIGNED = 2,
  APPROVED = 3,
  SENT_FOR_SIGN = 4,
}
