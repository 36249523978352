import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { GetLoginUseMicrosoftUrl, Login, ResetAuthErrors } from '../../store/app.actions';
import { STATUSES } from '../../static/statuses';
import { environment } from 'src/environments/environment';
import { RoleEnum, UserModel } from '../../shared/models';
import { AppState } from '../../store/app.state';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit, OnDestroy {
  @Select(AppState.rules) rules$: Observable<any>;
  @Select(AppState.microsoftRedirectUrl) microsoftRedirectUrl$: Observable<string>;

  private destroy$: ReplaySubject<void> = new ReplaySubject(1);
  formState$: Observable<any> = this.store.select(({app}) => app.meta.authForm);
  loginForm: FormGroup;
  isLogin = false;
  isLoginWithMicrosoft = false;
  environment = environment;

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.checkIsLoginWithMicrosoft();
    this.getCurrentUser();
    this.initForms();
    this.subscribeToValueChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  signIn() {
    const formError = this.store.selectSnapshot(({app}) => app.meta.authForm.errors);
    if (!formError) {
      this.store.dispatch(new Login(this.loginForm.value));
    }
  }

  getLoginUseMicrosoft() {
    this.store.dispatch(new GetLoginUseMicrosoftUrl()).pipe(take(1)).subscribe({
      next: () => {
        this.authByMicrosoftRedirectUrl();
      }
    });
  }

  private authByMicrosoftRedirectUrl() {
    this.microsoftRedirectUrl$.pipe(takeUntil(this.destroy$)).subscribe((redirectUrl: string) => {
      if (redirectUrl) {
        const params: any = 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000';
        window.open(redirectUrl, '_self', params);
      }
    });
  }

  private subscribeToValueChanges() {
    this.loginForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const formError = this.store.selectSnapshot(({app}) => app.meta.authForm.errors);
      if (formError) {
        this.store.dispatch(new ResetAuthErrors());
      }
    });
  }

  private initForms() {
    this.loginForm = this.fb.group({
      email: '',
      password: ''
    });
  }

  private getCurrentUser() {
    this.store.select(({app}) => app.currentUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: UserModel) => {
        if (user && !this.isLogin) {
          if (user?.roles?.length && user?.roles[0]?.title === RoleEnum.Employee) {
            if (!user.documentHistory || !user.documentHistory.status || user.documentHistory.status.id === STATUSES.STARTED) {
              this.isLogin = true;
              this.router.navigate(['/dashboard', 'employee-application', user.uid]);
            } else {
              if (user.documentHistory.status.id === STATUSES.SENT_FOR_SIGN) {
                this.isLogin = true;
                this.router.navigate(['/dashboard', 'employee-document', user.uid, user.documentHistory.id]);
              } else {
                this.isLogin = true;
                this.router.navigate(['/dashboard', 'employee-dashboard', 'documents']);
              }
            }
          } else {
            this.isLogin = true;
            this.router.navigate(['/dashboard']);
          }
        }
      });
  }

  private checkIsLoginWithMicrosoft() {
    this.rules$.pipe(takeUntil(this.destroy$)).subscribe((rules: any) => {
      this.isLoginWithMicrosoft = !!rules?.system?.sso_buttons?.includes('microsoft');
    });
  }
}
