import { AfterViewInit, Directive, ElementRef, Input, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDisabledState]'
})
export class DisabledStateDirective implements AfterViewInit {

  constructor(private elRef: ElementRef, @Self() private ngControl: NgControl) { }

  valueInput = false;
  @Input() debug: boolean;
  @Input() set appDisabledState(value) {
    this.valueInput = value;
    if (!!value && this.ngControl.control) {
      this.ngControl.control.setValue('');
      this.ngControl.control.disable();
    } else if (this.ngControl.control) {
      this.ngControl.control.enable();
    }
  }

  ngAfterViewInit() {
    if (!!this.valueInput && this.ngControl.control) {
      this.ngControl.control.setValue('');
      this.ngControl.control.disable();
    } else if (this.ngControl.control) {
      this.ngControl.control.enable();
    }
  }
}
