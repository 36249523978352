import { PasswordsDto, UserEditDto } from '../../services/user.service';
import { CreateNewUserPayload, UpdateUserModeEnum, UpdateUserOptionsModel, UpdateUserPasswordPayload } from '../../../shared/models';

export class ResetFilters {
  static readonly type = '[Users] reset filters';

  constructor() {
  }
}

export class GetAllManagementUsers {
  static readonly type = '[Users] GetAllManagementUsers';

  constructor(public filters?: any, public sort?: { order: string, dir: string }) {
  }
}

export class GetAllManagementEmployees {
  static readonly type = '[Users] GetAllManagementEmployees';

  constructor(public filters?: any, public sort?: { order: string, dir: string }) {
  }
}

export class UpdateUser {
  static readonly type = '[Users] Update user';

  constructor(public userData: UserEditDto, public uid: string) {
  }
}

export class UpdatePassword {
  static readonly type = '[Users] Update password';

  constructor(public passwords: PasswordsDto, public uid: string) {
  }
}

export class DeleteUser {
  static readonly type = '[Users] Delete user';

  constructor(public uids: string | string[]) {
  }
}

export class SetUserPage {
  static readonly type = '[Users] Set page';

  constructor(public page: number) {
  }
}

export class ResetPassword {
  static readonly type = '[Users] Reset user password';

  constructor(public uid: number | string) {
  }
}

export class BlockUser {
  static readonly type = '[Users] Block user';

  constructor(public uid: number | string, public isActive: number, public mode: string) {
  }
}

export class SetUpdateUserOptions {
  static readonly type = '[Users] SetUpdateUserOptions';

  constructor(public updateUserOptions: UpdateUserOptionsModel) {
  }
}

export class UpdateUserName {
  static readonly type = '[Users] UpdateUserName';

  constructor(public mode: UpdateUserModeEnum, public name: string, public uid: string) {
  }
}

export class UpdateEmployeeFirstName {
  static readonly type = '[Users] UpdateEmployeeFirstName';

  constructor(public mode: UpdateUserModeEnum, public firstName: string, public uid: string) {
  }
}

export class UpdateEmployeeLastName {
  static readonly type = '[Users] UpdateEmployeeLastName';

  constructor(public mode: UpdateUserModeEnum, public lastName: string, public uid: string) {
  }
}

export class UpdateEmployeeUid {
  static readonly type = '[Users] UpdateEmployeeUid';

  constructor(public oldUid: string, public newUid: string) {
  }
}

export class UpdateUserEmail {
  static readonly type = '[Users] UpdateUserEmail';

  constructor(public mode: UpdateUserModeEnum, public email: string, public uid: string) {
  }
}

export class UpdateUserPassword {
  static readonly type = '[Users] UpdateUserPassword';

  constructor(public mode: UpdateUserModeEnum, public payload: UpdateUserPasswordPayload, public uid: string) {
  }
}

export class UpdateProfileUserSign {
  static readonly type = '[Users] UpdateProfileUserSign';

  constructor(public sign: string) {
  }
}

export class UpdateUserAvatar {
  static readonly type = '[Users] UpdateUserAvatar';

  constructor(public mode: UpdateUserModeEnum, public avatar: string, public uid: string) {
  }
}

export class UpdateUserRole {
  static readonly type = '[Users] UpdateUserRole';

  constructor(public uid: string, public role: number) {
  }
}

export class UpdateUserDepartment {
  static readonly type = '[Users] UpdateUserDepartment';

  constructor(public uid: string, public departmentId: number) {
  }
}

export class CreateNewUser {
  static readonly type = '[Users] CreateNewUser';

  constructor(public payload: CreateNewUserPayload) {
  }
}
