import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import { CreateNewUserPayload, UpdateUserModeEnum, UpdateUserPasswordPayload, UserModel } from '../../shared/models';
import { deEscapeHtml } from '../../shared/helpers/deEscapeHtml';

export interface UserListFilters {
  page?: number;
  rows: number | string;
  departments?: number[];
  roles?: string[];
  search: string;
  order?: string;
  dir?: string;
  categoryId?: string;
}

export interface UserEditDto {
  name: string;
  email: string;
  role: string;
  uid?: string;
}

export interface PasswordsDto {
  password: string;
  password_confirmation: string;
}

export interface NewUserDto extends UserEditDto, PasswordsDto {
}

@Injectable()
export class UserService {
  private isUpdateUser: BehaviorSubject<{ value: boolean; isEditCurrentUser: boolean }> = new BehaviorSubject({
    value: false,
    isEditCurrentUser: false
  });
  isUpdateUser$: Observable<{ value: boolean; isEditCurrentUser: boolean }> = this.isUpdateUser.asObservable();

  constructor(
    private http: HttpClient,
    @Inject('API_URL') private apiUrl: string
  ) {
  }


  getAllForFilter(filters?: UserListFilters) {
    const filter = filters
      ? {
        ...filters,
        search: (filters.search || '').trim(),
      }
      : {};
    delete (<any>filter).roles;
    return new Observable((observer: Observer<any>) => {
      this.http.get(`${this.apiUrl}/page/users/filter`, {params: filter, observe: 'response'})
        .pipe(take(1))
        .subscribe({
          next: (success: HttpResponse<any>) => {
            observer.next({
              users: success.body,
              total: success.headers.get('X-Items-Count')
            });
            observer.complete();
          },
          error: (err: any) => {
            observer.error(err);
          }
        });
    });
  }

  getAllManagementUsers(userFilters?: UserListFilters): Observable<{ users: UserModel[], total: number }> {
    const filters: any = {
      ...userFilters,
      search: (deEscapeHtml((userFilters.search || '')) as string).trim(),
      'roles[]': userFilters.roles
    };
    delete (<any>filters).roles;
    return this.http.get<any>(`${this.apiUrl}/v2/users`, {params: filters, observe: 'response'})
      .pipe(
        map((res: any) => {
          return {
            users: res.body,
            total: res.headers.get('X-Items-Count')
          };
        }),
        take(1)
      );
  }

  getAllManagementEmployees(userFilters?: UserListFilters): Observable<{ employees: UserModel[], total: number }> {
    const filters: any = {
      ...userFilters,
      search: (deEscapeHtml((userFilters?.search || '')) as string).trim(),
      'departments[]': userFilters?.departments,
    };
    if (!filters?.departments) {
      delete (filters)['departments[]'];
    }
    delete (filters)['departments'];
    delete (<any>filters).roles;
    return this.http.get<any>(`${this.apiUrl}/v2/users/employee`, {params: filters, observe: 'response'})
      .pipe(
        map((res: any) => {
          return {
            employees: res.body,
            total: res.headers.get('X-Items-Count')
          };
        }),
        take(1)
      );
  }

  deleteUser(uids: string[]) {
    const params = {
      'uid[]': deEscapeHtml(uids)
    };
    return this.http.delete(`${this.apiUrl}/users`, {params});
  }

  getSupervisors() {
    return this.http.get(`${this.apiUrl}/page/users/supervisors`);
  }

  resetPassword(uid: number | string) {
    return this.http.put(`${this.apiUrl}/users/${uid}/password`, {});
  }

  sendSMS(payload: any) {
    return this.http.post(`${this.apiUrl}/sms`, payload);
  }

  blockUser(uid: string | number, isActive: number) {
    return this.http.post(`${this.apiUrl}/users/${uid}/active/${isActive}`, {});
  }

  changeAdmissionCreatedDate(payload: { id: string; startDate: string; typeDate }) {
    return this.http.post(`${this.apiUrl}/admissions/${payload.id}/${payload.typeDate}`, {startDate: payload.startDate});
  }

  updateEmployeeFirstName(mode: UpdateUserModeEnum, firstName: string, uid: string): Observable<UserModel> {
    return this.http.put<UserModel>(`${this.apiUrl}/v2/users/${uid}/employee/first-name`, {firstName});
  }

  updateEmployeeLastName(mode: UpdateUserModeEnum, lastName: string, uid: string): Observable<UserModel> {
    return this.http.put<UserModel>(`${this.apiUrl}/v2/users/${uid}/employee/last-name`, {lastName});
  }

  updateUserName(mode: UpdateUserModeEnum, name: string, uid: string): Observable<UserModel> {
    const url: string = mode === UpdateUserModeEnum.CurrentUser
      ? `${this.apiUrl}/v2/profile/name`
      : mode === UpdateUserModeEnum.User
        ? `${this.apiUrl}/v2/users/${uid}/name`
        : '';
    return this.http.put<UserModel>(url, {name});
  }

  updateUserEmail(mode: UpdateUserModeEnum, email: string, uid: string): Observable<UserModel> {
    const url: string = mode === UpdateUserModeEnum.CurrentUser
      ? `${this.apiUrl}/v2/profile/email`
      : mode === UpdateUserModeEnum.User
        ? `${this.apiUrl}/v2/users/${uid}/email`
        : '';
    return this.http.put<UserModel>(url, {email});
  }

  updateUserPassword(mode: UpdateUserModeEnum, payload: UpdateUserPasswordPayload, uid: string): Observable<any> {
    const url: string = mode === UpdateUserModeEnum.CurrentUser
      ? `${this.apiUrl}/v2/profile/password`
      : `${this.apiUrl}/v2/users/${uid}/password`;
    return this.http.put<any>(url, payload);
  }

  updateProfileUserSign(sign: string): Observable<{ sign: string }> {
    return this.http.put<{ sign: string }>(`${this.apiUrl}/v2/profile/sign`, {sign});
  }

  updateUserAvatar(mode: UpdateUserModeEnum, avatar: string, uid: string): Observable<UserModel> {
    const url = mode === UpdateUserModeEnum.CurrentUser
      ? `${this.apiUrl}/v2/profile/avatar`
      : mode === UpdateUserModeEnum.User
        ? `${this.apiUrl}/v2/users/${uid}/avatar`
        : mode === UpdateUserModeEnum.Employee
          ? `${this.apiUrl}/v2/users/${uid}/employee/avatar`
          : '';
    return this.http.put<UserModel>(url, {avatar});
  }

  updateUserRole(uid: string, role: number): Observable<UserModel> {
    return this.http.put<UserModel>(`${this.apiUrl}/v2/users/${uid}/role`, {role});
  }

  updateUserDepartment(uid: string, departmentId: number): Observable<UserModel> {
    return this.http.put<UserModel>(`${this.apiUrl}/v2/users/${uid}/employee/department`, {departmentId});
  }

  updateEmployeeUid(oldUid: string, newUid: string): Observable<UserModel> {
    return this.http.put<UserModel>(`${this.apiUrl}/v2/users/${oldUid}/employee/uid`, {uid: newUid});
  }

  createNewUser(payload: CreateNewUserPayload): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/v2/users`, payload);
  }

  setIsUpdateUser(value: boolean, isEditCurrentUser: boolean) {
    this.isUpdateUser.next({value, isEditCurrentUser});
  }
}
