import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { CanActivate, Router } from '@angular/router';
import { UserModel } from '../shared/models';
import { getDataFromLocalStorage } from '../shared/helpers/other';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router
  ) {
  }

  canActivate(): boolean {
    const user: UserModel = getDataFromLocalStorage('user');
    if (this.store.selectSnapshot(({app}) => app.currentUser) || user) {
      return true;
    } else {
      this.router.navigate(['/login']);
    }
  }
}
