export enum PermissionEmployeeEnum {
  ApproveEmployee = 'employee.approveEmployee',
  AssignUser = 'employee.assignUser',
  ChangeCreatedDate = 'employee.changeCreatedDate',
  ChangeCompletedDate = 'employee.changeCompletedDate',
  ChangeSignedDate = 'employee.changeSignedDate',
  ChangeStartedDate = 'employee.changeStartedDate',
  CommentAttachment = 'employee.commentAttachment',
  CreateAttachment = 'employee.createAttachment',
  CreateDocument = 'employee.createDocument',
  CreateEmployee = 'employee.createEmployee',
  CreateNote = 'employee.createNote',
  DeleteEmployee = 'employee.deleteEmployee',
  DeleteAttachment = 'employee.deleteAttachment',
  DeleteDocumentHistory = 'employee.deleteDocumentHistory',
  DeleteNote = 'employee.deleteNote',
  DownloadDocuments = 'employee.downloadDocuments',
  DownloadAttachment = 'employee.getAttachment',
  EditEmployee = 'employee.editEmployee',
  EditNote = 'employee.editNote',
  GetEmployee = 'employee.getEmployee',
  SendAttachmentToHHA = 'employee.sendAttachmentToHHA',
  ReceiveEmail = 'employee.receiveEmailNotificationAboutStatusChange',
  UploadDocumentToHHA = 'employee.uploadDocumentToHHA',
  UpdateAttachment = 'employee.updateAttachment',
  UploadDocuments = 'employee.uploadDocuments',
  ViewAttachment = 'employee.viewAttachment',
  ViewDashboard = 'employee.viewDashboard',
  ViewDocumentHistory = 'employee.viewDocumentHistory',
  ViewNote = 'employee.viewNote'
}

export enum PermissionIncidentsEnum {
  AssignUser = 'incident.assignUser',
  CommentAttachment = 'incident.commentAttachment',
  Create = 'incident.create',
  CreateAttachment = 'incident.createAttachment',
  CreateNote = 'incident.createNote',
  Delete = 'incident.delete',
  DeleteAttachment = 'incident.deleteAttachment',
  DownloadPdf = 'incident.downloadPdf',
  Get = 'incident.get',
  GetAttachment = 'incident.getAttachment',
  SendAttachmentToHha = 'incident.sendAttachmentToHha',
  Update = 'incident.update',
  UpdateStatus = 'incident.updateStatus',
  UpdateAttachment = 'incident.updateAttachment',
  UpdateNote = 'incident.updateNote',
  ViewAttachment = 'incident.viewAttachment',
  ViewIncidents = 'incident.viewIncidents',
  ViewNote = 'incident.viewNote',
}

export enum PermissionAdmissionEnum {
  ApproveDecline = 'admission.approveDecline',
  AssignUser = 'admission.assignUser',
  CanBeSupervisor = 'admission.canBeSupervisor',
  CommentAttachment = 'admission.commentAttachment',
  CreateAttachment = 'admission.createAttachment',
  CreateNote = 'admission.createNote',
  Create = 'admission.create',
  CreateWithAttachment = 'admission.createWithAttachment',
  Delete = 'admission.delete',
  DeleteAttachment = 'admission.deleteAttachment',
  DownloadDocuments = 'admission.downloadDocuments',
  Get = 'admission.get',
  GetAttachment = 'admission.getAttachment',
  SendAttachmentToHha = 'admission.sendAttachmentToHha',
  SendToSign = 'admission.sendToSign',
  SetSupervisor = 'admission.setSupervisor',
  SyncFromHha = 'admission.syncFromHha',
  Update = 'admission.update',
  UpdateAttachment = 'admission.updateAttachment',
  UpdateCreatedAtDocument = 'admission.updateCreatedAtDocument',
  UpdateNote = 'admission.updateNote',
  UpdateOffice = 'admission.updateOffice',
  UpdateSignedAtDocument = 'admission.updateSignedAtDocument',
  ViewAdmissions = 'admission.viewAdmissions',
  ViewAttachment = 'admission.viewAttachment',
  ViewNote = 'admission.viewNote',
  UpdateStartDateDocument = 'admission.updateStartDateDocument'
}

export enum PermissionPatientEnum {
  View = 'patients.viewPatients',
  Create = 'patient.create',
  AssignUser = 'patient.assignUser',
  CommentAttachment = 'patient.commentAttachment',
  CreateAttachment = 'patient.createAttachment',
  CreateNote = 'patient.createNote',
  DeleteAttachment = 'patient.deleteAttachment',
  UpdatePortalHHaStatus = 'patient.updatePortalPatientStatus',
  Get = 'patient.get',
  GetAttachment = 'patient.getAttachment',
  SendAttachmentToHha = 'patient.sendAttachmentToHha',
  UpdateAttachment = 'patient.updateAttachment',
  ViewAdmissions = 'patient.viewAdmissions',
  ViewAttachment = 'patient.viewAttachment',
  ViewIncidents = 'patient.viewIncidents',
  ViewNote = 'patient.viewNote',
  ViewTimesheets = 'patient.viewTimesheets'
}

export enum PermissionHospitalsEnum {
  View = 'hospital.viewHospitals',
  Create = 'hospital.create',
  Delete = 'hospital.delete',
  Update = 'hospital.update'
}

export enum PermissionDoctorsEnum {
  Create = 'doctor.create',
  Delete = 'doctor.delete',
  Get = 'doctor.get',
  Update = 'doctor.update',
  ViewDoctors = 'doctor.viewDoctors'
}

export enum PermissionProfileEnum {
  UpdatePassword = 'profile.updatePassword',
  UpdateProfile = 'profile.updateProfile',
  UpdateSign = 'profile.updateSign',
  UploadAvatar = 'profile.uploadAvatar',
}

export enum PermissionTimesheetsEnum {
  ApproveReject = 'timesheet.approveReject',
  Create = 'timesheet.create',
  Get = 'timesheet.get',
  Delete = 'timesheet.delete',
  DeleteApproved = 'timesheet.deleteApproved',
  DownloadPdf = 'timesheet.downloadPdf',
  SendToSign = 'timesheet.sendToSign',
  Update = 'timesheet.update',
  ChangeDates = 'timesheet.changeSigned',
  ViewTimesheets = 'timesheet.viewTimesheets'
}

export enum PermissionReportsEnum {
  GenerateAccuracy = 'reports.generateAccuracy',
  GenerateIncomplete = 'reports.generateIncomplete'
}

export enum PermissionRoleEnum {
  Create = 'role.create',
  Delete = 'role.delete',
  Get = 'role.get',
  Update = 'role.update',
  View = 'role.view',
}

export enum PermissionSystemsettingEnum {
  Update = 'systemsetting.update',
  View = 'systemsetting.viewSystemSettings'
}

export enum PermissionUserEnum {
  SendSms = 'user.sendSms',
  ViewEmployee = 'user.viewEmployee',
  CreateUser = 'user.create',
  ViewUserManagement = 'user.viewUserManagement',
}

export enum PermissionCalendarEnum {
  Create = 'calendar.create',
  Delete = 'calendar.delete',
  Get = 'calendar.get',
  Login = 'calendar.login',
  Update = 'calendar.update',
  ViewEvents = 'calendar.viewEvents'
}

export enum PermissionEducationEnum {
  Create = 'education.create',
  CreateEducationCategory = 'category.create',
  CreateEducationPlan = 'plan.create',
  Delete = 'education.delete',
  DeleteEducationCategory = 'category.delete',
  DeleteEducationPlan = 'plan.delete',
  Get = 'education.get',
  GetEducationCategory = 'category.get',
  GetEducationPlan = 'plan.get',
  Notify = 'plan.notify',
  Update = 'education.update',
  UpdateEducationCategory = 'category.update',
  UpdateEducationPlan = 'plan.update',
}

export enum PermissionView {
  ViewDashboard = 'employee.viewDashboard',
  ViewDocumentHistory = 'employee.viewDocumentHistory',
  ViewIncidents = 'incident.viewIncidents',
  ViewPatients = 'patient.viewPatients',
  ViewAdmissions = 'admission.viewAdmissions',
  ViewHospitals = 'hospital.viewHospitals',
  ViewDoctors = 'doctor.viewDoctors',
  ViewTimesheets = 'timesheet.viewTimesheets',
  GenerateAccuracy = 'reports.generateAccuracy',
  GenerateIncomplete = 'reports.generateIncomplete',
  ViewEducation = 'education.viewEducation',
  ViewPlans = 'plan.viewEducationPlan',
  ViewCategory = 'category.viewEducationCategory',
  ViewUsers = 'user.viewUserManagement',
  ViewSettings = 'systemsetting.viewSystemSettings',
  ViewPermissions = 'permissions.viewPermissions',
}
