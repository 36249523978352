export interface Account {
  accessCar: boolean;
  allergies: any;
  aptUnit: any;
  city: string;
  convicted: boolean;
  convictedExplain: any;
  dob: any;
  driverLicense: boolean;
  driverLicenseName: any;
  driverLicenseNumber: any;
  driverState: any;
  educations: any[];
  emergencyContacts: any[];
  firstName: string;
  fullName: string;
  gender: any;
  hasESign: number;
  hourlyRate: any;
  houseHoldMembers: any[];
  isFullApplication: boolean;
  isStoreToHhaexchange: boolean;
  langSpoken: any[];
  lastName: string;
  license: any;
  licenseExplain: any;
  maritalStatus: number;
  mi: any;
  municipality: number;
  name: string;
  originalUrl: string;
  otherLastName: any;
  patient: any;
  phone: any;
  positionApplied: any[];
  previousAddresses: any;
  previousEmployments: any[];
  purposeCertification: any;
  purposeCertificationOther: any;
  reasonRefuse: any;
  refuseVaccine: number;
  residencyCitizenUSA: boolean;
  residencyStatus: any[];
  signOnBonus: any;
  signUrl: string;
  smoker: boolean;
  socialSecurity: any;
  state: any;
  streetAddress: any;
  timeAvailability: any[];
  totalAllowances: any;
  totalChildren: number;
  totalChildrenSum: number;
  totalDependents: number;
  totalDependentsSum: number;
  zip: string;
}

export interface UserModel {
  account?: Account;
  creator?: UserModel;
  createdAt?: string;
  department?: any;
  documentHistory?: any;
  email?: string;
  isActive?: number;
  roles?: any;
  role?: RoleEnum;
  uid?: string;
  token?: string;
  isLoginToCalendar?: boolean;
  name?: string;
  firstName?: string;
  fullName?: string;
  lastName?: string;
  phone?: number | string;
  patient?: any;
  originalUrl?: string;
  refuseVaccine?: number;
  reasonRefuse?: any;
  reasonRefuseType?: any;
  isStoreToHhaexchange?: boolean;
  hasESign?: number;
  previousAddresses?: any;
  signUrl?: string;
  isFullApplication?: boolean;
  workRestrictions?: any;
  applicationFilled?: any;
  vaccinatedCovid19?: any;
  startDate?: any;
  sign?: string;
  avatar?: string;
  permissions?: string[];
}

export interface UpdateUserPasswordPayload {
  password: string;
  passwordConfirm: string;
}

export interface CreateNewUserPayload {
  name: string;
  email: string;
  role: string;
  password: string;
  passwordConfirm: string;
}

export interface UpdateUserOptionsModel {
  mode: UpdateUserModeEnum;
  userData: UserModel;
}

export enum UpdateUserModeEnum {
  CurrentUser = 'current-user',
  CreateNewUser = 'create-new-user',
  User = 'user',
  Employee = 'employee'
}

export enum IsActiveUser {
  Enable = 1,
  Disable = 2
}


export enum RoleEnum {
  Admin = 'admin',
  SuperAdmin = 'Super admin',
  Sales = 'sales',
  Supervisor = 'supervisor',
  Employee = 'Employee'
}
