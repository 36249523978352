<div class="new-employee">
  <div class="signature-wrapper">
    <signature-pad (drawStart)="drawStart()" #signature></signature-pad>
  </div>
  <div class="signature-utility-text">
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.0026 14.6654C11.6845 14.6654 14.6693 11.6806 14.6693 7.9987C14.6693 4.3168 11.6845 1.33203 8.0026 1.33203C4.32071 1.33203 1.33594 4.3168 1.33594 7.9987C1.33594 11.6806 4.32071 14.6654 8.0026 14.6654Z"
        stroke-miterlimit="10"/>
      <path
        d="M8 4.66406V8.55295"
        stroke-linecap="round"
        stroke-linejoin="round"/>
      <path
        d="M8.00546 11.3547C8.37954 11.3547 8.68279 11.0514 8.68279 10.6773C8.68279 10.3033 8.37954 10 8.00546 10C7.63138 10 7.32812 10.3033 7.32812 10.6773C7.32812 11.0514 7.63138 11.3547 8.00546 11.3547Z"/>
    </svg>
    <span>Should not be signed with a dot or single line. Cross mark is acceptable.</span>
  </div>
  <div class="btn-wrapper">
    <button [disabled]="disableBtn  || !validateSign()" (click)="save()" class="btn violet" data-e2e="save-signature">Save</button>
    <button (click)="clear()" class="clear-all" data-e2e="clear-signature">Clear</button>
  </div>
</div>
